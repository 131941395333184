@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
@import url(https://use.typekit.net/xaq6dip.css);
._3P4bWLyDoFDrwkHyurQl0o {
  position: relative;
  overflow: hidden;
  background: no-repeat center/cover;
}

.OlIfVYsWU1-WO-GAAjAvH {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  will-change: opacity;
  transition: opacity 300ms ease;
}

.device-IE11 .OlIfVYsWU1-WO-GAAjAvH,
.device-Edge .OlIfVYsWU1-WO-GAAjAvH {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: none;
}

.device-IE11 ._3P4bWLyDoFDrwkHyurQl0o._2w4pzwTT-IbDRYdhk24au3 .OlIfVYsWU1-WO-GAAjAvH,
.device-Edge ._3P4bWLyDoFDrwkHyurQl0o._2w4pzwTT-IbDRYdhk24au3 .OlIfVYsWU1-WO-GAAjAvH {
  min-width: none;
  min-height: none;
}

._3P4bWLyDoFDrwkHyurQl0o._2w4pzwTT-IbDRYdhk24au3 {
  background-size: contain;
}

._3P4bWLyDoFDrwkHyurQl0o._2w4pzwTT-IbDRYdhk24au3 .OlIfVYsWU1-WO-GAAjAvH {
  object-fit: contain;
}
.uvbEAgTZIC9g3GeG2JsAQ {
  width: 100%;
}

._3mSsI4EGgZe2rzbDuGBLem {
  max-width: 760px;
  margin-right: auto;
  margin-left: auto;
}

._2ZzQf583JtXrEReFKbeTeB {
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
}

.Wc5GPsUSmXMpD0I69JTOF {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
}

._1Oibb41BxUFUi62BX5wowj {
  padding-right: 0;
  padding-left: 0;
}

.EZMi0ftZ-qIhygE-Ph5Kf {
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (min-width: 700px) and (max-width: 799px) {
  .EZMi0ftZ-qIhygE-Ph5Kf {
    padding-right: 9%;
    padding-left: 9%;
  }
}

._2AEjD9cbnOteSpcokLY5ob {
  padding-right: 45px;
  padding-left: 45px;
}

@media screen and (min-width: 700px) and (max-width: 799px) {
  ._2AEjD9cbnOteSpcokLY5ob {
    padding-right: 9%;
    padding-left: 9%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._1Oibb41BxUFUi62BX5wowj,
  .EZMi0ftZ-qIhygE-Ph5Kf,
  ._2AEjD9cbnOteSpcokLY5ob {
    padding-right: 9%;
    padding-left: 9%;
  }
}

._3d3ERkJip6m83wR_2_4bYa {
  padding-top: 60px;
  padding-bottom: 60px;
}

._1LGmb2XkoPxtKeaxDahMha {
  position: relative;
}

._1xyX5WiLJYUz4Grt3Ft-xO {
  position: relative;
}
._2M4OO71eEq853gd5s9DOZr {
  font-size: 1.4rem;
}

._3zhmzrLQ8XghskNS3ynZQO {
  font-size: inherit;
  font-weight: inherit;
}

._3KpKVKJ-P3Z-liX38O7U3N {
  font-size: 2rem;
}

.qCqVFQClqIRL1e4ISx7wm {
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-style: italic;
}

._3X8bTB2HWMq0p3cOG2H3uy {
  color: rgba(20,19,21,0.6);
}

._1B1C1SnqKH4Tbo9DId2jMH {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._1n6H3glTj1SMfDE80w92XQ {
  color: #16a58e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.UOAiGeugYyNq82q0jh8qL p,
.UOAiGeugYyNq82q0jh8qL ul,
.UOAiGeugYyNq82q0jh8qL ol {
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.4px;
}

.UOAiGeugYyNq82q0jh8qL p:not(:first-child),
.UOAiGeugYyNq82q0jh8qL ul:not(:first-child),
.UOAiGeugYyNq82q0jh8qL ol:not(:first-child) {
  margin-top: 1em;
}

.UOAiGeugYyNq82q0jh8qL blockquote+p:not(:first-child),
.UOAiGeugYyNq82q0jh8qL blockquote+ul:not(:first-child),
.UOAiGeugYyNq82q0jh8qL blockquote+ol:not(:first-child) {
  margin-top: 4rem;
}

.UOAiGeugYyNq82q0jh8qL li {
  padding: 5px 0;
  margin-left: 20px;
}

.UOAiGeugYyNq82q0jh8qL strong {
  color: #141315;
}

.UOAiGeugYyNq82q0jh8qL blockquote {
  padding-left: 3rem;
  font-size: 1.4em;
  line-height: 1.4em;
  border-left: 2px solid #2fd0b7;
  color: #141315;
  font-style: italic;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.UOAiGeugYyNq82q0jh8qL blockquote:not(:first-child) {
  margin-top: 4rem;
}

@media screen and (min-width: 550px) {
  .UOAiGeugYyNq82q0jh8qL blockquote {
    margin-left: 3rem;
  }
}
._2lLPZPccVNGjUh0XbdQ2YO {
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
}

.C_NBE1z28bvgAahFPP8Tx {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._2CWrFAw_p9jpO4lwCzV1ka {
  font-family: "proxima-nova",sans-serif;
  font-weight: 700;
}

._3ih-KaQ91_bj_EOG9wv1Lt {
  font-family: "proxima-nova",sans-serif;
  font-weight: 900;
}

.XMJBBEuFBHhP3osWlMbdw {
  font-family: "proxima-nova",sans-serif;
  font-weight: 100;
}

._1_eMTdELxk4SMCXH5q41c- {
  font-style: normal;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

._3kwZZ6p0yZzwTga_KGADzf {
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-style: italic;
  font-style: italic;
}

._1a7xTJOixE6Mr7m5GIkImA {
  font-size: 2.8rem;
  line-height: 1.1em;
}

@media screen and (min-width: 400px) {
  ._1a7xTJOixE6Mr7m5GIkImA {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1000px) {
  ._1a7xTJOixE6Mr7m5GIkImA {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1400px) {
  ._1a7xTJOixE6Mr7m5GIkImA {
    font-size: 6rem;
  }
}

._21M_FfFKG5xC-bX5gT91Sb {
  font-size: 2.6rem;
  line-height: 1.1em;
}

@media screen and (min-width: 400px) {
  ._21M_FfFKG5xC-bX5gT91Sb {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  ._21M_FfFKG5xC-bX5gT91Sb {
    font-size: 4rem;
  }
}

.N73vZIsQDZvyBxLweLC-u {
  font-size: 2.4rem;
  line-height: 1.1em;
}

@media screen and (min-width: 400px) {
  .N73vZIsQDZvyBxLweLC-u {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 999px) {
  .N73vZIsQDZvyBxLweLC-u {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 1200px) {
  .N73vZIsQDZvyBxLweLC-u {
    font-size: 3.4rem;
  }
}

._3pWfOe0iL_a2r5E_15vWK5 {
  font-size: 2rem;
  line-height: 1.3em;
}

@media screen and (min-width: 400px) {
  ._3pWfOe0iL_a2r5E_15vWK5 {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1000px) {
  ._3pWfOe0iL_a2r5E_15vWK5 {
    font-size: 3rem;
  }
}

._3_ii1tK3oYr7-zixJnviZ3 {
  font-size: 2rem;
  line-height: 1.3em;
}

@media screen and (max-width: 1199px) {
  ._3_ii1tK3oYr7-zixJnviZ3 {
    font-size: 2.2rem;
  }
}

.AhZsNoh2xQ1OqMZv7SwZu {
  font-size: 1.2rem;
  line-height: 1.3em;
}

@media screen and (min-width: 1200px) {
  .AhZsNoh2xQ1OqMZv7SwZu {
    font-size: 1.4rem;
  }
}

.RH2WR44jiEyfCkqua-YuH {
  text-align: left;
}

._2tzJrdVYhzf_v2ZnrcwoXo {
  text-align: center;
}

.u-vW2eC4sCKKMQXGqOdBr {
  text-align: right;
}

._2NtS37PxkmXLwINnpSycKL {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

._3byJFtQA-QULdKaK6jZO9r {
  color: #1c1b20;
}

._2gjZ8ySTarP1HqvBDOUhNR {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._3Y7wB82EPIaGl6Qi3wwdka {
  color: #2fd0b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._2JfVO3k3kMF8TwcZdtG7Ls {
  color: #adadad;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.KIHisihPo0nwNp3A5Yn2o {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  margin-top: 180px;
}

@media screen and (max-width: 549px) {
  .KIHisihPo0nwNp3A5Yn2o {
    padding-top: 60px;
    padding-right: 20px;
    padding-bottom: 60px;
    padding-left: 20px;
  }
}

.KIHisihPo0nwNp3A5Yn2o:not(._21H9kNXrTVAdKZPOlXp2BZ) {
  margin-top: 60px;
  margin-bottom: 30px;
}

@media screen and (max-width: 699px) {
  .KIHisihPo0nwNp3A5Yn2o:not(._21H9kNXrTVAdKZPOlXp2BZ) {
    margin-top: 0;
  }
}

@media screen and (min-width: 800px) {
  .KIHisihPo0nwNp3A5Yn2o:not(._21H9kNXrTVAdKZPOlXp2BZ) {
    margin-bottom: 60px;
  }
}

.KIHisihPo0nwNp3A5Yn2o:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  background: #f5f5f5;
}

@media screen and (min-width: 800px) {
  .KIHisihPo0nwNp3A5Yn2o:before {
    height: 89%;
    top: 75px;
  }
}

.KIHisihPo0nwNp3A5Yn2o._1WLdSt8H75DL5ihpL0ESpY::before {
  background: #fff;
}

@media screen and (min-width: 800px) {
  ._3mGxmG8JV4246fLKy3Fgob:before {
    height: 91%;
  }
}

._3mGxmG8JV4246fLKy3Fgob:not(._21H9kNXrTVAdKZPOlXp2BZ) {
  margin-bottom: 0;
}

._21H9kNXrTVAdKZPOlXp2BZ.KIHisihPo0nwNp3A5Yn2o:before {
  height: 125%;
}

._3f8PpHJZhnkarrH2r1j3bn {
  max-width: 1200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  ._3f8PpHJZhnkarrH2r1j3bn {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

._2kExAGPKE24jLmLMsh5jDQ {
  width: 82%;
}

@media screen and (min-width: 700px) {
  ._2kExAGPKE24jLmLMsh5jDQ {
    width: 65%;
  }
}

@media screen and (min-width: 800px) {
  ._2kExAGPKE24jLmLMsh5jDQ {
    width: 300px;
    padding-right: 80px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 799px) {
  ._2kExAGPKE24jLmLMsh5jDQ {
    margin-bottom: 60px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1000px) {
  ._2kExAGPKE24jLmLMsh5jDQ {
    width: 440px;
    padding-right: 80px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 800px) {
  ._3bjNw0SpqjAlfZIKORYxuP {
    position: static;
    height: 320px;
  }
}

@media screen and (min-width: 1000px) {
  ._3bjNw0SpqjAlfZIKORYxuP {
    height: 520px;
  }
}

._1CvLK5iM2bmwoS1JhZJK2b {
  margin-bottom: 10px;
}

.oHKd1jjazrgcKZf0UPcBM {
  position: relative;
}

@media screen and (min-width: 800px) {
  .oHKd1jjazrgcKZf0UPcBM {
    margin: 0;
  }

  .oHKd1jjazrgcKZf0UPcBM.b3DrCRcMsVHQXKQR2XscW {
    -webkit-transform: translateY(-135px);
    transform: translateY(-135px);
  }
}

.oHKd1jjazrgcKZf0UPcBM:after {
  content: '';
  width: 100%;
  position: absolute;
  top: 35%;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: linear-gradient(to top, rgba(20,19,21,0.75), rgba(20,19,21,0));
  background-size: cover;
}

.BomFyuU6qtWk6GX6v9Rka {
  width: 100%;
  position: absolute;
  top: 55%;
  z-index: 3;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .BomFyuU6qtWk6GX6v9Rka {
    top: 72%;
  }
}

._2pLbYfFEn05ft0kwTZn0HZ {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-size: 2.1rem;
}

.Aooc-UVYX_OsdR97X2yTi {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: #efefef;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01em;
}

.HWN9bxzy5XkkSA_ohya2A {
  margin-top: 20px;
}

._2dZxaXhPip-dQtxZY9ENWj,
._39p1RNs_ynr1RiV5ONgg_V {
  display: block;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  color: #2fd0b7;
  font-size: 1.6rem;
  line-height: 2.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01em;
}

._3q6LExgp1ijxAd6dss3gTT {
  z-index: 10;
}

@media screen and (min-width: 800px) {
  ._3q6LExgp1ijxAd6dss3gTT {
    padding-top: 50px;
  }
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-form {
  padding-top: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: space-between;
  align-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-form fieldset {
  max-width: none;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 {
  margin: 13px 0;
  width: 100%;
}

@media screen and (max-width: 699px) {
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 {
    margin-top: 9px;
  }
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 ._1S4hkK1052SGdbgnGc54HY {
  width: 100% !important;
  margin: 0;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 {
  width: 100%;
  margin: 13px 0;
}

@media screen and (max-width: 699px) {
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 {
    margin-bottom: 0;
  }
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-form-field {
  float: left;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-form-field:nth-child(even) {
  float: right;
}

@media screen and (max-width: 399px) {
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-form-field:first-child {
    margin-bottom: 16px;
  }
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea .input {
  margin-right: 0 !important;
  margin-bottom: 10px;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea textarea {
  width: 100% !important;
  color: #1c1b20;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 6px 1px;
  border-bottom: #d7d7d8 2px solid;
  transition: border-bottom ease 0.25s;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text input::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text textarea::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea input::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea textarea::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text input::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text textarea::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea input::-webkit-input-placeholder,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea textarea::-webkit-input-placeholder {
  color: #1c1b20;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text input.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text textarea.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber input.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber textarea.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea input.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea textarea.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text input.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text textarea.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber input.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber textarea.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea input.error,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea textarea.error {
  border-color: #f53175;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

@media screen and (min-width: 550px) {
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-text,
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-phonenumber,
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-text,
  ._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-phonenumber {
    width: 43% !important;
  }
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea textarea,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea textarea {
  padding: 10px;
  border: #d7d7d8 2px solid;
  resize: none;
  height: 139px;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea label,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea label {
  width: 100%;
  margin: 7px 0;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-textarea .input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox ul,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox ul {
  list-style-type: none;
  padding: 0;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox .input label,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox .input label {
  cursor: pointer;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox label,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 32px;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox label span,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox label span {
  position: absolute;
  left: 40px;
  top: 0;
  transition: top 0.25s;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox label span::after,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox label span::after {
  content: '';
  position: absolute;
  top: 25px;
  left: 0;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox label.checkbox-checked span,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox label.checkbox-checked span {
  position: absolute;
  top: -25px;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox input,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox input {
  position: relative;
  margin-right: 20px;
  height: 24px;
  min-width: 24px;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox input:before,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox input:before {
  content: '';
  height: 24px;
  min-width: 24px;
  position: absolute;
  border: 2px solid #d7d7d8;
  z-index: 1;
  background: #fff;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox input:after,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox input:after {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #2fd0b7;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: opacity 0.25s ease, -webkit-transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease;
  z-index: 2;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-fieldtype-booleancheckbox input:checked:after,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-fieldtype-booleancheckbox input:checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: yujpgJVCyO8wvYo9LPXRe 0.3s ease-out;
  animation: yujpgJVCyO8wvYo9LPXRe 0.3s ease-out;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy label span::after,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy label span::after {
  content: 'Great!';
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk {
  margin-top: -13px;
  margin-bottom: 32px;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk label span::after,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk label span::after {
  content: "I'm awesome!";
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-form-required,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-form-required {
  display: none;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-error-msgs,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-error-msgs {
  list-style-type: none;
  padding: 0;
}

._2XTMsMcCed5ZlzkchSFSg8 .form-columns-2 .hs-error-msgs .hs-error-msg,
._2XTMsMcCed5ZlzkchSFSg8 .form-columns-1 .hs-error-msgs .hs-error-msg {
  color: #f53175;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs_error_rollup {
  width: 100%;
  margin: 0 0 13px 0;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs_error_rollup .hs-error-msgs {
  list-style-type: none;
  padding: 0;
  color: #f53175;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-submit {
  position: relative;
  margin-top: 24px;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-submit input {
  cursor: pointer;
  border: none;
  padding: 11px 77px 11px 27px;
  z-index: 1;
  background-image: linear-gradient(-90deg, #47dba4, #46d1be);
  background-size: cover;
  color: #fff;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 1.3;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.4s ease;
}

@media screen and (max-width: 799px) {
  ._2XTMsMcCed5ZlzkchSFSg8 .hs-submit input {
    font-size: 1.5rem;
  }
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-submit input:hover {
  background-image: linear-gradient(90deg, #47dba4, #46d1be);
  background-size: cover;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-submit::after {
  content: '';
  position: absolute;
  width: 35px;
  height: 14px;
  background: url(/media/images/svg/arrow-right.svg);
  right: 30px;
  transition: right 0.25s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

._2XTMsMcCed5ZlzkchSFSg8 .hs-submit:hover::after {
  right: 20px;
}

._2XTMsMcCed5ZlzkchSFSg8 .submitted-message {
  color: #2fd0b7;
  font-size: 2.2rem;
  margin-top: 50px;
}

@-webkit-keyframes yujpgJVCyO8wvYo9LPXRe {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes yujpgJVCyO8wvYo9LPXRe {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.H2eX8WdTXSHlRUkiqMsoa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

._2Hd30t-_uA6hUluPUL1RZi,
._2Ly_YjF6xIjMSXkP3XzKUi {
  font-size: 1.4rem;
  color: rgba(255,255,255,0.25);
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._2Hd30t-_uA6hUluPUL1RZi {
  text-transform: uppercase;
}

._2Ly_YjF6xIjMSXkP3XzKUi {
  transition: color 0.25s ease;
}

.device-desktop ._2Ly_YjF6xIjMSXkP3XzKUi:hover {
  color: #2fd0b7;
}
._2FR-MOjscIQ7wXL7hrTI5s {
  display: -webkit-flex;
  display: flex;
}

._3XvT-L4YilC4MRJD1nJD3h {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
}

._3XvT-L4YilC4MRJD1nJD3h:not(:first-child) {
  margin-left: 5px;
}

@media screen and (min-width: 400px) {
  ._3XvT-L4YilC4MRJD1nJD3h:not(:first-child) {
    margin-left: 20px;
  }
}

._3XvT-L4YilC4MRJD1nJD3h:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 50%;
  background-color: #fff;
}

._1EvAlh_Q3ey0-3unRKfQxy {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.aLj4w9qb3dgn4nde78xsT {
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 2;
  display: block;
  fill: #141315;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.device-desktop ._3XvT-L4YilC4MRJD1nJD3h:before {
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: -webkit-transform ease-in-out 0.25s;
  transition: transform ease-in-out 0.25s;
  transition: transform ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
}

.device-desktop ._3XvT-L4YilC4MRJD1nJD3h:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.device-desktop .aLj4w9qb3dgn4nde78xsT {
  fill: #fff;
  transition: fill ease-in-out 0.25s;
}

.device-desktop ._3XvT-L4YilC4MRJD1nJD3h:hover .aLj4w9qb3dgn4nde78xsT {
  fill: #141315;
}
.qMeGbFGTTVXHOyaQeMCsA {
  background-color: #141315;
}

._2SMSkt8MI1smxPXYZygdxW {
  padding-top: 55px;
  padding-bottom: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@media screen and (min-width: 1200px) {
  ._2SMSkt8MI1smxPXYZygdxW {
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

.x3YUbb93gFq9Q86p2WGOR {
  width: 100%;
  max-width: 400px;
}

@media screen and (min-width: 1200px) {
  .x3YUbb93gFq9Q86p2WGOR {
    -webkit-order: 1;
    order: 1;
  }
}

._2gQhILrRY3GOUvOJ7mN-FB {
  width: 50px;
  margin-right: 4%;
  margin-left: 4%;
  border: 0;
  border-top: 1px solid rgba(255,255,255,0.25);
}

._2gQhILrRY3GOUvOJ7mN-FB:first-of-type {
  display: none;
}

@media screen and (min-width: 1200px) {
  ._2gQhILrRY3GOUvOJ7mN-FB:first-of-type {
    display: initial;
    -webkit-order: 2;
    order: 2;
  }
}

@media screen and (min-width: 1200px) {
  ._2gQhILrRY3GOUvOJ7mN-FB:last-of-type {
    -webkit-order: 4;
    order: 4;
  }
}

@media screen and (max-width: 1199px) {
  ._2gQhILrRY3GOUvOJ7mN-FB {
    margin: 0;
    padding-top: 0.5em;
  }
}

@media screen and (min-width: 1200px) {
  .tnj3NZCYBEM4qdB_6hllr {
    -webkit-order: 3;
    order: 3;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

._3nUGdSNwH6UuOEwxE_29mC {
  font-size: 1.8rem;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: capitalize;
  transition: opacity 0.2s ease;
}

._3nUGdSNwH6UuOEwxE_29mC:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1199px) {
  ._3nUGdSNwH6UuOEwxE_29mC {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: block;
  }

  ._3nUGdSNwH6UuOEwxE_29mC:first-child {
    padding-top: 0em;
  }

  ._3nUGdSNwH6UuOEwxE_29mC:last-child {
    padding-bottom: 1em;
  }
}

@media screen and (min-width: 1200px) {
  ._3nUGdSNwH6UuOEwxE_29mC {
    -webkit-flex-grow: 2;
    flex-grow: 2;
    text-align: center;
  }
}

._3jR9DpgCNKrtOP2yTgi2_i {
  padding-top: 1em;
  padding-bottom: 1.8em;
}

@media screen and (min-width: 1200px) {
  ._3jR9DpgCNKrtOP2yTgi2_i {
    padding: 0;
    -webkit-order: 5;
    order: 5;
  }
}
._3yaYARg1pbA6LNugp5iSb9 {
  width: 100%;
  margin: 13px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.YNHeuetbrD5BgSZDRzVsW,
._3vNDhM1akfopL-DzzzzwFp {
  width: 100%;
  color: #1c1b20;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
}

.YNHeuetbrD5BgSZDRzVsW::-webkit-input-placeholder,
._3vNDhM1akfopL-DzzzzwFp::-webkit-input-placeholder {
  color: #adadad;
}

.YNHeuetbrD5BgSZDRzVsW {
  padding: 6px 1px;
  border-bottom: #d7d7d8 2px solid;
  transition: border-bottom ease 0.25s;
}

._3vNDhM1akfopL-DzzzzwFp {
  padding: 10px;
  border: #d7d7d8 2px solid;
  resize: none;
}
._4fW__CIGogq2PX-Plpj5y {
  width: 100%;
  position: relative;
  padding: 40px 30px;
  color: #fff;
  background-color: #29282d;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  ._4fW__CIGogq2PX-Plpj5y {
    padding: 25px 65px;
  }
}

._1-HHpQtzLVqo-cL5R14NP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 800px) {
  ._1-HHpQtzLVqo-cL5R14NP {
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
  }
}

.Qb2Ro1lu15qRphCtCMVHE {
  width: 100%;
}

.Qb2Ro1lu15qRphCtCMVHE p {
  font-size: 2.2rem;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1199px) {
  .Qb2Ro1lu15qRphCtCMVHE p {
    font-size: 2rem;
  }
}

._2bJJ2Rb9CJrYzm4sah70l9 {
  margin: 40px 0;
}

@media screen and (min-width: 800px) {
  ._2bJJ2Rb9CJrYzm4sah70l9 {
    margin: 0 40px;
  }
}

._2T1g7YRJCI-oDZPfNnAtU9._2T1g7YRJCI-oDZPfNnAtU9 {
  color: #2fd0b7;
}

._2T1g7YRJCI-oDZPfNnAtU9::-webkit-input-placeholder {
  font-style: italic;
}

._2n6csXeeTur38tQoEIuO3I,
._3W6nlUvkU_hew9tU3N7mJo {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

._2n6csXeeTur38tQoEIuO3I {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  background-color: #2fd0b7;
}

.EcZB1PjXKSY33bHGU2_F_ ._2n6csXeeTur38tQoEIuO3I {
  -webkit-animation: _5C6eJ42LOjYn0m1X1NFEE 1.6s;
  animation: _5C6eJ42LOjYn0m1X1NFEE 1.6s;
}

._3W6nlUvkU_hew9tU3N7mJo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 2.2rem;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

@media screen and (max-width: 1199px) {
  ._3W6nlUvkU_hew9tU3N7mJo {
    font-size: 2rem;
  }
}

.EcZB1PjXKSY33bHGU2_F_ ._3W6nlUvkU_hew9tU3N7mJo {
  -webkit-animation: _2epcpWefjjOagAs7XUY8cP 1.6s;
  animation: _2epcpWefjjOagAs7XUY8cP 1.6s;
}

@-webkit-keyframes _5C6eJ42LOjYn0m1X1NFEE {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  20%, 80% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes _5C6eJ42LOjYn0m1X1NFEE {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  20%, 80% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes _2epcpWefjjOagAs7XUY8cP {
  from {
    opacity: 0;
  }

  10% {
    opacity: 0;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
  }

  25%, 72% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes _2epcpWefjjOagAs7XUY8cP {
  from {
    opacity: 0;
  }

  10% {
    opacity: 0;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
  }

  25%, 72% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

._2IvEdE7obJI9CdO6y4etN {
  background-color: #f53175;
}

._17u0gbTw73R5yVTuf0djM0 input[type='email'] {
  border-bottom: #f53175 1px solid;
}

._1LvFuNX34YXq4Fas1Y7IL8 {
  opacity: 0.4;
  cursor: not-allowed;
}
._3BQTcs4699FAfm5oYATj8R {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  ._3BQTcs4699FAfm5oYATj8R {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

._15LX-P5VqF5_z1PAgxIxl1 {
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  ._15LX-P5VqF5_z1PAgxIxl1 {
    width: 40% !important;
  }
}

._2eFAdX_jMWZHUcfWm7zsKj {
  min-height: 240px;
  transition: -webkit-transform ease 0.25s;
  transition: transform ease 0.25s;
  transition: transform ease 0.25s, -webkit-transform ease 0.25s;
}

._437C6Qz821cu1c5KsNgHm ._2eFAdX_jMWZHUcfWm7zsKj {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

._3g3oU37YDqjfPVcMzw736b {
  padding: 80px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  background-color: #141315;
}

@media screen and (min-width: 800px) {
  ._3g3oU37YDqjfPVcMzw736b {
    width: 60%;
    min-width: 380px;
    padding: 55px 80px;
  }
}

._3iDxwYhoMejfcmCcQlGVU6 {
  color: #2fd0b7;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.3em;
}

@media screen and (min-width: 1200px) {
  ._3iDxwYhoMejfcmCcQlGVU6 {
    font-size: 1.4rem;
  }
}

._2IqBbYCPU64cL43MjHAuUf {
  padding-top: 20px;
  color: #fff;
  font-size: 2.6rem;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 400px) {
  ._2IqBbYCPU64cL43MjHAuUf {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1000px) {
  ._2IqBbYCPU64cL43MjHAuUf {
    font-size: 3.4rem;
  }
}

._3ocdJkH4I1Hy2Tm5eh10me {
  padding-bottom: 20px;
}
._1NlzQ67hu5V8s1b-5Cqdfp {
  position: relative;
  padding-top: 150px;
  z-index: 10;
}

._20nLQ6ecHxi4O4Q2iTwb2f {
  background-color: #1c1b20;
}

._3kIBsS71ZHV1ONZPDX5yuK {
  position: relative;
  top: -150px;
  background: #f5f5f5;
}

._3BRczj09qOrMeSpRuKasbo {
  height: 160px;
  margin-top: -150px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media screen and (min-width: 800px) {
  ._3BRczj09qOrMeSpRuKasbo {
    height: 200px;
  }
}

._2YdT13_xYVddlX7TUaLsbK {
  width: 100px;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  border: 0;
  border-top: 1px solid rgba(255,255,255,0.25);
}

@media screen and (max-width: 799px) {
  ._2YdT13_xYVddlX7TUaLsbK {
    display: none;
  }
}

._38teJQVTqVxBF_2Ngau82i {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

@media screen and (min-width: 800px) {
  ._38teJQVTqVxBF_2Ngau82i {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

._1tRJOfqhhPPD0BPGy67x_s {
  width: 60px;
  margin-bottom: 20px;
  fill: #fff;
}

@media screen and (min-width: 800px) {
  ._1tRJOfqhhPPD0BPGy67x_s {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

._6b38-sZ9kyWXVZj4RtaBD {
  font-size: 2rem;
  line-height: 1.3em;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 400px) {
  ._6b38-sZ9kyWXVZj4RtaBD {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1000px) {
  ._6b38-sZ9kyWXVZj4RtaBD {
    font-size: 3rem;
  }
}
._11dZQ0feZBQxC8v8TlpK_9 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 799px) {
  ._11dZQ0feZBQxC8v8TlpK_9 {
    font-size: 1.5rem;
  }
}

._3T4rE2Wd_CLc3oXUYNa9qe {
  color: #2fd0b7;
}

._2so49cTS4FgObs5YWd-roA {
  color: #fff;
}

.TehRpFvbk1OOhAPg1gkvv {
  display: block;
}

.device-desktop .TehRpFvbk1OOhAPg1gkvv {
  pointer-events: none;
  color: rgba(47,208,183,0);
  transition: color .25s linear,-webkit-transform .25s ease-out;
  transition: color .25s linear,transform .25s ease-out;
  transition: color .25s linear,transform .25s ease-out,-webkit-transform .25s ease-out;
}

.device-desktop ._3T4rE2Wd_CLc3oXUYNa9qe .TehRpFvbk1OOhAPg1gkvv {
  color: rgba(47,208,183,0);
}

.device-desktop ._2so49cTS4FgObs5YWd-roA .TehRpFvbk1OOhAPg1gkvv {
  color: rgba(255,255,255,0);
}

._2j1iT8JCSrAB0TmZKW3FZO {
  width: 35px;
  height: 14px;
  position: absolute;
  top: calc(50% - 7px);
  left: 100%;
  fill: transparent;
  stroke: #2fd0b7;
  stroke-width: 6px;
}

._3T4rE2Wd_CLc3oXUYNa9qe ._2j1iT8JCSrAB0TmZKW3FZO {
  stroke: #2fd0b7;
}

._2so49cTS4FgObs5YWd-roA ._2j1iT8JCSrAB0TmZKW3FZO {
  stroke: #fff;
}

.device-mobile ._2j1iT8JCSrAB0TmZKW3FZO {
  left: calc(100% + 15px);
}

.device-desktop ._2j1iT8JCSrAB0TmZKW3FZO {
  pointer-events: none;
  transition: -webkit-transform .275s;
  transition: transform .275s;
  transition: transform .275s, -webkit-transform .275s;
}

.vnz0aZiSPYxsfmCfLoATk {
  margin-right: 10px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.device-desktop ._11dZQ0feZBQxC8v8TlpK_9._2Hs0bOMZ_KrWbKFSNXk896 .TehRpFvbk1OOhAPg1gkvv,
.device-desktop ._11dZQ0feZBQxC8v8TlpK_9:hover .TehRpFvbk1OOhAPg1gkvv {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.device-desktop ._11dZQ0feZBQxC8v8TlpK_9._2Hs0bOMZ_KrWbKFSNXk896 ._2j1iT8JCSrAB0TmZKW3FZO,
.device-desktop ._11dZQ0feZBQxC8v8TlpK_9:hover ._2j1iT8JCSrAB0TmZKW3FZO {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.device-desktop ._3T4rE2Wd_CLc3oXUYNa9qe:hover ._2j1iT8JCSrAB0TmZKW3FZO,
.device-desktop ._3T4rE2Wd_CLc3oXUYNa9qe._2Hs0bOMZ_KrWbKFSNXk896 ._2j1iT8JCSrAB0TmZKW3FZO,
.device-desktop ._2so49cTS4FgObs5YWd-roA:hover ._2j1iT8JCSrAB0TmZKW3FZO,
.device-desktop ._2so49cTS4FgObs5YWd-roA._2Hs0bOMZ_KrWbKFSNXk896 ._2j1iT8JCSrAB0TmZKW3FZO {
  -webkit-transform: translateX(10px) !important;
  transform: translateX(10px) !important;
}

.device-desktop ._3T4rE2Wd_CLc3oXUYNa9qe .TehRpFvbk1OOhAPg1gkvv {
  color: #2fd0b7;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.device-desktop ._2so49cTS4FgObs5YWd-roA .TehRpFvbk1OOhAPg1gkvv,
.device-desktop ._2so49cTS4FgObs5YWd-roA .vnz0aZiSPYxsfmCfLoATk {
  color: #fff;
}

.device-desktop ._1URVy9kudSOMao7tZ_QpMF .TehRpFvbk1OOhAPg1gkvv {
  left: -45px;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.device-desktop ._1URVy9kudSOMao7tZ_QpMF .vnz0aZiSPYxsfmCfLoATk {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.device-desktop ._1URVy9kudSOMao7tZ_QpMF ._2j1iT8JCSrAB0TmZKW3FZO {
  left: calc(100% + 10px);
}

.device-mobile ._1URVy9kudSOMao7tZ_QpMF {
  margin-left: auto;
}

.device-desktop ._1URVy9kudSOMao7tZ_QpMF._2Hs0bOMZ_KrWbKFSNXk896 .TehRpFvbk1OOhAPg1gkvv,
.device-desktop ._1URVy9kudSOMao7tZ_QpMF._2Hs0bOMZ_KrWbKFSNXk896 ._2j1iT8JCSrAB0TmZKW3FZO,
.device-desktop ._1URVy9kudSOMao7tZ_QpMF:hover .TehRpFvbk1OOhAPg1gkvv,
.device-desktop ._1URVy9kudSOMao7tZ_QpMF:hover ._2j1iT8JCSrAB0TmZKW3FZO {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.device-desktop ._1URVy9kudSOMao7tZ_QpMF._2Hs0bOMZ_KrWbKFSNXk896 .vnz0aZiSPYxsfmCfLoATk,
.device-desktop ._1URVy9kudSOMao7tZ_QpMF:hover .vnz0aZiSPYxsfmCfLoATk {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
._1Y9pBzXvcIhof5VLoIJhjp {
  display: block;
}

._302seDnD3OIlrJkeRcPDph {
  padding-top: 30px;
  cursor: pointer;
}

@media screen and (min-width: 800px) {
  ._302seDnD3OIlrJkeRcPDph {
    display: -webkit-flex;
    display: flex;
    padding-top: 80px;
  }
}

._3yLGpKicPvC64ZlfJREi5s {
  width: 100%;
  margin-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._3yLGpKicPvC64ZlfJREi5s {
    max-width: 130px;
  }
}

@media screen and (min-width: 800px) {
  .device-IE11 ._3yLGpKicPvC64ZlfJREi5s {
    min-width: 130px;
  }
}

._1ltcG1lOZvAnUS--V-3Kgk {
  padding-bottom: 30px;
  color: rgba(20,19,21,0.7);
  border: 0;
  border-bottom: 1px solid rgba(20,19,21,0.1);
}

._1ltcG1lOZvAnUS--V-3Kgk ul li {
  margin-left: 0;
}

@media screen and (min-width: 800px) {
  ._1ltcG1lOZvAnUS--V-3Kgk {
    margin-right: 30px;
  }
}

._1QGf3kxOG34UtHPY_MUSch {
  margin-top: 1em;
}
._25E_muJMIoxQEN-VPS_SoQ {
  position: relative;
  margin-bottom: -120px;
  top: -120px;
}

@media screen and (min-width: 800px) {
  ._25E_muJMIoxQEN-VPS_SoQ {
    margin-bottom: -160px;
    top: -200px;
  }
}

._1fREpQt_ierl12-Jebs7y5 {
  background: #fff;
}

@media screen and (min-width: 800px) {
  ._1fREpQt_ierl12-Jebs7y5 {
    padding-top: 60px;
    padding-right: 120px;
    padding-bottom: 60px;
    padding-left: 120px;
  }
}

._2zrUvIMHRvY-4jseD_JI49 {
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
}

._24BI65C3Uk-8Ceut5wypMx {
  padding-top: 60px;
  padding-bottom: 10px;
}

@media screen and (min-width: 800px) {
  ._24BI65C3Uk-8Ceut5wypMx {
    padding-bottom: 40px;
  }
}

._3ZBfm6m_YdlnU61uw9wMv7 {
  max-width: 650px;
  padding-top: 30px;
}
._4FndokwfA53ba3I35TXh9 {
  position: relative;
}

._2a92K3vpDah4ZlVXCJF5fS {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

._2a92K3vpDah4ZlVXCJF5fS._1XgPX2jWajzpUzrjzA6H05,
._2a92K3vpDah4ZlVXCJF5fS._1Dq4SfjE5PWem1YEJEE61f {
  opacity: 1;
}

._2a92K3vpDah4ZlVXCJF5fS._3ewjom4_wiVG3j14YkH_to,
._2a92K3vpDah4ZlVXCJF5fS._2wKYHLLqywH-jdkIQnX5Y {
  opacity: 0;
}
@media screen and (max-width: 799px) {
  .OdoHwLOkNAedi7ruP2Mi6 {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1000px) {
  ._1R7xiv3XkaYMpBDd6Y_U2o {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media screen and (min-width: 1000px) {
  .vflwg1NsX_k28S4T-9hUL {
    width: 45%;
  }
}

._1CZ6ZuXKvwRxwVilI0UejE {
  margin-top: 30px;
  margin-bottom: 50px;
}

._2TwX7U5F5VQ7p3uZeBmFtx {
  width: 100%;
  height: 312px;
}

.cns_sJLrWwSxkrVz2x3D1 {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 0;
}

@media screen and (min-width: 1000px) {
  .cns_sJLrWwSxkrVz2x3D1 {
    min-width: 450px;
    margin-left: 10%;
    width: 35%;
  }
}

._2FlfYxAQYszm1ri47N7OiW {
  padding-bottom: 25px;
  position: relative;
  list-style-type: none;
  border-bottom: 1px solid rgba(20,19,21,0.1);
  cursor: pointer;
  transition: border ease 0.25s;
}

@media screen and (min-width: 800px) {
  ._2FlfYxAQYszm1ri47N7OiW {
    padding-bottom: 15px;
  }
}

._2FlfYxAQYszm1ri47N7OiW:not(:last-of-type) {
  margin-bottom: 25px;
}

@media screen and (min-width: 800px) {
  ._2FlfYxAQYszm1ri47N7OiW:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

._2FlfYxAQYszm1ri47N7OiW ._2T4DQ0zdfqr7MTVSA7ZzPb {
  display: block;
  padding-right: 30px;
  color: #141315;
  font-size: 2.4rem;
  transition: color ease 0.25s;
}

._2FlfYxAQYszm1ri47N7OiW ._5l6aibhV_91tfFxeSABb {
  width: 35px;
  position: absolute;
  top: calc(50% - 14px);
  top: 8px;
  right: 15px;
  fill: transparent;
  stroke: rgba(20,19,21,0.2);
  stroke-width: 4px;
  transition: stroke ease 0.25s, -webkit-transform ease-in-out 0.25s;
  transition: stroke ease 0.25s, transform ease-in-out 0.25s;
  transition: stroke ease 0.25s, transform ease-in-out 0.25s, -webkit-transform ease-in-out 0.25s;
}

._2FlfYxAQYszm1ri47N7OiW:hover {
  border-bottom-color: rgba(47,208,183,0.5);
}

._2FlfYxAQYszm1ri47N7OiW:hover ._2T4DQ0zdfqr7MTVSA7ZzPb {
  color: #2fd0b7;
}

._2FlfYxAQYszm1ri47N7OiW:hover ._5l6aibhV_91tfFxeSABb {
  stroke: #2fd0b7;
  -webkit-transform: translateX(6px);
  transform: translateX(6px);
}
._1xQX1dYpNYpkGYt7sbKSoX {
  margin-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  ._1xQX1dYpNYpkGYt7sbKSoX {
    margin-bottom: 180px;
  }
}

._1jb-k55_-MG3e9GFEsQoWL {
  position: relative;
  margin-bottom: 240px;
}

._32Okt8yk-lc9aAr5l6xZRa {
  width: 100%;
  position: relative;
}

.BW7lo95aHaLy-I-PAMaHk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .BW7lo95aHaLy-I-PAMaHk {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

._2lysO4FlgYsDTKl4X4Yogx {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: 'unit';
}

@media screen and (min-width: 1200px) {
  ._2lysO4FlgYsDTKl4X4Yogx {
    width: 70%;
    max-width: 600px;
    margin: auto;
  }
}

@media screen and (max-width: 1199px) {
  ._2lysO4FlgYsDTKl4X4Yogx {
    width: 80%;
  }
}

.RSICUoJ8VMpePhJKNwKPF,
._14J5QdBbLUWQ9GzyKsOd0y {
  grid-area: unit;
  transition: all 250ms;
}

.LghR7LfFJ8heU5bTmJJLQ .RSICUoJ8VMpePhJKNwKPF {
  opacity: 0;
  pointer-events: none;
}

.device-IE11 .LghR7LfFJ8heU5bTmJJLQ .RSICUoJ8VMpePhJKNwKPF {
  display: none;
}

._14J5QdBbLUWQ9GzyKsOd0y {
  position: relative;
  padding-bottom: 100px;
  opacity: 0;
  pointer-events: none;
}

.device-IE11 ._14J5QdBbLUWQ9GzyKsOd0y {
  display: none;
}

.LghR7LfFJ8heU5bTmJJLQ ._14J5QdBbLUWQ9GzyKsOd0y.LghR7LfFJ8heU5bTmJJLQ {
  display: block;
  opacity: 1;
  pointer-events: initial;
}

@media screen and (min-width: 800px) {
  ._14J5QdBbLUWQ9GzyKsOd0y {
    padding-bottom: 50px;
  }
}

._2b65XLGMJmrW0UwGkf135b {
  max-width: 500px;
  margin: 30px 0;
  display: block;
}

.o03P7xgTVbX2r957NFbjw {
  width: 100%;
  height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .o03P7xgTVbX2r957NFbjw {
    margin-bottom: 25px;
  }
}

._1g-JpwacRIgBnt5tKbk4eb,
._2v1aKb6owndO2P_cMmUuqH {
  width: 60px;
  height: 44px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #efefef;
}

._1g-JpwacRIgBnt5tKbk4eb::before,
._2v1aKb6owndO2P_cMmUuqH::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  background-image: linear-gradient(0deg, #47dba4, #46d1be);
  background-size: cover;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

._1g-JpwacRIgBnt5tKbk4eb::before {
  -webkit-transform-origin: right;
  transform-origin: right;
}

._2v1aKb6owndO2P_cMmUuqH::before {
  -webkit-transform-origin: left;
  transform-origin: left;
}

._1g-JpwacRIgBnt5tKbk4eb svg,
._2v1aKb6owndO2P_cMmUuqH svg {
  position: relative;
  width: 28px;
  height: 15px;
  fill: transparent;
  stroke: #adadad;
  stroke-width: 4px;
  transition: stroke 250ms linear;
}

._1g-JpwacRIgBnt5tKbk4eb svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.device-desktop ._1g-JpwacRIgBnt5tKbk4eb:hover svg,
.device-desktop ._1g-JpwacRIgBnt5tKbk4eb:active svg,
.device-desktop ._1g-JpwacRIgBnt5tKbk4eb:focus svg,
.device-desktop ._2v1aKb6owndO2P_cMmUuqH:hover svg,
.device-desktop ._2v1aKb6owndO2P_cMmUuqH:active svg,
.device-desktop ._2v1aKb6owndO2P_cMmUuqH:focus svg {
  stroke: #fff;
}

.device-desktop ._1g-JpwacRIgBnt5tKbk4eb:hover::before,
.device-desktop ._1g-JpwacRIgBnt5tKbk4eb:active::before,
.device-desktop ._1g-JpwacRIgBnt5tKbk4eb:focus::before,
.device-desktop ._2v1aKb6owndO2P_cMmUuqH:hover::before,
.device-desktop ._2v1aKb6owndO2P_cMmUuqH:active::before,
.device-desktop ._2v1aKb6owndO2P_cMmUuqH:focus::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

._2g07CUWq0PcqW8XPwNfP_o {
  margin-left: auto;
  color: #2fd0b7;
  cursor: pointer;
}

._1tIQlSUgelhoDEy7frQSWn {
  position: absolute;
  top: 12px;
  right: 100%;
  padding-right: 30px;
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  opacity: 0;
  transition: opacity 250ms;
}

.LghR7LfFJ8heU5bTmJJLQ ._1tIQlSUgelhoDEy7frQSWn {
  opacity: 1;
}

.xmnrgJyazacOiH_chuOpc {
  height: 100%;
}

@media screen and (max-width: 1199px) {
  ._1o0d47-c_ds_Yw8NeM3FrC {
    width: 80%;
    padding-bottom: 80%;
  }
}

@media screen and (min-width: 1200px) {
  ._1o0d47-c_ds_Yw8NeM3FrC {
    width: 30% !important;
    min-height: 60vh;
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
  }
}

._1bYdgelwSMAXcdcjgLwuDD {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  -webkit-transform: scaleY(1.2);
  transform: scaleY(1.2);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  background: #f5f5f5;
}

@media screen and (min-width: 1200px) {
  ._1bYdgelwSMAXcdcjgLwuDD {
    width: 75%;
  }
}

@media screen and (max-width: 799px) {
  ._1bYdgelwSMAXcdcjgLwuDD {
    width: 100%;
    top: 50px;
  }
}

@media screen and (min-width: 1200px) {
  ._1bYdgelwSMAXcdcjgLwuDD {
    -webkit-transform: scaleY(1.4);
    transform: scaleY(1.4);
  }

  .LghR7LfFJ8heU5bTmJJLQ ._1bYdgelwSMAXcdcjgLwuDD {
    -webkit-transform: none;
    transform: none;
  }
}

._1aIM1wtr7f_Ucm5qfiEXvo {
  margin-top: 50px;
}

@media screen and (max-width: 1199px) {
  ._1aIM1wtr7f_Ucm5qfiEXvo {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.lXwhVx_Pp9ceI5AecVw-f {
  padding-bottom: 30px;
}

@media screen and (min-width: 800px) {
  .lXwhVx_Pp9ceI5AecVw-f {
    padding-top: 75px;
  }
}

._29vwggKRk3ULzasC7xUlEP {
  width: 100%;
  margin-bottom: 12px;
  color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  ._29vwggKRk3ULzasC7xUlEP {
    margin-bottom: 30px;
  }
}

._1RuyEdyuLMbHeu6T2hSJH7 {
  padding-top: 0;
  overflow-x: hidden;
}

._3ZehdwCA8SHeeE5N6W4Qam {
  max-width: 1640px;
  position: relative;
  padding-bottom: 150px;
}

@media screen and (min-width: 800px) {
  ._3ZehdwCA8SHeeE5N6W4Qam {
    padding-top: 100px;
    padding-bottom: 220px;
  }
}

.KmBrh2dAWrPy_ucr2t_nc {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 3%;
  left: 0;
  z-index: 0;
  background-image: linear-gradient(240deg, #47dba4, #46d1be);
  background-size: cover;
}

@media screen and (min-width: 800px) {
  .KmBrh2dAWrPy_ucr2t_nc {
    top: 6%;
  }
}

._3kCcxKjy9qNhGYAkXMgqkB {
  padding-top: 10px;
  padding-bottom: 60px;
}

@media screen and (min-width: 1200px) {
  ._3kCcxKjy9qNhGYAkXMgqkB {
    padding-bottom: 78px;
  }
}

._1O5CBj62ZR-QZKUZ9aybjZ {
  text-align: center;
}

.R8AjUux-CvlMTW4ADcE0b {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 700px) {
  .R8AjUux-CvlMTW4ADcE0b {
    width: 72%;
  }
}

@media screen and (min-width: 800px) {
  .R8AjUux-CvlMTW4ADcE0b {
    width: 100%;
    max-width: 1200px;
  }
}
._1qcG2BEvSaB-KH35fDzmoL {
  border-left: 2px solid #2fd0b7;
  padding-left: 50px;
  padding-right: 0;
}

@media screen and (max-width: 699px) {
  ._1qcG2BEvSaB-KH35fDzmoL {
    padding-left: 25px;
  }
}

._2BuZS3DS2KCYL0dx_DHY8v {
  font-family: "Playfair Display Italic",sans-serif;
  font-size: 32px;
  line-height: 1.4;
  color: #1c1b20;
}

@media screen and (max-width: 999px) {
  ._2BuZS3DS2KCYL0dx_DHY8v {
    font-size: 28px;
  }
}

@media screen and (max-width: 399px) {
  ._2BuZS3DS2KCYL0dx_DHY8v {
    font-size: 20px;
  }
}

._2kciU8tccxj4Sf5pcT1H7u {
  margin-top: 10px;
  font-size: 16px;
}
._1MMsjoll53f64xKKGJuTHB {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #141315;
  padding: 11.11111px;
  border-radius: 60px;
  margin-right: 20px;
  margin-left: 0;
}

._1MMsjoll53f64xKKGJuTHB:hover ._17O7gJQ-UcglXKq03GlaSF {
  fill: #2fd0b7;
}

@media screen and (max-width: 1199px) {
  ._1MMsjoll53f64xKKGJuTHB {
    margin-right: 10px;
    margin-left: 10px;
  }
}

._17O7gJQ-UcglXKq03GlaSF {
  line-height: 1.2rem;
  fill: #fff;
  transition: fill 0.25s ease;
}
._3BtihTi8gP4Psk2LEeQfWx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 80px 120px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1399px) {
  ._3BtihTi8gP4Psk2LEeQfWx {
    padding: 80px 80px;
  }
}

@media screen and (max-width: 1199px) {
  ._3BtihTi8gP4Psk2LEeQfWx {
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 699px) {
  ._3BtihTi8gP4Psk2LEeQfWx {
    padding: 40px 20px;
  }
}

._2k82Qd_6Z6oBbwTfxTJtHF {
  width: 360px;
  height: 520px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  position: relative;
}

@media screen and (max-width: 1399px) {
  ._2k82Qd_6Z6oBbwTfxTJtHF {
    width: 340px;
  }
}

@media screen and (max-width: 1199px) {
  ._2k82Qd_6Z6oBbwTfxTJtHF {
    width: 440px;
    margin-bottom: 60px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 899px) {
  ._2k82Qd_6Z6oBbwTfxTJtHF {
    width: 360px;
    height: 425px;
  }
}

@media screen and (max-width: 549px) {
  ._2k82Qd_6Z6oBbwTfxTJtHF {
    width: 60vw;
    height: 250px;
    margin-bottom: 30px;
  }
}

._3mNoP1y7mgY_IzhmWrbzk2 {
  position: absolute;
  bottom: 70px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 549px) {
  ._3mNoP1y7mgY_IzhmWrbzk2 {
    bottom: 35px;
  }
}

._6L09C_Zah8db5DF1LJyLt {
  height: 100%;
}

._1TN57OYnmkbhktKf3cWOzC {
  width: 100%;
  padding-left: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media screen and (max-width: 1399px) {
  ._1TN57OYnmkbhktKf3cWOzC {
    padding-left: 60px;
  }
}

@media screen and (max-width: 1199px) {
  ._1TN57OYnmkbhktKf3cWOzC {
    padding-left: 0;
  }
}

._3Nrdmm4pO5EKpshW9Uthzc {
  font-size: 40px;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1199px) {
  ._3Nrdmm4pO5EKpshW9Uthzc {
    text-align: center;
  }
}

@media screen and (max-width: 549px) {
  ._3Nrdmm4pO5EKpshW9Uthzc {
    font-size: 24px;
  }
}

._3K9mk1-4sp2c7ZpFcB1-_e {
  font-size: 14px;
  color: #2fd0b7;
  letter-spacing: 1.12px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1199px) {
  ._3K9mk1-4sp2c7ZpFcB1-_e {
    text-align: center;
  }
}

@media screen and (max-width: 549px) {
  ._3K9mk1-4sp2c7ZpFcB1-_e {
    font-size: 12px;
  }
}

._1dIOE-arTZHQF-3kVngkGY {
  margin-top: 20px;
}

@media screen and (max-width: 1199px) {
  ._1dIOE-arTZHQF-3kVngkGY {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

._2DLvIvYk2XbJAuqiMiVF3k {
  font-size: 20px;
  letter-spacing: 0.4px;
  text-align: left;
}

._2DLvIvYk2XbJAuqiMiVF3k strong {
  font-family: "proxima-nova",sans-serif;
  font-weight: 700;
  color: rgba(28,27,32,0.8);
}

.kzhQguxMUzlWejXUKRZkV {
  margin: 30px 0;
}

._2wB5B9nNH5s0yGB3Cl7h9z {
  display: -webkit-flex;
  display: flex;
}

@media screen and (max-width: 1199px) {
  ._2wB5B9nNH5s0yGB3Cl7h9z {
    display: none;
  }
}

._1Yq606yM1PcMLyJvMnbjLY {
  display: none;
}

@media screen and (max-width: 1199px) {
  ._1Yq606yM1PcMLyJvMnbjLY {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 40px;
  }
}
._3PjZPk5LRLMVdw-aVGOUN_,
._22ZzSZfNTsZBPgQhbT5Ez7 {
  padding-bottom: 1px;
  padding-top: 20px;
  margin: 0 auto;
}

@media screen and (max-width: 999px) {
  ._3PjZPk5LRLMVdw-aVGOUN_:first-of-type,
  ._22ZzSZfNTsZBPgQhbT5Ez7:first-of-type {
    padding-top: 0;
  }
}

@media screen and (min-width: 1000px) {
  ._3PjZPk5LRLMVdw-aVGOUN_,
  ._22ZzSZfNTsZBPgQhbT5Ez7 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-bottom: 10px;
  }
}

._1OLkAAbohj9ZntOAnXiFxr {
  padding-bottom: 1px;
  padding-top: 20px;
}

@media screen and (max-width: 999px) {
  ._1OLkAAbohj9ZntOAnXiFxr:first-of-type {
    padding-top: 0;
  }
}

._2Vssm8hOV8N5_XlKmij3PL {
  padding-bottom: 60px;
}

._22ZzSZfNTsZBPgQhbT5Ez7 {
  padding-top: 0px;
}
._36Ji1dFfM-PR4uoloBUQ3u {
  height: 40vw;
}

._7xOttuYxSrxJozZuFzrHR {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  z-index: 1;
}

._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 ._3xvZICN5TFtgr0jIyyC04_,
._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 .kYrOcBp2O_rZOeX3kCggk {
  color: #fff;
  transition: all .25s ease-in-out;
}

._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 .fW1Ve5bQXbKZXpxhVq84a {
  color: #141315;
}

._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 ._2GC0ZSeIawYLyNRO7YWEYM,
._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 ._3BQAMZyzSqGDa25jQmxhVn {
  color: rgba(20,19,21,0.7);
}

._7xOttuYxSrxJozZuFzrHR:hover ._1J1EjkMaGhbcJy57FaSyb6 ._2vK8pCtVnYEVvI_7fKuQDl {
  color: #333;
  transition: all .25s ease-in-out;
}

@media screen and (max-width: 699px) {
  ._7xOttuYxSrxJozZuFzrHR:not(:last-child) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 700px) {
  ._7xOttuYxSrxJozZuFzrHR {
    -webkit-flex-basis: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    margin-bottom: 20px;
  }

  ._7xOttuYxSrxJozZuFzrHR:nth-child(even) {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1000px) {
  ._7xOttuYxSrxJozZuFzrHR {
    -webkit-flex-basis: calc(33.33% - 20px);
    flex-basis: calc(33.33% - 20px);
    margin-bottom: 30px;
  }

  ._7xOttuYxSrxJozZuFzrHR:nth-child(even) {
    margin-left: unset;
  }
}

@media screen and (min-width: 700px) {
  ._7xOttuYxSrxJozZuFzrHR._2Yw8XjKeQyozwU6tYWXMt6:nth-child(3n+3) {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}

@media screen and (min-width: 1000px) {
  ._7xOttuYxSrxJozZuFzrHR._2Yw8XjKeQyozwU6tYWXMt6 {
    -webkit-flex-basis: calc(50% - 60px / 4);
    flex-basis: calc(50% - 60px / 4);
  }

  ._7xOttuYxSrxJozZuFzrHR._2Yw8XjKeQyozwU6tYWXMt6:nth-child(3n+2) {
    margin: 0 30px 30px 30px;
  }
}

@media screen and (min-width: 1000px) {
  ._7xOttuYxSrxJozZuFzrHR.eEGh7DMSNPWz3UXHY3XGk {
    -webkit-flex-basis: calc(33.33% - 20px);
    flex-basis: calc(33.33% - 20px);
  }

  ._7xOttuYxSrxJozZuFzrHR.eEGh7DMSNPWz3UXHY3XGk:nth-child(3n+2) {
    margin: 0 30px 30px 30px;
  }
}

@media screen and (min-width: 700px) {
  ._7xOttuYxSrxJozZuFzrHR.FDJ2EOQ3L924FKL3SuTzT {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }

  ._7xOttuYxSrxJozZuFzrHR.FDJ2EOQ3L924FKL3SuTzT:nth-child(3n+2) {
    margin: 0;
  }
}

@media screen and (min-width: 1000px) {
  ._7xOttuYxSrxJozZuFzrHR.FDJ2EOQ3L924FKL3SuTzT {
    -webkit-flex-basis: calc(50% - 15px);
    flex-basis: calc(50% - 15px);
    margin-bottom: 0;
  }

  ._7xOttuYxSrxJozZuFzrHR.FDJ2EOQ3L924FKL3SuTzT:nth-child(3n+2) {
    margin: 0 0 0 30px;
  }
}

@media screen and (min-width: 700px) {
  ._7xOttuYxSrxJozZuFzrHR._1DOZybx0GzlT3D2iTk-HdK {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }

  ._7xOttuYxSrxJozZuFzrHR._1DOZybx0GzlT3D2iTk-HdK:nth-child(2n) {
    margin: 0 0 20px 0;
  }
}

@media screen and (min-width: 1000px) {
  ._7xOttuYxSrxJozZuFzrHR._1DOZybx0GzlT3D2iTk-HdK {
    -webkit-flex-basis: calc(50% - 15px);
    flex-basis: calc(50% - 15px);
    margin-bottom: 30px;
  }

  ._7xOttuYxSrxJozZuFzrHR._1DOZybx0GzlT3D2iTk-HdK:nth-child(2n) {
    margin: 0 0 30px 30px;
  }
}

._3xTwJJIfa6opYjd4T2AGEA {
  position: relative;
  overflow: hidden;
  height: 40vw;
}

@media screen and (min-width: 700px) {
  ._3xTwJJIfa6opYjd4T2AGEA {
    height: 20vw;
  }
}

@media screen and (min-width: 1500px) {
  ._3xTwJJIfa6opYjd4T2AGEA {
    height: 340px;
  }
}

._1PLdgW4cdYUbIDQYtcULWs {
  background: linear-gradient(180deg, rgba(2,0,36,0.200718) 0%, rgba(0,0,0,0.0502976) 30%, rgba(0,0,0,0) 100%);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

._27F7HP6jieQvrLC2LBDo20 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  transition: -webkit-transform ease 0.25s;
  transition: transform ease 0.25s;
  transition: transform ease 0.25s, -webkit-transform ease 0.25s;
}

._3VoFlJyNsIrJQEDxRJ87VM ._27F7HP6jieQvrLC2LBDo20 {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

._2qAjgG1eCfR4VKOIINNKLb {
  position: absolute;
  top: 25px;
  right: 40px;
  z-index: 1;
  font-size: 1.4rem;
}

._1J1EjkMaGhbcJy57FaSyb6 {
  padding: 20px 40px 30px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  transition: all .25s ease-in-out;
}

._1J1EjkMaGhbcJy57FaSyb6:before {
  content: '';
  background-image: linear-gradient(180deg, #47dba4, #46d1be);
  background-size: cover;
  width: 100%;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -5;
  transition: all .25s ease-in-out;
}

._1ihpZL7caMfts2GR65xqis {
  background-color: #f5f5f5;
}

._2xakoqKJ4VudFU1Aws58Sq {
  width: 100%;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  z-index: 2;
}

._3xvZICN5TFtgr0jIyyC04_ {
  padding-top: 15px;
  padding-bottom: 20px;
  color: #141315;
  font-size: 26px;
  line-height: 1.385;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all .25s ease-in-out;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  ._3xvZICN5TFtgr0jIyyC04_ {
    font-size: 22px;
  }
}

@media screen and (max-width: 399px) {
  ._3xvZICN5TFtgr0jIyyC04_ {
    font-size: 22px;
  }
}

.kYrOcBp2O_rZOeX3kCggk {
  font-size: 16px;
  color: rgba(28,27,32,0.8);
  max-height: 80px;
  line-height: 1.8;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all .25s ease-in-out;
}

._2vK8pCtVnYEVvI_7fKuQDl {
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #adadad;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.4px;
  text-align: left;
  z-index: 2;
  transition: all .25s ease-in-out;
}

._1uOMd0XblbdJ9MUbl9sFee {
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 35px;
  font-size: 14px;
  z-index: 1;
}

._2GC0ZSeIawYLyNRO7YWEYM {
  font-size: 14px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.98px;
  line-height: 1.7;
  text-align: left;
  color: #2fd0b7;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  z-index: 2;
  transition: all .25s ease-in-out;
}

._3BQAMZyzSqGDa25jQmxhVn {
  color: rgba(20,19,21,0.7);
  opacity: 0.4;
  transition: all .25s ease-in-out;
}

._1JmaWBZyvNJ3om3fcFyxvq {
  display: block;
}

@media screen and (max-width: 999px) {
  ._1JmaWBZyvNJ3om3fcFyxvq {
    display: none;
  }
}

._2hLMaVWZ4HvCdrnVyi40NY {
  display: none;
}

@media screen and (max-width: 999px) {
  ._2hLMaVWZ4HvCdrnVyi40NY {
    display: block;
  }
}
._36wXxLNI9ZFOQM_bxvGUuI {
  position: relative;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 30px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  z-index: 1;
  width: 100%;
}

._36wXxLNI9ZFOQM_bxvGUuI:last-of-type {
  margin-bottom: 0;
}
._3_roAbLQGAn0Qr7SnD6mAl {
  position: relative;
  display: inline-block;
  color: #141315;
  white-space: nowrap;
  cursor: pointer;
}

.KzGNb6mT3zF-MEyuRuH17 {
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._1fL-e9scbqpHcB7d6ih31g {
  width: 35px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  fill: transparent;
  stroke: #2fd0b7;
  stroke-width: 6px;
  pointer-events: none;
  -webkit-transform: rotate(180deg) translateX(0);
  transform: rotate(180deg) translateX(0);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.device-desktop ._3_roAbLQGAn0Qr7SnD6mAl:hover ._1fL-e9scbqpHcB7d6ih31g {
  -webkit-transform: rotate(180deg) translateX(10px);
  transform: rotate(180deg) translateX(10px);
}
._1dZ-xkJSa5gfN1pdBPywsJ {
  background-color: #fff;
  padding-bottom: 20px;
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._1dZ-xkJSa5gfN1pdBPywsJ {
    padding-right: 9%;
    padding-left: 9%;
  }
}

@media screen and (min-width: 700px) and (max-width: 799px) {
  ._1dZ-xkJSa5gfN1pdBPywsJ {
    padding-right: 9%;
    padding-left: 9%;
  }
}

@media screen and (max-width: 699px) {
  ._1dZ-xkJSa5gfN1pdBPywsJ {
    padding: 0 20px 20px 20px;
  }
}

._1X7MMuuhqF3hcg5KErHW10 {
  margin-top: 50px;
}

.IBVCHjIXj-szYl-zu89ns {
  max-width: 1400px;
  width: 100%;
  min-height: 2vw;
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  color: #fff;
  transition: color ease-out 0.25s;
}

._3smTeYQhItCFgj2bLAORmY {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: 1.26px;
  margin-bottom: 10px;
  text-align: left;
  color: #ccc;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._1uS79TkVjCC9MdrY2jWnvm {
  color: #141315;
  font-size: 40px;
  line-height: 1.125;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 399px) {
  ._1uS79TkVjCC9MdrY2jWnvm {
    font-size: 30px;
  }
}

._2LHFwKVW146focB2tWVr2L {
  padding-bottom: 10px;
}

._3bSWOUiHfZ3wQ4YTi1hOa {
  display: block;
}

._1XkKlaqzt_7AN9-HQfhiVe {
  display: block;
}

._1XkKlaqzt_7AN9-HQfhiVe span {
  color: #626262;
}

._1XkKlaqzt_7AN9-HQfhiVe svg {
  stroke: #626262;
}

._1XkKlaqzt_7AN9-HQfhiVe:hover span {
  color: #333;
}

._1XkKlaqzt_7AN9-HQfhiVe:hover svg {
  stroke: #333;
  -webkit-transform: rotate(180deg) translateX(10px);
  transform: rotate(180deg) translateX(10px);
}
._3eGqw2G5EAmfGeKZfpPn5C {
  font-size: 18px;
  text-align: left;
  margin: 0 0 20px 0;
  color: #ccc;
  font-style: normal;
  letter-spacing: 1.26px;
  text-transform: uppercase;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  ._3eGqw2G5EAmfGeKZfpPn5C {
    font-size: 16px;
  }
}

@media screen and (max-width: 699px) {
  ._3eGqw2G5EAmfGeKZfpPn5C {
    font-size: 14px;
    margin: 20px 0 0 0;
  }
}
._1c6oI7QoX6sQOpF1yUsNbd {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3.35rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  transition: -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s, -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
}

@media screen and (min-width: 800px) {
  ._1c6oI7QoX6sQOpF1yUsNbd {
    padding: 3rem 3.5rem;
  }
}

@media screen and (min-width: 1200px) {
  ._1c6oI7QoX6sQOpF1yUsNbd {
    padding: 5.5rem 6.5rem;
  }
}

@media screen and (min-width: 800px) {
  ._1c6oI7QoX6sQOpF1yUsNbd._1OSNlOaNX9P47tfHeKJhHT {
    width: calc(50% - 60px / 4);
  }
}

@media screen and (min-width: 1200px) {
  ._1c6oI7QoX6sQOpF1yUsNbd._1OSNlOaNX9P47tfHeKJhHT {
    width: calc(50% - 60px / 2);
  }
}

._1c6oI7QoX6sQOpF1yUsNbd:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #141315;
  opacity: 0.38;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  transition: -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s, -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
}

@media screen and (min-width: 800px) {
  ._1OSNlOaNX9P47tfHeKJhHT:after {
    width: 70%;
    height: 300%;
    top: -200%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}

._2JPzp9JJfdk2ieN4eAw-u0,
._24AEjLAHykxV0iZcRCsZxa,
.IV0YFPRGwS2YIb3S-0xwO,
._3AhulaERbs2LVjopRtrmt7,
._265kCUjj2kV3naNCAQS7Fm {
  z-index: 1;
}

.IV0YFPRGwS2YIb3S-0xwO {
  width: 100%;
  margin: 2.2rem 0 2rem 0;
  border: 0;
  border-top: 1px solid #fff;
  opacity: 0.3;
}

@media screen and (min-width: 1200px) {
  .IV0YFPRGwS2YIb3S-0xwO {
    margin: 2.7rem 0 2.5rem 0;
  }
}

._2JPzp9JJfdk2ieN4eAw-u0 {
  margin-bottom: 1.8rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.3em;
}

@media screen and (min-width: 1200px) {
  ._2JPzp9JJfdk2ieN4eAw-u0 {
    font-size: 1.4rem;
  }
}

._24AEjLAHykxV0iZcRCsZxa {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._3AhulaERbs2LVjopRtrmt7 {
  display: none;
}

@media screen and (min-width: 550px) {
  ._3AhulaERbs2LVjopRtrmt7 {
    width: 100%;
    display: initial;
  }
}

@media screen and (min-width: 800px) {
  ._3AhulaERbs2LVjopRtrmt7 {
    width: initial;
  }
}

.device-desktop ._3AhulaERbs2LVjopRtrmt7 {
  max-width: 50%;
  line-height: 2.8rem;
}

.device-desktop ._265kCUjj2kV3naNCAQS7Fm {
  left: -45px;
  margin-left: auto;
}

@media screen and (max-width: 549px) {
  ._265kCUjj2kV3naNCAQS7Fm {
    max-width: 100%;
    right: -7px;
    font-size: 1.5rem;
  }

  ._265kCUjj2kV3naNCAQS7Fm svg {
    width: 25px;
  }
}
._2Nl9PPBmgrilcJNg373-Jz {
  position: relative;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  overflow: hidden;
  margin-top: 30px;
}

@media screen and (min-width: 800px) {
  ._2Nl9PPBmgrilcJNg373-Jz {
    -webkit-flex-basis: calc(50% - 60px / 4);
    flex-basis: calc(50% - 60px / 4);
  }
}

@media screen and (min-width: 1200px) {
  ._2Nl9PPBmgrilcJNg373-Jz {
    -webkit-flex-basis: calc(50% - 60px / 2);
    flex-basis: calc(50% - 60px / 2);
    margin-top: 60px;
  }
}

:root.keyboard-focus ._2Nl9PPBmgrilcJNg373-Jz:focus-within {
  outline: #16a58e 5px auto;
}

._2Nl9PPBmgrilcJNg373-Jz._213RAe2aC7gy5IvWckUO9L {
  -webkit-flex-basis: 100% !important;
  flex-basis: 100% !important;
}

._2Nl9PPBmgrilcJNg373-Jz._213RAe2aC7gy5IvWckUO9L:nth-child(2):nth-last-child(2) {
  -webkit-order: -1;
  order: -1;
}

.device-Firefox ._2Nl9PPBmgrilcJNg373-Jz {
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

._2Nl9PPBmgrilcJNg373-Jz:before {
  content: '';
  width: 100%;
  padding-top: 135%;
  display: block;
}

@media screen and (min-width: 700px) {
  ._2Nl9PPBmgrilcJNg373-Jz:before {
    padding-top: 100%;
  }
}

._213RAe2aC7gy5IvWckUO9L:before {
  padding-top: 150%;
}

@media screen and (min-width: 800px) {
  ._213RAe2aC7gy5IvWckUO9L:before {
    padding-top: 50%;
  }
}

._2Nl9PPBmgrilcJNg373-Jz._2LFDYbVYAsCiwe-BzNqROL:before {
  padding-top: 135%;
}

._2h_sl_52UtHUA4RMYR3IEn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1920px;
  max-width: 1920px;
  height: 1080px;
  max-height: 100%;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

._1c3Gf_yJmdem2QkiTUN2fN {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._1cf3w7v9nvunzHy7v2PoJq {
  width: 70px;
  position: absolute;
  top: 3.5rem;
  left: 4.5rem;
  z-index: 1;
  opacity: 0;
  transition: -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s, -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
}

@media screen and (min-width: 800px) {
  ._1cf3w7v9nvunzHy7v2PoJq {
    width: 55px;
    left: 3.5rem;
  }
}

@media screen and (min-width: 1200px) {
  ._1cf3w7v9nvunzHy7v2PoJq {
    width: 65px;
    top: 5rem;
    left: 6.5rem;
  }

  ._2LFDYbVYAsCiwe-BzNqROL ._1cf3w7v9nvunzHy7v2PoJq {
    left: 4rem;
  }
}

._1zY1E4CfFdc8XP6sV6S_zD {
  position: absolute;
  top: 0;
  right: 3.35rem;
  width: 35px;
  height: 96px;
}

@media screen and (min-width: 800px) {
  ._1zY1E4CfFdc8XP6sV6S_zD {
    width: 30px;
    height: 96px;
    right: 3.5rem;
  }
}

@media screen and (min-width: 1200px) {
  ._1zY1E4CfFdc8XP6sV6S_zD {
    width: 40px;
    height: 127px;
    right: 6.5rem;
  }
}
._1fvK4oQck2Z3Q5YudMPH0H {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: -60px;
  margin-bottom: 60px;
}

._1fvK4oQck2Z3Q5YudMPH0H:last-child {
  margin-bottom: 0;
}

._1B1WvGmK4F82H9GFwIzaC9:before {
  content: '';
  position: absolute;
  top: 25%;
  right: -20px;
  bottom: 5%;
  left: -20px;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._1B1WvGmK4F82H9GFwIzaC9:before {
    left: -9%;
    right: -9%;
  }
}

@media screen and (min-width: 1500px) {
  ._1B1WvGmK4F82H9GFwIzaC9:before {
    left: -7%;
    right: -7%;
  }
}

.-uWQCklsnFmOKAjili0di:before {
  bottom: -15%;
}

@media screen and (min-width: 800px) {
  .-uWQCklsnFmOKAjili0di:before {
    bottom: -40%;
  }
}
.ykvYKBHsFoQZ_G31FEx1k {
  font-size: 40px;
  margin-top: 30px;
  margin-bottom: 60px;
}

@media screen and (max-width: 1199px) {
  .ykvYKBHsFoQZ_G31FEx1k {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 999px) {
  .ykvYKBHsFoQZ_G31FEx1k {
    margin-top: 15px;
  }
}

.SuZxkPW_onqUpiKW29PWM {
  margin-top: 160px;
  margin-bottom: -80px;
}

@media screen and (max-width: 549px) {
  .SuZxkPW_onqUpiKW29PWM {
    margin-bottom: -50px;
  }
}
.N_mDr3IfBW8emYSNU9CHK {
  position: relative;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: #141315;
}

@media screen and (min-width: 1000px) {
  .N_mDr3IfBW8emYSNU9CHK {
    padding: 42px;
  }
}

.N_mDr3IfBW8emYSNU9CHK:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(180deg, #47dba4, #46d1be);
  background-size: cover;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  transition: -webkit-transform ease-out .15s;
  transition: transform ease-out .15s;
  transition: transform ease-out .15s, -webkit-transform ease-out .15s;
}

._3s5BSLwGcQFVA05-cqLOc {
  position: relative;
  padding-bottom: 20%;
  z-index: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
}

@media screen and (min-width: 700px) {
  ._3s5BSLwGcQFVA05-cqLOc {
    padding-bottom: 40%;
  }
}

._2wm3Uz12UladtFqTvC4nby {
  min-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
}

@media screen and (min-width: 700px) {
  ._2wm3Uz12UladtFqTvC4nby {
    font-size: 1.6rem;
  }
}

._3f9h_3jSR0RPScIKWQZ0iG,
._2fdgzvR9Vn6JGnHtEfrfcH {
  display: inline-block;
  vertical-align: middle;
  transition: color .075s linear;
}

._3f9h_3jSR0RPScIKWQZ0iG {
  color: #2fd0b7;
}

._3f9h_3jSR0RPScIKWQZ0iG:not(:last-child):after {
  content: '';
  width: 15px;
  height: 1px;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
  vertical-align: middle;
  background-color: #fff;
  opacity: 0.3;
  transition: opacity .075s linear;
}

@media screen and (min-width: 800px) {
  ._3f9h_3jSR0RPScIKWQZ0iG:not(:last-child):after {
    width: 20px;
    margin-right: 15px;
    margin-left: 15px;
  }
}

._2fdgzvR9Vn6JGnHtEfrfcH {
  color: rgba(255,255,255,0.3);
}

._3IRCPcbxf_zFqKK_BbgZ4O:not(:first-child) {
  margin-top: 2rem;
}

.LjW92hDxdynCSArB31vWp {
  position: relative;
  z-index: 2;
}

.device-desktop .N_mDr3IfBW8emYSNU9CHK:hover:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.device-desktop .N_mDr3IfBW8emYSNU9CHK:hover ._3f9h_3jSR0RPScIKWQZ0iG,
.device-desktop .N_mDr3IfBW8emYSNU9CHK:hover ._2fdgzvR9Vn6JGnHtEfrfcH {
  color: #fff;
}

.device-desktop .N_mDr3IfBW8emYSNU9CHK:hover ._3f9h_3jSR0RPScIKWQZ0iG:after {
  opacity: 1;
}
._AHpyW2U_kD2k9s2H_99f {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% 250px;
}

@media screen and (min-width: 1000px) {
  ._AHpyW2U_kD2k9s2H_99f {
    background-size: 100% 450px;
  }
}

._AHpyW2U_kD2k9s2H_99f:before {
  content: '';
  width: 100%;
  height: calc(100% + 120px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 1000px) {
  ._AHpyW2U_kD2k9s2H_99f:before {
    height: calc(100% + 180px);
  }
}

.Z0D63KfVT-wizpAk90PtR {
  position: relative;
  z-index: 2;
}

._1WM2dmvZchsWXXPx6uk4xB {
  padding-top: 20px;
}

@media screen and (min-width: 1500px) {
  ._1WM2dmvZchsWXXPx6uk4xB {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 700px) {
  ._3pfP-sGuvALss2fUKIgHrZ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

._3pfP-sGuvALss2fUKIgHrZ:after {
  content: '';
}

._3pfP-sGuvALss2fUKIgHrZ:after,
._1UFhkijF6w_jT5lPse2vr5 {
  margin-top: 30px;
}

@media screen and (min-width: 700px) {
  ._3pfP-sGuvALss2fUKIgHrZ:after,
  ._1UFhkijF6w_jT5lPse2vr5 {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 1000px) {
  ._3pfP-sGuvALss2fUKIgHrZ:after,
  ._1UFhkijF6w_jT5lPse2vr5 {
    width: calc(50% - 30px);
    margin-top: 60px;
  }
}

@media screen and (min-width: 1200px) {
  ._3pfP-sGuvALss2fUKIgHrZ:after,
  ._1UFhkijF6w_jT5lPse2vr5 {
    width: calc(33.33% - 40px);
  }
}

._1QrcNsaHVu00r4yEOV4lC6 {
  margin: 0 auto 50px auto;
}

@media screen and (max-width: 699px) {
  ._1QrcNsaHVu00r4yEOV4lC6 {
    margin-bottom: 30px;
  }
}

.YyOx9ClBWivIgVD_oqFlp {
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
}

._1TMTKfOCxtTz_XO5TEJ9mp {
  list-style: none;
}

._2hH8ET19ILpjyVXuflQwF7 {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  position: relative;
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  padding: 10px 15px;
  margin: 0 10px 20px 0;
  background: #efefef;
  color: #626262;
  cursor: pointer;
}

@media screen and (max-width: 399px) {
  ._2hH8ET19ILpjyVXuflQwF7 {
    font-size: 18px;
  }
}

._2hH8ET19ILpjyVXuflQwF7:hover {
  background: transparent linear-gradient(67deg, #47dba4 0%, #46d1be 100%) 0% 0% no-repeat padding-box;
  color: #fff !important;
}

._3BBkfU5rpHfFoDWyXekAp4 {
  background: transparent linear-gradient(67deg, #47dba4 0%, #46d1be 100%) 0% 0% no-repeat padding-box;
  color: #fff !important;
}

._1h7LClnbEZfnPULTsZgdI3 {
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 10px;
  font-size: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.mr6dV8egVPQL049uqu0GJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 699px) {
  .mr6dV8egVPQL049uqu0GJ {
    display: none;
  }
}

._6gnj0SV_su8XEctFS0KkO {
  display: none;
}

@media screen and (max-width: 699px) {
  ._6gnj0SV_su8XEctFS0KkO {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

._1EqqLg94RXcfcEP31x3wCb {
  width: 15px;
  height: 15px;
  margin: -2px 10px 0 5px;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  fill: #2fd0b7;
  stroke: #2fd0b7;
}

._2CDfp2yGlB7XPzTZOVcZPj {
  text-align: center;
  padding: 10rem 0;
  color: #2fd0b7;
  font-size: 20px;
}
@media screen and (min-width: 1000px) {
  ._3Y7DGnvfnLSrtD7p7Of63l,
  ._3LEtUZfGkmBrusuUizpZ2C {
    max-width: 60vw;
  }
}

@media screen and (min-width: 1500px) {
  ._3Y7DGnvfnLSrtD7p7Of63l,
  ._3LEtUZfGkmBrusuUizpZ2C {
    max-width: none;
  }
}

._1ZtVbUa6L6Qyblj42saHmb {
  width: 100%;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  line-height: 1.1em;
  color: #adadad;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  display: block;
}

@media screen and (max-width: 1199px) {
  ._1ZtVbUa6L6Qyblj42saHmb {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 799px) {
  ._1ZtVbUa6L6Qyblj42saHmb {
    margin-bottom: 1.2rem;
  }
}

.u1hwevzuLi5007PjkmS8R {
  margin-top: 1.5em;
  opacity: 0.6;
}

._3E9uRUWop66bHF_B-3R8bE .u1hwevzuLi5007PjkmS8R {
  opacity: 1;
}
.x6Tg-heZsu8YLF-d5wB4K {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 800px) {
  .x6Tg-heZsu8YLF-d5wB4K {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}

.qTWh5vw6yGD-G6Sl1FSCL {
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  .qTWh5vw6yGD-G6Sl1FSCL {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1200px) {
  .qTWh5vw6yGD-G6Sl1FSCL {
    padding-bottom: 180px;
    margin-top: -120px;
  }
}

.Ur5lPJbaOtBCFyI7H2auJ {
  display: block;
  color: #2fd0b7;
  font-size: 1.8rem;
  text-align: left;
  -webkit-transform: translateY(-12px);
  transform: translateY(-12px);
}

@media screen and (min-width: 800px) {
  .Ur5lPJbaOtBCFyI7H2auJ {
    font-size: 2.4rem;
  }
}

._1_sFNkNZClAacUA85axF4P {
  width: 32px;
  height: 15px;
  margin-left: 5px;
  fill: transparent;
  stroke: #2fd0b7;
  stroke-width: 4px;
  -webkit-transform: rotate(90deg) translateX(-7px);
  transform: rotate(90deg) translateX(-7px);
  transition: -webkit-transform ease 0.25s;
  transition: transform ease 0.25s;
  transition: transform ease 0.25s, -webkit-transform ease 0.25s;
}

.Ur5lPJbaOtBCFyI7H2auJ:hover ._1_sFNkNZClAacUA85axF4P {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

._9qAl3uP0Ub9flxPKzKkkP {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  ._9qAl3uP0Ub9flxPKzKkkP {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1000px) {
  ._9qAl3uP0Ub9flxPKzKkkP {
    -webkit-align-items: center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  ._9qAl3uP0Ub9flxPKzKkkP {
    margin-top: 120px;
  }
}

@media screen and (min-width: 800px) {
  ._33WjEcrUC4yJjHIFZVWzeh {
    width: calc(50% - 30px);
  }
}

@media screen and (min-width: 1200px) {
  ._33WjEcrUC4yJjHIFZVWzeh {
    width: calc(55% - 60px);
  }
}

._2XUHxqF0JxOA4vdyIjVyF7 {
  margin-top: 60px;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  ._2XUHxqF0JxOA4vdyIjVyF7 {
    width: calc(50% - 30px);
    margin-top: 0;
  }
}

@media screen and (min-width: 1200px) {
  ._2XUHxqF0JxOA4vdyIjVyF7 {
    width: calc(45% - 30px);
    max-width: 450px;
  }
}

._2XUHxqF0JxOA4vdyIjVyF7 em {
  opacity: 0.5;
}
._1-Any-woQ9oenJ4AIB6M76 {
  top: -7px;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 15;
  position: -webkit-sticky;
  position: sticky;
}

@media screen and (min-width: 1000px) {
  ._1-Any-woQ9oenJ4AIB6M76 {
    padding-top: 120px;
    top: -55px;
  }
}

._29aJ6zFmG8nc89_qJRseKY._263qaGeaF7yEznKxB-2_3a {
  max-width: 1400px;
}

._29aJ6zFmG8nc89_qJRseKY:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -58px;
  left: 0;
  background-color: #fff;
}

._2d8yBiY22P050douAXLGJM {
  max-width: 450px;
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.wp-bdcvQoBGJ79U9ZgEj7 {
  width: 33.33%;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  color: #141315;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  opacity: 0.6;
}

.SydkqtghNvP9WAN5gdbBl.wp-bdcvQoBGJ79U9ZgEj7 {
  opacity: 1;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

.device-mobile .wp-bdcvQoBGJ79U9ZgEj7 {
  position: relative;
  width: auto;
}

.device-mobile .wp-bdcvQoBGJ79U9ZgEj7:not(:last-child) {
  padding-right: 60px;
}

.device-mobile .wp-bdcvQoBGJ79U9ZgEj7.SydkqtghNvP9WAN5gdbBl:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 28px;
  height: 2px;
  background-color: #2fd0b7;
}

._3jPeG112fm-5hlLLPVFqqw {
  width: 33.33333%;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.device-mobile ._3jPeG112fm-5hlLLPVFqqw {
  display: none;
}

._3jPeG112fm-5hlLLPVFqqw:before {
  content: '';
  width: 28px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2fd0b7;
}

.SydkqtghNvP9WAN5gdbBl:nth-child(1) ~ ._3jPeG112fm-5hlLLPVFqqw,
.wp-bdcvQoBGJ79U9ZgEj7:nth-child(1):hover ~ ._3jPeG112fm-5hlLLPVFqqw {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.SydkqtghNvP9WAN5gdbBl:nth-child(2) ~ ._3jPeG112fm-5hlLLPVFqqw,
.wp-bdcvQoBGJ79U9ZgEj7:nth-child(2):hover ~ ._3jPeG112fm-5hlLLPVFqqw {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.SydkqtghNvP9WAN5gdbBl:nth-child(3) ~ ._3jPeG112fm-5hlLLPVFqqw,
.wp-bdcvQoBGJ79U9ZgEj7:nth-child(3):hover ~ ._3jPeG112fm-5hlLLPVFqqw {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}
._2E44hy4JRETmoG7Ssnk-gu {
  list-style: none;
}

@media screen and (min-width: 800px) {
  ._2E44hy4JRETmoG7Ssnk-gu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.LNV40kHfdpcv5dl2TYJbu {
  margin-top: 7px;
  margin-bottom: 7px;
}

@media screen and (min-width: 800px) {
  .LNV40kHfdpcv5dl2TYJbu {
    width: 50%;
  }
}

@media screen and (min-width: 1500px) {
  .LNV40kHfdpcv5dl2TYJbu {
    width: 33.33%;
  }
}

._3lTj-A4ysVYwhVxu2H5-hT .LNV40kHfdpcv5dl2TYJbu {
  width: 100%;
}

.LNV40kHfdpcv5dl2TYJbu::before {
  content: '\2022';
  width: 1em;
  margin-left: -1em;
  display: inline-block;
  color: #2fd0b7;
}
._21hA0RZEGLLDl0uU3srMxt {
  padding-top: 45px;
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._21hA0RZEGLLDl0uU3srMxt {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

._3d-QhkcxgljAjKCf0dFwnQ {
  padding-bottom: 0;
}

@media screen and (min-width: 800px) {
  ._192p9tzcddGa6X5y_Y6QFX {
    display: -webkit-flex;
    display: flex;
  }
}

.ZGQFNJiF2Fh8z-0uCv8Lt {
  padding-bottom: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  gap: 5rem;
}

@media screen and (min-width: 800px) {
  .ZGQFNJiF2Fh8z-0uCv8Lt {
    width: 38%;
    padding-bottom: 0;
  }
}

._25mvr2nIj8iP_-f4pPyUCp {
  padding-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._25mvr2nIj8iP_-f4pPyUCp {
    padding-bottom: 90px;
    text-align: right;
  }
}

@media screen and (min-width: 800px) {
  ._5T9sbp_B58PVezcn8N6oi {
    width: 40%;
    margin-left: 8%;
  }
}

.MmASSRBOZ-ORfDc54zQG3 {
  margin-bottom: 25px;
}

@media screen and (min-width: 800px) {
  .MmASSRBOZ-ORfDc54zQG3 {
    margin-bottom: 60px;
  }
}

._3Bl3VX8N0HO1yI2llzVGys {
  margin-top: 25px;
  margin-bottom: 30px;
}
._1zgn-WlW6O6pV93CId6D7s {
  width: 100%;
  position: relative;
  padding: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  background-color: #f5f5f5;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  ._1zgn-WlW6O6pV93CId6D7s {
    padding: 50px;
  }
}

._1zgn-WlW6O6pV93CId6D7s:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(180deg, #47dba4, #46d1be);
  background-size: cover;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  transition: -webkit-transform ease-out 0.25s;
  transition: transform ease-out 0.25s;
  transition: transform ease-out 0.25s, -webkit-transform ease-out 0.25s;
}

.device-desktop ._1zgn-WlW6O6pV93CId6D7s:hover:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.interacted._1zgn-WlW6O6pV93CId6D7s:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

._1b5nEdc0GQSFjk3Kf7NKsa,
._1UUesiDg3-TBpIuhayk89S,
._2qy1Ti5c_ys4Rhrz5t7rsK {
  position: relative;
  z-index: 3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color ease-out 0.25s 0.1s;
}

._1b5nEdc0GQSFjk3Kf7NKsa {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  ._1b5nEdc0GQSFjk3Kf7NKsa {
    min-height: 3.3em;
    margin-bottom: 0;
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1500px) {
  ._1b5nEdc0GQSFjk3Kf7NKsa {
    font-size: 3.2rem;
  }
}

._1UUesiDg3-TBpIuhayk89S {
  display: block;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

._2qy1Ti5c_ys4Rhrz5t7rsK {
  margin-top: 20px;
}

@media screen and (min-width: 800px) {
  ._2qy1Ti5c_ys4Rhrz5t7rsK {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1500px) {
  ._2qy1Ti5c_ys4Rhrz5t7rsK {
    margin-top: 60px;
  }
}
.Hekqw6_uGFpTKUX2zfagR {
  padding-bottom: 0;
}

@media screen and (min-width: 800px) {
  .Hekqw6_uGFpTKUX2zfagR {
    padding-top: 120px;
  }
}

@media screen and (max-width: 799px) {
  ._2gocMOJXwPSPxwh0_mfQ4f {
    padding-top: 0;
    padding-bottom: 0;
  }
}

._2Czlh9mFyNuruYSeQQypNZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media screen and (min-width: 800px) {
  ._2Czlh9mFyNuruYSeQQypNZ {
    padding-bottom: 60px;
  }
}

._2Czlh9mFyNuruYSeQQypNZ:not(:first-child) {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  ._2Czlh9mFyNuruYSeQQypNZ:not(:first-child) {
    margin-top: 120px;
  }
}

._2Czlh9mFyNuruYSeQQypNZ:after {
  content: '';
}

.N1L92sToGS-rm9rBsIprt,
._2Czlh9mFyNuruYSeQQypNZ:after {
  width: 100%;
  margin-top: 30px;
}

@media screen and (min-width: 800px) {
  .N1L92sToGS-rm9rBsIprt,
  ._2Czlh9mFyNuruYSeQQypNZ:after {
    -webkit-flex-basis: calc(50% - 15px);
    flex-basis: calc(50% - 15px);
  }
}

@media screen and (min-width: 1200px) {
  .N1L92sToGS-rm9rBsIprt,
  ._2Czlh9mFyNuruYSeQQypNZ:after {
    margin-top: 45px;
    -webkit-flex-basis: calc(33.33% - 30px);
    flex-basis: calc(33.33% - 30px);
  }
}

@media screen and (min-width: 1500px) {
  .N1L92sToGS-rm9rBsIprt,
  ._2Czlh9mFyNuruYSeQQypNZ:after {
    margin-top: 60px;
    -webkit-flex-basis: calc(33.33% - 40px);
    flex-basis: calc(33.33% - 40px);
  }
}

@media screen and (min-width: 800px) {
  .device-IE11 .N1L92sToGS-rm9rBsIprt,
  .device-IE11 ._2Czlh9mFyNuruYSeQQypNZ:after {
    max-width: calc(50% - 15px);
  }
}

@media screen and (min-width: 1200px) {
  .device-IE11 .N1L92sToGS-rm9rBsIprt,
  .device-IE11 ._2Czlh9mFyNuruYSeQQypNZ:after {
    max-width: calc(33.33% - 40px);
  }
}

._1pGT55HqmYM40WdMlzMlk8 {
  margin-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._1pGT55HqmYM40WdMlzMlk8 {
    margin-bottom: 60px;
  }
}
._2WEveeqfeZbQeLdOGgbRHO {
  display: block;
}

.VP7hFvPEb72u5AfFEEySD {
  height: 28px;
  position: relative;
  padding: 0 40px;
  display: block;
  border: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999999px;
  overflow: hidden;
}

.VP7hFvPEb72u5AfFEEySD+.VP7hFvPEb72u5AfFEEySD {
  margin-top: 15px;
}

.VP7hFvPEb72u5AfFEEySD::after,
.VP7hFvPEb72u5AfFEEySD::before {
  content: '';
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
}

.VP7hFvPEb72u5AfFEEySD::after {
  z-index: 2;
  opacity: 0;
  background-color: #2fd0b7;
  transition: opacity 0.75s ease;
}

.w1wDqRBMzMTwb5pt3szUY .VP7hFvPEb72u5AfFEEySD::after {
  background-color: #fff;
}

.VP7hFvPEb72u5AfFEEySD::before {
  z-index: 1;
  background-color: #edeced;
}

.w1wDqRBMzMTwb5pt3szUY .VP7hFvPEb72u5AfFEEySD::before {
  background-color: rgba(255,255,255,0.3);
}

.VP7hFvPEb72u5AfFEEySD._2e27FMTuFi-XzvA9dRJA6M::after {
  opacity: 1;
  -webkit-animation: _2e27FMTuFi-XzvA9dRJA6M 4000ms ease-in-out;
  animation: _2e27FMTuFi-XzvA9dRJA6M 4000ms ease-in-out;
  transition: none;
}

@-webkit-keyframes _2e27FMTuFi-XzvA9dRJA6M {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes _2e27FMTuFi-XzvA9dRJA6M {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
._3SwmSQSCD1F1LRXsA3CSU9 {
  position: relative;
  margin-top: 60px;
  margin-bottom: -60px;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  ._3SwmSQSCD1F1LRXsA3CSU9 {
    margin-top: 180px;
    margin-bottom: 60px;
  }
}

.KB0cKRme6AcZcbLaf6WU4 {
  position: relative;
  padding: 60px 20px;
  overflow: hidden;
  z-index: 3;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: 'unit';
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  .KB0cKRme6AcZcbLaf6WU4 {
    max-width: calc(1400px + 9%);
    padding: 180px 120px;
    right: -4.5%;
  }
}

.device-IE11 .KB0cKRme6AcZcbLaf6WU4 {
  display: block;
}

.HX89oV-Owi_xg7hzvVbg5 {
  display: -webkit-flex;
  display: flex;
  grid-area: unit;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms;
}

.HX89oV-Owi_xg7hzvVbg5._1LIvdmUBQ9uheeXVETjRBO {
  pointer-events: auto;
  opacity: 1;
}

.device-IE11 .HX89oV-Owi_xg7hzvVbg5 {
  display: none;
}

.device-IE11 .HX89oV-Owi_xg7hzvVbg5._1LIvdmUBQ9uheeXVETjRBO {
  display: block;
}

._1hT4ervvWDUwFpCBXLmJdA {
  margin-right: auto;
  margin-left: auto;
  font-size: 3.4rem;
}

.tie4g30zlxeBb2JZ5vnJE {
  margin-bottom: 15px !important;
  height: 12px;
  display: block;
}

._3gXJJXqYPGv_fI6vXbivRu {
  position: absolute;
  bottom: 0;
  right: 20%;
  z-index: 4;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media screen and (min-width: 800px) {
  ._3gXJJXqYPGv_fI6vXbivRu {
    bottom: 120px;
  }
}

._3gXJJXqYPGv_fI6vXbivRu:after {
  content: '';
  width: 2px;
  height: 50vw;
  position: absolute;
  bottom: calc(100% + 10px);
  right: 49%;
  z-index: 1;
  background-color: #edeced;
}

@media screen and (min-width: 800px) {
  ._3gXJJXqYPGv_fI6vXbivRu:after {
    height: 43vw;
  }
}
._2GEg-LGHHWo0c8qTwFH-ct {
  position: relative;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  overflow: hidden;
  margin-top: 30px;
  flex-basis: 100% !important;
}

@media screen and (min-width: 800px) {
  ._2GEg-LGHHWo0c8qTwFH-ct {
    -webkit-flex-basis: calc(50% - 60px / 4);
    flex-basis: calc(50% - 60px / 4);
  }
}

@media screen and (min-width: 1200px) {
  ._2GEg-LGHHWo0c8qTwFH-ct {
    -webkit-flex-basis: calc(50% - 60px / 2);
    flex-basis: calc(50% - 60px / 2);
    margin-top: 60px;
  }
}

:root.keyboard-focus ._2GEg-LGHHWo0c8qTwFH-ct:focus-within {
  outline: #16a58e 5px auto;
}

._2GEg-LGHHWo0c8qTwFH-ct:nth-child(2):nth-last-child(2) {
  -webkit-order: -1;
  order: -1;
}

.device-Firefox ._2M9D63-ZtbCXScT1qCkaB5 {
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

._2GEg-LGHHWo0c8qTwFH-ct:before {
  content: '';
  width: 100%;
  padding-top: 135%;
  display: block;
}

._20043A9Qs5hsjdhGdkkQmM {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._1CdpbjczUH6prH0K4369uQ {
  width: 70px;
  position: absolute;
  top: 3.5rem;
  left: 4.5rem;
  z-index: 1;
  opacity: 0;
  transition: -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
  transition: transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s, -webkit-transform cubic-bezier(0.4, 0.01, 0.5, 1) 0.25s;
}

@media screen and (min-width: 800px) {
  ._1CdpbjczUH6prH0K4369uQ {
    width: 55px;
    left: 3.5rem;
  }
}

@media screen and (min-width: 1200px) {
  ._1CdpbjczUH6prH0K4369uQ {
    width: 65px;
    top: 5rem;
    left: 4rem;
  }
}
._18yM0qiXBii4CoVA-Z5sXL:not(:first-child) {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  ._18yM0qiXBii4CoVA-Z5sXL {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
  }

  ._18yM0qiXBii4CoVA-Z5sXL:first-child {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }

  ._18yM0qiXBii4CoVA-Z5sXL:last-child {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 800px) {
  ._3rjVd4dnu3GiCpk42YeZyR {
    width: calc(50% - 30px);
  }
}

@media screen and (min-width: 1200px) {
  ._3rjVd4dnu3GiCpk42YeZyR {
    width: calc(50% - 60px);
  }
}

@media screen and (min-width: 1200px) {
  ._3rjVd4dnu3GiCpk42YeZyR {
    width: calc(50% - 90px);
  }
}

._1dZZj6mbvLJPdrBqgVcaiJ {
  padding-top: 20px;
  padding-bottom: 30px;
  -webkit-order: 1;
  order: 1;
}

@media screen and (min-width: 800px) {
  ._1dZZj6mbvLJPdrBqgVcaiJ {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

._18yM0qiXBii4CoVA-Z5sXL:nth-child(even) ._1dZZj6mbvLJPdrBqgVcaiJ {
  -webkit-order: 2;
  order: 2;
}

.TG7jSlS2LBf2_bkknE_7C {
  margin-top: 3px;
}

.oFeldKUoCZvN4AHC95Th2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-style: unset;
}

._3w6GUUE3LyqOZ6STrefuv_ {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  margin-right: 15px;
  background-color: #fff;
  border-radius: 50%;
}

._2ltbM97gFfalEpui344kf8 {
  margin-top: 30px;
  margin-left: 25px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._86JA3WoLSQIarL4OwSOdk {
  position: relative;
  margin-top: 30px;
  padding-left: 25px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-style: italic;
}

@media screen and (min-width: 800px) {
  ._86JA3WoLSQIarL4OwSOdk {
    padding-left: 65px;
  }
}

._86JA3WoLSQIarL4OwSOdk:before {
  content: '';
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2fd0b7;
}

@media screen and (min-width: 800px) {
  ._86JA3WoLSQIarL4OwSOdk:before {
    left: 22px;
  }
}

.-y_ancbiQG6i5NzlR6FHC {
  -webkit-order: 2;
  order: 2;
}

._18yM0qiXBii4CoVA-Z5sXL:nth-child(even) .-y_ancbiQG6i5NzlR6FHC {
  -webkit-order: 1;
  order: 1;
}

.-y_ancbiQG6i5NzlR6FHC ._2nRwUFXDNSkTJ2pfi0-gSI {
  width: 100%;
  display: block;
  margin-top: 0;
}
._6ihNQOYx2AzQAsDizHZqX {
  position: relative;
  margin-bottom: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  background-image: linear-gradient(#fff, #fff),linear-gradient(#fff, #fff);
  background-size: 100% 0px, 100% 0px;
  background-repeat: no-repeat;
  background-position: center top, center bottom;
}

@media screen and (min-width: 800px) {
  ._6ihNQOYx2AzQAsDizHZqX {
    padding-top: 60px;
    padding-bottom: 60px;
    background-size: 100% 120px,100% 120px;
  }
}

@media screen and (min-width: 1000px) {
  ._6ihNQOYx2AzQAsDizHZqX {
    background-size: 100% 180px,100% 120px;
  }
}

@media screen and (min-width: 800px) {
  .lWU6QPXgrRvnwVYRD5Ssz {
    width: calc(50% - 30px);
  }
}

@media screen and (min-width: 1000px) {
  .lWU6QPXgrRvnwVYRD5Ssz {
    width: calc(50% - 60px);
  }
}

@media screen and (min-width: 1200px) {
  .lWU6QPXgrRvnwVYRD5Ssz {
    width: calc(50% - 180px);
  }
}

@media screen and (min-width: 800px) {
  ._3LfmN4ionr7JQutrqUW7zO {
    margin-top: -3rem;
  }
}
._2kYZmSRvHD0aflJdB4A1Os {
  width: 100%;
}

@media screen and (min-width: 1200px) {
  ._2kYZmSRvHD0aflJdB4A1Os {
    display: -webkit-flex;
    display: flex;
  }
}

._1gzDYYb2fEJo3RevZJ_tTS {
  position: relative;
}

@media screen and (min-width: 1200px) {
  ._1gzDYYb2fEJo3RevZJ_tTS {
    width: calc(50% - 60px);
  }
}

._1gzDYYb2fEJo3RevZJ_tTS:nth-child(2) {
  margin-left: auto;
}

@media screen and (max-width: 1199px) {
  ._1gzDYYb2fEJo3RevZJ_tTS:nth-child(2) {
    margin-top: 30px;
    margin-left: 0;
  }
}

@media screen and (min-width: 1200px) {
  ._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS {
    padding: 20px 0;
  }
}

@media screen and (min-width: 1200px) {
  ._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS:first-child {
    width: 50%;
    border-right: 1px solid rgba(28,27,32,0.1);
  }
}

._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS:nth-child(2) {
  max-width: 400px;
}

@media screen and (max-width: 1199px) {
  ._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS:nth-child(2) {
    max-width: unset;
    margin-left: 0;
    margin-top: 50px;
    padding-top: 40px;
    border: 0;
    border-top: 1px solid rgba(28,27,32,0.1);
  }
}

._3xc55eGe9DIHSMXpNhYIvh {
  text-decoration: none;
}

._1NVueR-UElYs-hbh3QDttB {
  display: block;
}

._1NVueR-UElYs-hbh3QDttB:not(:first-child) {
  margin-top: 30px;
}

@media screen and (min-width: 1200px) {
  ._1mVqIxkuZ225VE2tbXVks9 ._1NVueR-UElYs-hbh3QDttB {
    min-height: 6.5em;
  }
}

.t3v02kPG2onDe0Ppi7V_B {
  color: rgba(20,19,21,0.7);
}

._2JMz6mSy6O40QvOrlh9FS3 {
  margin-top: 20px;
}

@media screen and (min-width: 1200px) {
  ._2JMz6mSy6O40QvOrlh9FS3 {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1200px) {
  ._1gzDYYb2fEJo3RevZJ_tTS ._1VoyWWPmOFzg95eSx-EGji,
  ._1gzDYYb2fEJo3RevZJ_tTS ._1NVueR-UElYs-hbh3QDttB,
  ._1gzDYYb2fEJo3RevZJ_tTS ._2JMz6mSy6O40QvOrlh9FS3,
  ._14RpBDy-FeCrVGwsKfrk9D:after {
    max-width: 335px;
  }
}

@media screen and (min-width: 1200px) {
  ._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS ._1VoyWWPmOFzg95eSx-EGji,
  ._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS ._1NVueR-UElYs-hbh3QDttB,
  ._1mVqIxkuZ225VE2tbXVks9 ._1gzDYYb2fEJo3RevZJ_tTS ._2JMz6mSy6O40QvOrlh9FS3,
  ._1mVqIxkuZ225VE2tbXVks9 ._14RpBDy-FeCrVGwsKfrk9D:after {
    max-width: 400px;
  }
}
.okDvNzyDua2w0i2MO00Ha {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 1;
}

@media screen and (min-width: 800px) {
  .okDvNzyDua2w0i2MO00Ha {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1200px) {
  .udMbLLmD0rLsMxzeE2pw7 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

.okDvNzyDua2w0i2MO00Ha ._3ThtInVchDiMFMUF98LTIx {
  position: relative;
  z-index: 2;
}

._3QcHWCL1UJPhE4gobVcW61:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -1;
  background-color: #f5f5f5;
}
._3rUpHBmfitClhGPjr2o0b2 {
  max-width: 500px;
  background-color: #f5f5f5;
}

._3Yrae1rsngC7d7GGr-dTkD {
  padding: 45px;
}

._1JmjTEPXDd4fUfc68rURzL {
  font-size: 1.1rem;
}

._28n9C4hviIsggdogQbDMFl {
  margin-top: 20px;
  margin-bottom: 25px;
}
._3c0NfqYZpXJ2nINil1YV9s {
  position: relative;
  width: 100%;
  display: grid;
}

._1NIY4EWGg0dBIb0JnScymP {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

@media screen and (min-width: 550px) {
  ._1NIY4EWGg0dBIb0JnScymP {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 800px) {
  ._1NIY4EWGg0dBIb0JnScymP {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

._2PGP2ukTo3idFHL2X-u0KA {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (min-width: 400px) {
  ._2PGP2ukTo3idFHL2X-u0KA {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 550px) {
  ._2PGP2ukTo3idFHL2X-u0KA {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 800px) {
  ._2PGP2ukTo3idFHL2X-u0KA {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1000px) {
  ._2PGP2ukTo3idFHL2X-u0KA {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

._2BujujJP4bdfENH6Shq_SW {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
}

._2BujujJP4bdfENH6Shq_SW:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 100%;
}

._2Wj8dlYJR69J7YBnVd9em8 {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  z-index: 4;
}

._34opOnXTgpXCcDHUQtHvvv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.15s linear;
}

._3Y2wuJrlRwjF5iRGBn38Rw {
  opacity: 0;
}

.device-desktop ._2BujujJP4bdfENH6Shq_SW:hover ._2P93S2RsTZDASqni6tCTYU {
  opacity: 0;
}

.device-desktop ._2BujujJP4bdfENH6Shq_SW:hover ._3Y2wuJrlRwjF5iRGBn38Rw {
  opacity: 1;
}
@media screen and (min-width: 800px) {
  .ygZ4FSDCMT9kGGCH2LdB9 {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1000px) {
  ._1pAw1xq3a_ESC_P5nrBNdI {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1000px) {
  .SDpiI4T5rirGLhqG7RcER {
    width: 58%;
  }
}

._37Wfl-lVG51iRQEMzis1Rz {
  display: none;
}

@media screen and (min-width: 1000px) {
  ._37Wfl-lVG51iRQEMzis1Rz {
    display: initial;
    margin-left: 12%;
    width: 38%;
  }
}

@media screen and (min-width: 1200px) {
  ._37Wfl-lVG51iRQEMzis1Rz {
    width: 30%;
  }
}

._3tnB9jHQNtQeXQiBRsIt9- {
  padding-bottom: 60px;
  border-bottom: 2px solid #f5f5f5;
}

._3tnB9jHQNtQeXQiBRsIt9-:last-child {
  border-bottom: 0;
}

._3tnB9jHQNtQeXQiBRsIt9-:not(:first-child) {
  margin-top: 60px;
}

._25WzZQ6Nsu0DUlbKqLT_19 {
  opacity: 0.6;
}

._25WzZQ6Nsu0DUlbKqLT_19:not(:first-child) {
  margin-top: 30px;
}

._2Pi5tAQUZXzae5u1-OiVC {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  ._2Pi5tAQUZXzae5u1-OiVC {
    margin-top: 90px;
  }
}

._28Y99AiwTzkZ8Wjabtf56C {
  margin-top: 30px;
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
}

._1t_ncaRA9Tc5OGs8LghYPh {
  position: relative;
  margin-right: 1.5%;
  margin-bottom: 30px;
  margin-left: 1.5%;
  -webkit-flex-basis: 17%;
  flex-basis: 17%;
  list-style-type: none;
}

._1t_ncaRA9Tc5OGs8LghYPh:before {
  content: '';
  display: block;
  padding-top: 90%;
}

.uAC-kD-wRet9Zp4ScadLx {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.uAC-kD-wRet9Zp4ScadLx img {
  object-position: center;
}
._2H7I997tfRIozQtTxlXTn_ {
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._2H7I997tfRIozQtTxlXTn_ {
    padding-bottom: 120px;
  }
}

.GHXOtmKtwMRqL9OC5Q9zd {
  position: relative;
}

@media screen and (min-width: 800px) {
  .GHXOtmKtwMRqL9OC5Q9zd {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
}

._3CdqyNqGHXLu63zr8Omvhq {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 4;
}

@media screen and (min-width: 1500px) {
  ._3CdqyNqGHXLu63zr8Omvhq {
    right: calc(100% + 15px);
    left: auto;
  }
}

@media screen and (min-width: 1700px) {
  ._3CdqyNqGHXLu63zr8Omvhq {
    right: calc(100% + 120px);
  }
}

._2VsQEYbuNLbmewra_vz0Ik {
  position: relative;
  padding-top: 120px;
  padding-bottom: 30px;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  ._2VsQEYbuNLbmewra_vz0Ik {
    width: 140%;
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

.hShPHllNImLWt0XGGIjXP {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 800px) {
  .hShPHllNImLWt0XGGIjXP {
    width: 60%;
  }
}

._2h2mUzpCxAArOnxI2uZDac {
  position: relative;
  z-index: 1;
}

._2h2mUzpCxAArOnxI2uZDac:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: linear-gradient(to right, rgba(70,209,190,0.9), rgba(71,219,164,0.15));
  background-size: cover;
  opacity: 0.8;
}

._1JKydAoOV-pWGC8o6hhztc {
  position: relative;
  z-index: 1;
}

._1Tv7V3iYuntMoiawsvySzw {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  -webkit-transform-origin: right;
  transform-origin: right;
  background-image: linear-gradient(to top, #47dba4, #46d1be);
  background-size: cover;
}
.j77es5QjYxholuSWMny6i {
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 35px;
  list-style-type: none;
  border-bottom: 1px solid rgba(20,19,21,0.1);
  cursor: pointer;
  transition: border ease 0.25s;
}

@media screen and (min-width: 550px) {
  .j77es5QjYxholuSWMny6i {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 1000px) {
  .j77es5QjYxholuSWMny6i {
    width: calc(33.33% - 30px);
    margin-right: 15px;
    margin-left: 15px;
  }
}

.j77es5QjYxholuSWMny6i ._3PG_dEeoY7P3nXaFW7c3pF {
  display: block;
  padding-right: 30px;
  color: #141315;
  font-size: 2rem;
  transition: color ease 0.25s;
}

.j77es5QjYxholuSWMny6i ._3BYuNZF7B9xHkGagcLiCw3 {
  width: 35px;
  position: absolute;
  top: calc(50% - 14px);
  top: 8px;
  right: 10px;
  fill: transparent;
  stroke: rgba(20,19,21,0.2);
  stroke-width: 4px;
  transition: stroke ease 0.25s;
}

.j77es5QjYxholuSWMny6i:hover {
  border-bottom-color: rgba(47,208,183,0.5);
}

.j77es5QjYxholuSWMny6i:hover ._3PG_dEeoY7P3nXaFW7c3pF {
  color: #2fd0b7;
}

.j77es5QjYxholuSWMny6i:hover ._3BYuNZF7B9xHkGagcLiCw3 {
  stroke: #2fd0b7;
}
.OIUhMT8TznBgsfh1trp_v {
  padding-top: 60px;
  padding-bottom: 30px;
}

@media screen and (min-width: 1000px) {
  .OIUhMT8TznBgsfh1trp_v {
    margin-right: 15px;
    margin-left: 15px;
  }
}

._1yrhY5EgNcnGtZzz9Fh5yD {
  margin-top: 30px;
  padding-left: 0;
  list-style-type: none;
}

@media screen and (min-width: 550px) {
  ._1yrhY5EgNcnGtZzz9Fh5yD {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  ._1yrhY5EgNcnGtZzz9Fh5yD {
    -webkit-justify-content: unset;
    justify-content: unset;
  }
}
.pYbW68VMD5mGsx_sPxvip {
  padding-bottom: 60px;
}

._3b6uuu7lmVvaJD9v0K0ygN {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._3b6uuu7lmVvaJD9v0K0ygN {
    padding-bottom: 120px;
  }
}

._1g-jGN6zagtH1uaNBZ1y3V {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.pYbW68VMD5mGsx_sPxvip ._3ES3SU6xYH4MBSWfED2i5l {
  position: relative;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}

@media screen and (min-width: 800px) {
  .pYbW68VMD5mGsx_sPxvip ._3ES3SU6xYH4MBSWfED2i5l {
    -webkit-flex-basis: calc(50% - 15px);
    flex-basis: calc(50% - 15px);
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

@media screen and (min-width: 1200px) {
  .pYbW68VMD5mGsx_sPxvip ._3ES3SU6xYH4MBSWfED2i5l {
    -webkit-flex-basis: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
    margin-bottom: 60px;
  }
}
._16-fzSAWwZYfwFWUezCiUO {
  padding-top: 60px;
  padding-bottom: 15px;
}

.mdUOwSoPYHN3yB406rd4y {
  position: relative;
}

@media screen and (min-width: 800px) {
  .mdUOwSoPYHN3yB406rd4y {
    padding-bottom: 120px;
  }
}

.mdUOwSoPYHN3yB406rd4y:before {
  content: '';
  width: 100vw;
  height: 100%;
  position: absolute;
  right: -20px;
  top: -60px;
  z-index: 1;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  .mdUOwSoPYHN3yB406rd4y:before {
    height: 100%;
    top: -150px;
    right: 10%;
  }
}

._2hvtX08oO0fJPyz9ujoX59.mdUOwSoPYHN3yB406rd4y:before {
  height: calc(100% - 60px);
}

@media screen and (min-width: 800px) {
  ._2hvtX08oO0fJPyz9ujoX59.mdUOwSoPYHN3yB406rd4y:before {
    height: calc(100% - 120px);
  }
}

.WWkUICz2FMAw2_jx9l1q_ {
  position: relative;
  margin-top: 60px;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (min-width: 800px) {
  .WWkUICz2FMAw2_jx9l1q_ {
    margin-top: 90px;
  }
}

._1amh4oYISp0NmQ9NY03lrO {
  width: 100%;
  margin-top: 20px;
}

@media screen and (min-width: 550px) {
  ._1amh4oYISp0NmQ9NY03lrO {
    width: calc(33.33% - 20px);
  }
}

@media screen and (min-width: 1000px) {
  ._1amh4oYISp0NmQ9NY03lrO {
    width: calc(33.33% - 40px);
  }
}

._3zIqte_-J5Jdwdjxf7EdMT {
  position: relative;
  margin-top: 60px;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  ._3zIqte_-J5Jdwdjxf7EdMT {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1000px) {
  ._3zIqte_-J5Jdwdjxf7EdMT {
    margin-top: 180px;
    display: -webkit-flex;
    display: flex;
  }
}

@media screen and (min-width: 1000px) {
  ._1kFjcixcROYwgv3jy6mJvy {
    width: 20%;
    margin-top: -0.3em;
  }
}

._2C2tFXG_9T1rTFXT9aNiPU {
  margin-top: 30px;
  padding-left: 0;
  list-style-type: none;
}

@media screen and (min-width: 550px) {
  ._2C2tFXG_9T1rTFXT9aNiPU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (min-width: 800px) {
  ._2C2tFXG_9T1rTFXT9aNiPU {
    width: 80%;
  }
}

@media screen and (min-width: 1000px) {
  ._2C2tFXG_9T1rTFXT9aNiPU {
    width: 50%;
    margin-top: 0;
  }
}

._9M1MBNz3qf6ZFd0JwmsFA {
  display: block;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px;
  padding-right: 30px;
  list-style-type: none;
  font-size: 1.6rem;
  color: #141315;
  border-bottom: 1px solid rgba(20,19,21,0.1);
  transition: color ease 0.25s, border ease 0.25s;
}

@media screen and (min-width: 550px) {
  ._9M1MBNz3qf6ZFd0JwmsFA {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 800px) {
  ._9M1MBNz3qf6ZFd0JwmsFA {
    width: calc(50% - 30px);
    margin-right: 30px;
  }
}

._9M1MBNz3qf6ZFd0JwmsFA .ndsESBUkltwuBeqsXIEXg {
  width: 35px;
  position: absolute;
  top: 6px;
  right: 10px;
  fill: transparent;
  stroke: rgba(20,19,21,0.2);
  stroke-width: 4px;
  transition: stroke ease 0.25s;
}

._9M1MBNz3qf6ZFd0JwmsFA:hover {
  color: #2fd0b7;
  border-bottom-color: rgba(47,208,183,0.5);
}

._9M1MBNz3qf6ZFd0JwmsFA:hover .ndsESBUkltwuBeqsXIEXg {
  stroke: #2fd0b7;
}
._2DvuLYOVFFBKoRORBokew {
  position: relative;
}

@media screen and (min-width: 1300px) {
  ._2DvuLYOVFFBKoRORBokew {
    margin-left: 0;
    width: 100%;
  }

  ._2DvuLYOVFFBKoRORBokew._3vRkwixOTYMC62ioxLDJYu {
    margin-left: -15%;
    width: 130%;
  }
}

._2DvuLYOVFFBKoRORBokew:not(:first-child) {
  margin-top: 15px;
}

@media screen and (min-width: 400px) {
  ._2DvuLYOVFFBKoRORBokew {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
}

@media screen and (max-width: 399px) {
  .o3iHqduWntlI3Sm1QEbaF:not(:first-child) {
    margin-top: 15px;
  }
}

.o3iHqduWntlI3Sm1QEbaF>figure {
  background-image: none !important;
}

._3DxXs86xIbrt1fJk416uUO .o3iHqduWntlI3Sm1QEbaF {
  width: 100%;
}

@media screen and (min-width: 400px) {
  .uLxU_RdImu5sMdVx7w9Zl .o3iHqduWntlI3Sm1QEbaF {
    width: calc(50% - (60px * 0.25));
  }
}

@media screen and (min-width: 1000px) {
  .uLxU_RdImu5sMdVx7w9Zl .o3iHqduWntlI3Sm1QEbaF {
    width: calc(50% - (60px * 0.5));
  }
}

@media screen and (min-width: 400px) {
  ._3MFBzD0NInOpU0rb6FTPIa .o3iHqduWntlI3Sm1QEbaF {
    width: calc(50% - (60px * 0.25));
    -webkit-order: 1;
    order: 1;
  }
}

@media screen and (min-width: 1000px) {
  ._3MFBzD0NInOpU0rb6FTPIa .o3iHqduWntlI3Sm1QEbaF {
    width: calc(50% - (60px * 0.5));
  }
}

._3MFBzD0NInOpU0rb6FTPIa .o3iHqduWntlI3Sm1QEbaF:nth-child(3) {
  width: 100%;
  -webkit-order: 3;
  order: 3;
}

@media screen and (min-width: 400px) {
  ._3MFBzD0NInOpU0rb6FTPIa:before {
    content: '';
    display: block;
    height: 30px;
    width: 100%;
    -webkit-order: 2;
    order: 2;
  }
}

@media screen and (min-width: 1000px) {
  ._3MFBzD0NInOpU0rb6FTPIa:before {
    height: 60px;
  }
}

._1OvIIQKcJqUkAV42xKHsxk {
  padding-top: 10px;
  -webkit-order: 5;
  order: 5;
}

._1OvIIQKcJqUkAV42xKHsxk._1a2Uy6G-1RgIenf6IbUOX9,
._1OvIIQKcJqUkAV42xKHsxk._3bGc2rLZQtmW6xOVzkMG1o {
  background-color: #fff;
}

@media screen and (min-width: 400px) {
  ._1OvIIQKcJqUkAV42xKHsxk._1a2Uy6G-1RgIenf6IbUOX9,
  ._1OvIIQKcJqUkAV42xKHsxk._3bGc2rLZQtmW6xOVzkMG1o {
    width: calc(100% - 120px);
    position: absolute;
    bottom: 0;
  }
}

@media screen and (min-width: 1000px) {
  ._1OvIIQKcJqUkAV42xKHsxk._1a2Uy6G-1RgIenf6IbUOX9,
  ._1OvIIQKcJqUkAV42xKHsxk._3bGc2rLZQtmW6xOVzkMG1o {
    width: calc(100% - 180px);
    padding-top: 30px;
  }
}

._1OvIIQKcJqUkAV42xKHsxk._1a2Uy6G-1RgIenf6IbUOX9 {
  left: 0;
}

@media screen and (min-width: 400px) {
  ._1OvIIQKcJqUkAV42xKHsxk._1a2Uy6G-1RgIenf6IbUOX9 {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1000px) {
  ._1OvIIQKcJqUkAV42xKHsxk._1a2Uy6G-1RgIenf6IbUOX9 {
    padding-right: 30px;
  }
}

._1OvIIQKcJqUkAV42xKHsxk._3bGc2rLZQtmW6xOVzkMG1o {
  right: 0;
}

@media screen and (min-width: 400px) {
  ._1OvIIQKcJqUkAV42xKHsxk._3bGc2rLZQtmW6xOVzkMG1o {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1000px) {
  ._1OvIIQKcJqUkAV42xKHsxk._3bGc2rLZQtmW6xOVzkMG1o {
    padding-left: 30px;
  }
}

._2jk3zo2RIo0OF9JuYpoh0P:not(:first-child) {
  margin-top: 30px;
}
.WzXmTP5gE7dJjA-KV8qKW {
  background-color: #f5f5f5;
}

.EISBENTw3Pq8pilQvJFEs {
  padding: 55px 9%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-column-gap: 9%;
  -moz-column-gap: 9%;
  column-gap: 9%;
}

@media screen and (max-width: 799px) {
  .EISBENTw3Pq8pilQvJFEs {
    padding: 30px 9%;
    -webkit-flex-direction: column;
    flex-direction: column;
    row-gap: 25px;
  }
}

._2lEnShQ8F4aZLKyi-qh9bg {
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 799px) {
  ._2lEnShQ8F4aZLKyi-qh9bg {
    text-align: center;
  }
}

.Hy_lkF97dfzehSxyuxYrO {
  position: relative;
  padding-top: 0.5em;
  padding-right: 1.3em;
  padding-bottom: 0.5em;
  padding-left: 1.3em;
  justify-self: end;
  border-radius: 35px;
  vertical-align: middle;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.3;
  color: #fff;
  z-index: 1;
  background-image: linear-gradient(-90deg, #47dba4, #46d1be);
  background-size: cover;
}

@media screen and (min-width: 800px) {
  .Hy_lkF97dfzehSxyuxYrO {
    padding-top: 0.6em;
    padding-right: 1.5em;
    padding-bottom: 0.6em;
    padding-left: 1.5em;
  }
}

.Hy_lkF97dfzehSxyuxYrO:hover {
  background-image: linear-gradient(90deg, #47dba4, #46d1be);
  background-size: cover;
  transition: opacity 0.4s ease;
}
.TbJYUkuORm_UIq11uFawm {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

@media screen and (min-width: 1300px) {
  .TbJYUkuORm_UIq11uFawm {
    margin-left: -15%;
    width: 130%;
  }
}

.TbJYUkuORm_UIq11uFawm:not(:first-child) {
  margin-top: 15px;
}

._3HDXk1kOP_b0GYqWUeXXFB,
.TbJYUkuORm_UIq11uFawm iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._3HDXk1kOP_b0GYqWUeXXFB {
  background-image: linear-gradient(0deg, #47dba4, #46d1be);
  background-size: cover;
}

._3HDXk1kOP_b0GYqWUeXXFB:focus {
  outline: none;
}
._321TYqOOtv21yP_9nWV7TD {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 699px) {
  ._321TYqOOtv21yP_9nWV7TD {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.P1QCyC2AJhAjbQyIDJYPU {
  background: #f5f5f5;
}

@media screen and (max-width: 399px) {
  .P1QCyC2AJhAjbQyIDJYPU p {
    line-height: 1.4;
  }
}

._28kqvLm4_o3K_SokPmaD-L:not(:first-child) {
  margin-top: 36px;
}

@media screen and (min-width: 700px) {
  ._28kqvLm4_o3K_SokPmaD-L {
    padding-right: 20px;
    padding-left: 20px;
  }
}

._1yMINOrsbYgbFk0GwReCD8 {
  display: block;
  color: rgba(28,27,32,0.8);
}

._1yMINOrsbYgbFk0GwReCD8:not(:first-child) {
  margin-top: 15px;
}

._1yMINOrsbYgbFk0GwReCD8 a {
  transition: color 0.25s ease-in-out;
}

._1yMINOrsbYgbFk0GwReCD8 a:hover {
  color: #16a58e;
  transition: color 0.25s ease-in-out;
}

._3vkcr8hUjv6mSjA3T81NTK {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.4px;
  color: #1c1b20;
  padding: 25px 0;
}

._2b6LSXuXjoU9tmlZDjxqab {
  font-size: 28px;
  line-height: 40px;
  font-family: "Playfair Display Regular",sans-serif;
  padding: 25px 0;
}
._3vshleJAi6JArNruBisRPX {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._3vshleJAi6JArNruBisRPX {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

._3qLVzb_HNxaMmBGuaKMynp {
  padding-top: 0;
}

@media screen and (min-width: 1000px) {
  ._3qLVzb_HNxaMmBGuaKMynp {
    margin-top: 60px;
    margin-bottom: 180px;
    padding-top: 60px;
  }
}

._17y4RY8rDKmV1zuMerUZbf {
  padding-right: 20px;
  padding-left: 20px;
}
._1JV2BD2YzdXLyf5A3ggVy_ {
  padding: 40px;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  ._1JV2BD2YzdXLyf5A3ggVy_ {
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    padding-left: 80px;
  }
}

._1JV2BD2YzdXLyf5A3ggVy_>* {
  width: 100%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

._2NXU9he_7sXzg68vlEaBOF {
  font-size: 1.2em;
  line-height: 1.5;
  font-style: normal;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._2NXU9he_7sXzg68vlEaBOF._214MHUuO8f1g_4MwWWJXy_ {
  font-size: 1em;
}

@media screen and (min-width: 800px) {
  ._2NXU9he_7sXzg68vlEaBOF {
    font-size: 1.6em;
  }

  ._2NXU9he_7sXzg68vlEaBOF._214MHUuO8f1g_4MwWWJXy_ {
    font-size: 1.2em;
  }
}

._3uKY5XVbGJqbkkIA9Nm8mb {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

._1czkLWphZ7Sx46pOsdU27z {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 50%;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  overflow: hidden;
}

@media screen and (max-width: 449px) {
  ._1czkLWphZ7Sx46pOsdU27z {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
}

._3jpPlIwEteF3SEU7aZDg_4 {
  font-family: "proxima-nova",sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #141315;
}

._1Bsm_pEVhwtkmvZDeE0Sc_ {
  margin-top: -3px;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  color: #2fd0b7;
  letter-spacing: 0.15em;
}

@media screen and (max-width: 449px) {
  ._1Bsm_pEVhwtkmvZDeE0Sc_ {
    margin-top: 0;
    font-size: 12px;
    line-height: 20px;
  }
}

.quote--grey {
  background-color: #f5f5f5;
  padding-bottom: 120px;
}
._33MYeGY7PqW4OQFahtEQB0 {
  background-color: #f5f5f5;
  margin-bottom: 60px;
}

._2-hgBHeHKjayEIeb2Qd8Rz {
  position: relative;
  margin-top: 30px;
  z-index: 1;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  ._2-hgBHeHKjayEIeb2Qd8Rz {
    margin-top: 60px;
  }
}

._2-hgBHeHKjayEIeb2Qd8Rz:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-image: linear-gradient(to bottom, rgba(245,245,245,0), #f5f5f5);
  background-size: cover;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.QzC7pEK8Cy_eAgLTiT2nT._2-hgBHeHKjayEIeb2Qd8Rz:after {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

._1IdpgTZlmbsRc_jBjGwTDz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  line-height: 1.1em;
}

._1IdpgTZlmbsRc_jBjGwTDz:not(:first-child) {
  padding-top: 20px;
}

@media screen and (min-width: 1000px) {
  ._1IdpgTZlmbsRc_jBjGwTDz {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  ._1IdpgTZlmbsRc_jBjGwTDz:not(:first-child) {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1000px) {
  ._1IdpgTZlmbsRc_jBjGwTDz>* {
    -webkit-flex-basis: 33.33%;
    flex-basis: 33.33%;
  }
}

@media screen and (max-width: 999px) {
  ._24HWUbORfPEMOozwEShmW_,
  ._3MZSBY9YYReCCtBT7kJwmh {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
}

._27zhXHUXOvIacL8Cs748MH {
  -webkit-order: 1;
  order: 1;
  width: 100%;
  font-size: 0.8em;
}

@media screen and (min-width: 1000px) {
  ._27zhXHUXOvIacL8Cs748MH {
    width: 150px;
    -webkit-flex-basis: 5%;
    flex-basis: 5%;
    -webkit-order: 2;
    order: 2;
    text-align: right;
    font-size: inherit;
  }
}

._3xQprAHm8eK_B55HI30Jvw,
._24HWUbORfPEMOozwEShmW_,
._3MZSBY9YYReCCtBT7kJwmh {
  -webkit-order: 2;
  order: 2;
}

@media screen and (min-width: 1000px) {
  ._3xQprAHm8eK_B55HI30Jvw,
  ._24HWUbORfPEMOozwEShmW_,
  ._3MZSBY9YYReCCtBT7kJwmh {
    -webkit-order: 1;
    order: 1;
    padding-right: 20px;
  }
}

._3xQprAHm8eK_B55HI30Jvw {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

@media screen and (min-width: 550px) {
  ._3xQprAHm8eK_B55HI30Jvw {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
}

@media screen and (min-width: 1000px) {
  ._3xQprAHm8eK_B55HI30Jvw {
    -webkit-flex-basis: 33.33%;
    flex-basis: 33.33%;
  }
}

@media screen and (min-width: 550px) {
  ._3xQprAHm8eK_B55HI30Jvw:after {
    content: '\2013';
    display: inline-block;
    margin-right: 0.2em;
    margin-left: 0.2em;
  }
}

@media screen and (min-width: 1000px) {
  ._3xQprAHm8eK_B55HI30Jvw:after {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  ._24HWUbORfPEMOozwEShmW_:after {
    content: ',';
    display: inline-block;
    margin-right: 0.2em;
  }
}

@media screen and (min-width: 1000px) {
  ._24HWUbORfPEMOozwEShmW_:after {
    display: none;
  }
}

.SSOi0wO16K3G_84S3ZAHz {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 60px;
  z-index: 2;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1em;
  color: #2fd0b7;
}

.SSOi0wO16K3G_84S3ZAHz.THSngmTN29LDlERZF6tyX {
  pointer-events: none;
}

@media screen and (min-width: 800px) {
  .SSOi0wO16K3G_84S3ZAHz {
    font-size: 1.2em;
  }
}
._2S0ACk1oRcIGvUaV5WGjc1 {
  padding-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._2S0ACk1oRcIGvUaV5WGjc1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1200px) {
  ._2S0ACk1oRcIGvUaV5WGjc1 {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 800px) {
  ._160uy0ZpjTtimQQbuglBnF {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 800px) {
  ._3w6ABvvt59NKyLCG7eEeHr {
    width: calc(35% - 30px);
  }
}

@media screen and (min-width: 1200px) {
  ._3w6ABvvt59NKyLCG7eEeHr {
    width: calc(45% - 30px);
  }
}

._1kJDaLcZLt9PYijuhRCnLM {
  margin-top: 10px;
  font-size: 2.4rem;
  color: rgba(20,19,21,0.7) !important;
}

@media screen and (min-width: 800px) {
  .CTcysP7uje0duJU0hpoN {
    width: calc(62% - 30px);
  }
}

@media screen and (min-width: 1200px) {
  .CTcysP7uje0duJU0hpoN {
    width: calc(52% - 30px);
    padding-right: 30px;
  }
}

.CTcysP7uje0duJU0hpoN ._1ASvDE2boj3JDetiPmOTaN {
  margin-top: 50px;
}

@media screen and (min-width: 800px) {
  .CTcysP7uje0duJU0hpoN ._1ASvDE2boj3JDetiPmOTaN p {
    margin-top: 25px;
  }
}

._33CjlFvnQQB6t2hSOlvCyg {
  margin-top: 60px;
}

.ZKnwqWzsgKygB0mt0FEiL {
  max-width: 760px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 800px) {
  .ZKnwqWzsgKygB0mt0FEiL {
    margin-top: 60px;
  }
}
._1vkjlaoVJN8w9NELyBTxtA {
  margin-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._1vkjlaoVJN8w9NELyBTxtA {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

._3d5YbkDWcMbjx3IG2ZWCDp:not(:first-child) {
  margin-top: 30px;
}

._3Vv768Gwqn3JMjJXmtbkmH:not(:first-child) {
  margin-top: 60px;
}

._18Rn_dr_3_NbWd2gSG1sBJ {
  text-align: right;
}

._18Rn_dr_3_NbWd2gSG1sBJ:not(:first-child) {
  margin-top: 30px;
}

@media screen and (min-width: 800px) {
  ._18Rn_dr_3_NbWd2gSG1sBJ:not(:first-child) {
    margin-top: 60px;
  }
}
._8P6zXedtlJXDGvdXuWy5R {
  position: relative;
}

.device-IE11 ._8P6zXedtlJXDGvdXuWy5R {
  overflow: hidden;
}

._10AnYx78d5yRIRznbqIpbe {
  display: block;
  width: 100%;
  cursor: pointer;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

._20hFeecXIvKtUu3UqyLWEu {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 65px;
  height: 65px;
  fill: transparent;
  stroke: #2fd0b7;
  stroke-width: 3px;
  stroke-linejoin: round;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
}

._1Mgg5h5laFMJSkwTxAioK3 ._20hFeecXIvKtUu3UqyLWEu {
  opacity: 0;
}

._8P6zXedtlJXDGvdXuWy5R ._20hFeecXIvKtUu3UqyLWEu polygon {
  fill: transparent;
  transition: fill 0.3s ease;
}

._8P6zXedtlJXDGvdXuWy5R:hover ._20hFeecXIvKtUu3UqyLWEu polygon {
  fill: #2fd0b7;
}

@media screen and (min-width: 550px) {
  ._20hFeecXIvKtUu3UqyLWEu {
    top: calc(50% - 44px);
    left: calc(50% - 44px);
    width: 88px;
    height: 88px;
  }
}

._2BJtKqWTXgWuLsDFaOrw2- {
  width: 180px;
  height: 180px;
  stroke: #383838;
  stroke-width: 5px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

._8P6zXedtlJXDGvdXuWy5R:hover ._2BJtKqWTXgWuLsDFaOrw2- polygon {
  fill: #383838;
}

@media screen and (min-width: 550px) {
  ._2BJtKqWTXgWuLsDFaOrw2- {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
._XuV9stAxmwaJ3QVj40cg {
  position: relative;
  overflow: hidden;
}

._XuV9stAxmwaJ3QVj40cg {
  cursor: move;
  cursor: -webkit-grab;
}

._XuV9stAxmwaJ3QVj40cg._2El_0NI9B72wShfKa1RtBn {
  cursor: -webkit-grabbing;
}

._1Fvy8rWh6YHvA-nuirtpxL {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  touch-action: none;
}
._192dWNUn-KTy9WZvhOmGf2 {
  padding-top: 60px;
  overflow: hidden;
}

._3TD6YlAhtT_NvAkwuaiG7Y {
  padding-bottom: 60px;
}

._3-YYsu4dCeXLblkIKxRA2t {
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  ._3-YYsu4dCeXLblkIKxRA2t {
    margin-bottom: 120px;
  }
}

._1lZ3OQMomLgLxnZb8NbQXI {
  max-width: 420px;
  margin-bottom: 45px;
}

@media screen and (min-width: 550px) {
  .FpbWFgzCH0_QBO8x8FFhn {
    display: -webkit-flex;
    display: flex;
  }
}

.FpbWFgzCH0_QBO8x8FFhn div {
  max-width: 420px;
}

@media screen and (min-width: 550px) {
  .FpbWFgzCH0_QBO8x8FFhn div:not(:first-child) {
    margin-left: 60px;
  }
}

.btK46W9m8lOrH3OoHv9cb,
.hkTTl7v6dGdtB92uqFb-t {
  display: inline-block;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btK46W9m8lOrH3OoHv9cb+.btK46W9m8lOrH3OoHv9cb,
.hkTTl7v6dGdtB92uqFb-t+.btK46W9m8lOrH3OoHv9cb,
.btK46W9m8lOrH3OoHv9cb+.hkTTl7v6dGdtB92uqFb-t,
.hkTTl7v6dGdtB92uqFb-t+.hkTTl7v6dGdtB92uqFb-t {
  margin-left: 25px;
}

@media screen and (min-width: 800px) {
  .btK46W9m8lOrH3OoHv9cb+.btK46W9m8lOrH3OoHv9cb,
  .hkTTl7v6dGdtB92uqFb-t+.btK46W9m8lOrH3OoHv9cb,
  .btK46W9m8lOrH3OoHv9cb+.hkTTl7v6dGdtB92uqFb-t,
  .hkTTl7v6dGdtB92uqFb-t+.hkTTl7v6dGdtB92uqFb-t {
    margin-left: 40px;
  }
}
._3zX7QUO18C2kHEqyIyaaK9 {
  overflow: hidden;
  padding-bottom: 15px;
  background-image: linear-gradient(#f5f5f5, #f5f5f5);
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

@media screen and (min-width: 1000px) {
  ._3zX7QUO18C2kHEqyIyaaK9 {
    padding-top: 120px;
    padding-bottom: 120px;
    background-size: 100% 220px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  ._3zX7QUO18C2kHEqyIyaaK9 {
    background-size: 100% 120px;
  }
}

._1SKF1dHaQe_GdubigssOy_ {
  position: relative;
}

@media screen and (min-width: 1000px) {
  ._1SKF1dHaQe_GdubigssOy_ {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (max-width: 999px) {
  ._1G6xPSiTYen5cFoqOrzvy2 {
    width: 100%;
    max-width: 500px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1000px) {
  ._1G6xPSiTYen5cFoqOrzvy2 {
    width: calc(50% - 60px);
  }
}

._3e2Wyc7Ar0I0g-D3rUzJ4A {
  position: relative;
}

@media screen and (min-width: 1000px) {
  ._3e2Wyc7Ar0I0g-D3rUzJ4A {
    width: calc(50% - 60px);
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1500px) {
  ._3e2Wyc7Ar0I0g-D3rUzJ4A {
    width: calc(50% - 120px);
  }
}

._3M1lRtYWbIbEjQo7g3vjvr:not(:first-child) {
  margin-top: 2rem;
}

.THecufLzSMqJJUNt8OSbn {
  margin-bottom: -30px;
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
}

.THecufLzSMqJJUNt8OSbn:not(:first-child) {
  margin-top: 3rem;
}

.qZsCJeFmdSqFxAsBaSYtw {
  max-width: 100px;
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  -webkit-flex-basis: 19%;
  flex-basis: 19%;
  list-style-type: none;
}

.qZsCJeFmdSqFxAsBaSYtw:before {
  content: '';
  display: block;
  padding-top: 40%;
}

._1ES8Jatpl2k3cQRFRG2Kf2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

._1ES8Jatpl2k3cQRFRG2Kf2 img {
  object-position: left center;
}
._1VCViuqK8UOFJnI_8vBkh3 {
  position: relative;
  margin-bottom: 30px;
  display: block;
  overflow: hidden;
}

._1VCViuqK8UOFJnI_8vBkh3:after {
  content: '';
  width: 100%;
  height: 1px;
  margin-top: 27px;
  display: block;
  background-color: rgba(28,27,32,0.1);
}

@media screen and (max-width: 799px) {
  ._1VCViuqK8UOFJnI_8vBkh3:last-child:after {
    content: none;
  }
}

@media screen and (min-width: 800px) {
  ._1VCViuqK8UOFJnI_8vBkh3 {
    width: calc(50% - 60px);
    margin-bottom: 60px;
    padding-right: 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1000px) {
  ._1OlSVNpHw_1XmyFhLJ6m5m,
  ._3CPAaaVqvLeRrAGXYoYxjY {
    width: calc(100% + 60px);
  }
}

._3CPAaaVqvLeRrAGXYoYxjY {
  margin-top: 2rem;
  color: rgba(20,19,21,0.7);
}

@media screen and (min-width: 1000px) {
  ._3CPAaaVqvLeRrAGXYoYxjY {
    -webkit-flex-grow: 2;
    flex-grow: 2;
  }
}

._245xN7VH7MF5-oJw8UtCVI {
  margin-top: 2rem;
}

@media screen and (max-width: 549px) {
  ._245xN7VH7MF5-oJw8UtCVI {
    font-size: 1.5rem;
  }

  ._245xN7VH7MF5-oJw8UtCVI svg {
    width: 25px;
  }
}
@media screen and (min-width: 1000px) {
  .f4E5ugFsy37nSsFwIYTPJ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

.TL_NLYJiy1JSl-kjKhUXJ {
  padding-bottom: 40px;
}

@media screen and (min-width: 550px) {
  .TL_NLYJiy1JSl-kjKhUXJ {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1000px) {
  .TL_NLYJiy1JSl-kjKhUXJ {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}

.UIZH8qAuT3Jhs6vMWOOIH {
  width: 100%;
  max-width: 400px;
  position: relative;
  margin-bottom: 6rem;
}

@media screen and (min-width: 1000px) {
  .UIZH8qAuT3Jhs6vMWOOIH {
    width: 30%;
    max-width: none;
    position: relative;
  }
}

.UIZH8qAuT3Jhs6vMWOOIH:not(.Skj9G56aAznWqp-A5S5-9) {
  margin-left: -20px;
}

@media screen and (min-width: 800px) {
  .UIZH8qAuT3Jhs6vMWOOIH:not(.Skj9G56aAznWqp-A5S5-9) {
    margin-left: -10%;
  }
}

@media screen and (min-width: 1000px) {
  .UIZH8qAuT3Jhs6vMWOOIH:not(.Skj9G56aAznWqp-A5S5-9) {
    margin-left: 0;
  }
}

@media screen and (min-width: 1200px) {
  .UIZH8qAuT3Jhs6vMWOOIH:not(.Skj9G56aAznWqp-A5S5-9) {
    width: 40%;
    margin-right: 0;
  }
}

.UIZH8qAuT3Jhs6vMWOOIH.Skj9G56aAznWqp-A5S5-9 {
  padding-right: 60px;
}

._176WPZKANDnU7cXGvATz8b {
  margin-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  ._176WPZKANDnU7cXGvATz8b {
    margin-bottom: 120px;
  }
}

._2wAE4JTinVfkO1CO51Wd3_ {
  position: relative;
}

._2wAE4JTinVfkO1CO51Wd3_:after {
  content: '';
  width: 100%;
  padding-top: 100%;
  display: block;
}

@media screen and (min-width: 1000px) {
  ._2wAE4JTinVfkO1CO51Wd3_:after {
    padding-top: 200%;
  }
}

@media screen and (min-width: 1500px) {
  .UIZH8qAuT3Jhs6vMWOOIH.Skj9G56aAznWqp-A5S5-9 ._2wAE4JTinVfkO1CO51Wd3_ {
    width: calc(100% - 60px);
  }
}

._3f0FZwFytjrA5l0kiD5YCC {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1000px) {
  .UIZH8qAuT3Jhs6vMWOOIH:not(.Skj9G56aAznWqp-A5S5-9) ._3f0FZwFytjrA5l0kiD5YCC {
    left: -10vw;
  }
}

@media screen and (min-width: 1200px) {
  .UIZH8qAuT3Jhs6vMWOOIH:not(.Skj9G56aAznWqp-A5S5-9) ._3f0FZwFytjrA5l0kiD5YCC {
    left: -30%;
  }
}

.UIZH8qAuT3Jhs6vMWOOIH.Skj9G56aAznWqp-A5S5-9 ._3f0FZwFytjrA5l0kiD5YCC {
  left: -20px;
}

@media screen and (min-width: 800px) {
  .UIZH8qAuT3Jhs6vMWOOIH.Skj9G56aAznWqp-A5S5-9 ._3f0FZwFytjrA5l0kiD5YCC {
    left: -10vw;
  }
}

@media screen and (min-width: 1000px) {
  .UIZH8qAuT3Jhs6vMWOOIH.Skj9G56aAznWqp-A5S5-9 ._3f0FZwFytjrA5l0kiD5YCC {
    left: 0;
  }
}

@media screen and (min-width: 800px) {
  ._1msddxqPbppqlOip5VnK3T {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1000px) {
  ._1msddxqPbppqlOip5VnK3T {
    width: 70%;
  }
}

@media screen and (min-width: 1200px) {
  ._1msddxqPbppqlOip5VnK3T {
    width: 60%;
  }
}

._2ArdhyRYZHw5kteJGb8yt9._2ouwrlQDr7cR7T9-w8R6TM {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  ._2ArdhyRYZHw5kteJGb8yt9._2ouwrlQDr7cR7T9-w8R6TM {
    margin-top: 120px;
  }
}
._18WNbex2ujg0sC5YHFVSyO {
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._18WNbex2ujg0sC5YHFVSyO {
    padding-bottom: 120px;
    padding-top: 60px;
  }
}

.ybdgnrA4AUkAnZSQX-QeI {
  position: relative;
  margin-top: 60px;
}

@media screen and (min-width: 1000px) {
  .ybdgnrA4AUkAnZSQX-QeI {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1200px) {
  .ybdgnrA4AUkAnZSQX-QeI {
    margin-top: 180px;
  }
}

.phMMH7s0HdvXgwwp4olF9 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

._252Zhrqdz6SWx62G9VyWFd {
  display: block;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  text-align: left;
  color: #141315;
  font-size: 9vw;
  text-transform: uppercase;
  line-height: normal;
}

@media screen and (max-width: 799px) {
  ._252Zhrqdz6SWx62G9VyWFd {
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  ._252Zhrqdz6SWx62G9VyWFd {
    font-size: 6vw;
  }

  .ybdgnrA4AUkAnZSQX-QeI:nth-child(even) ._252Zhrqdz6SWx62G9VyWFd {
    margin-left: 33.33%;
  }
}

@media screen and (min-width: 1200px) {
  .ybdgnrA4AUkAnZSQX-QeI:nth-child(odd) ._252Zhrqdz6SWx62G9VyWFd {
    margin-left: -60px;
  }
}

.device-IE11 ._252Zhrqdz6SWx62G9VyWFd {
  font-size: 9.5vw;
  font-size: 6.5vw;
}

._3tiH8GLGlO5J2bGvKzktZC {
  margin-top: 15px;
  margin-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._3tiH8GLGlO5J2bGvKzktZC {
    max-width: 500px;
    margin-bottom: 0;
  }

  .ybdgnrA4AUkAnZSQX-QeI:nth-child(even) ._3tiH8GLGlO5J2bGvKzktZC {
    margin-left: calc(33.33% + 60px);
  }
}

@media screen and (min-width: 1000px) {
  ._3tiH8GLGlO5J2bGvKzktZC {
    margin-top: 30px;
  }
}

.nB2zaXycAp-mWH0GlpIal {
  \position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: 799px) {
  .nB2zaXycAp-mWH0GlpIal {
    overflow: auto;
    scrollbar-color: transparent;
  }

  .nB2zaXycAp-mWH0GlpIal::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .nB2zaXycAp-mWH0GlpIal {
    max-width: 1200px;
    margin-top: 60px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .ybdgnrA4AUkAnZSQX-QeI:nth-child(even) .nB2zaXycAp-mWH0GlpIal {
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .nB2zaXycAp-mWH0GlpIal {
    margin-top: 90px;
  }
}

@media screen and (max-width: 799px) {
  .zoPp_qJTJ9X4IKNxE0gQk {
    width: 65%;
    min-width: 65%;
  }

  .zoPp_qJTJ9X4IKNxE0gQk:not(:last-child) {
    margin-right: 15px;
  }
}

@media screen and (min-width: 800px) {
  .zoPp_qJTJ9X4IKNxE0gQk {
    width: calc(33.33% - 15px);
  }
}

.zoPp_qJTJ9X4IKNxE0gQk._1V6-hZGZn_9Z_ZnQqGAVEM {
  margin-top: auto;
}

@media screen and (min-width: 800px) {
  ._1YkIDZbvSUNBiH1Cmpd5QF .zoPp_qJTJ9X4IKNxE0gQk._1V6-hZGZn_9Z_ZnQqGAVEM {
    margin-top: 30px;
  }
}

@media screen and (min-width: 800px) {
  ._1YkIDZbvSUNBiH1Cmpd5QF .zoPp_qJTJ9X4IKNxE0gQk._2JpAJqB3i5cOVQTlOtZ1UY {
    margin-top: auto;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@media screen and (min-width: 800px) {
  ._2JTSaDzA24hXSWy0MpOl-y .zoPp_qJTJ9X4IKNxE0gQk._1V6-hZGZn_9Z_ZnQqGAVEM {
    margin-top: auto;
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@media screen and (min-width: 800px) {
  ._2JTSaDzA24hXSWy0MpOl-y .zoPp_qJTJ9X4IKNxE0gQk._2JpAJqB3i5cOVQTlOtZ1UY {
    margin-top: 15px;
  }
}

@media screen and (min-width: 800px) {
  ._149PSyKn1L1eyvOC0GAGlH .zoPp_qJTJ9X4IKNxE0gQk._1V6-hZGZn_9Z_ZnQqGAVEM {
    margin-top: auto;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@media screen and (min-width: 800px) {
  ._149PSyKn1L1eyvOC0GAGlH .zoPp_qJTJ9X4IKNxE0gQk._2JpAJqB3i5cOVQTlOtZ1UY {
    margin-top: 15px;
  }
}

@media screen and (min-width: 800px) {
  ._2pPj_lUz33-DwT1Ax58lPK .nB2zaXycAp-mWH0GlpIal {
    -webkit-align-items: center;
    align-items: center;
  }
}

@media screen and (min-width: 800px) {
  ._2pPj_lUz33-DwT1Ax58lPK ._1V6-hZGZn_9Z_ZnQqGAVEM {
    margin-top: 30px;
  }
}

._3t6txanpp44QtFqnFpQ2v2 {
  position: relative;
}

._3t6txanpp44QtFqnFpQ2v2:before {
  content: '';
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

._16HVNsb_6oU83vIlUfvvCL {
  padding-top: 60px;
  padding-bottom: 0;
  border-top: 1px #f5f5f5 solid;
}

@media screen and (min-width: 800px) {
  ._16HVNsb_6oU83vIlUfvvCL {
    padding-top: 120px;
  }
}
.mI1z06aEi7-qRCBkjhQdG {
  max-width: 650px;
}

@media screen and (min-width: 800px) {
  .mI1z06aEi7-qRCBkjhQdG {
    padding-top: 60px;
  }
}

._3JFohs_Zunpm_jG8heGYgm {
  margin-top: 1.5em;
  opacity: 0.6;
}
._2Hg0ASGXn0WI18QYmlhBu_ {
  font-size: 30px;
  line-height: 40px;
}

@media screen and (max-width: 799px) {
  ._2Hg0ASGXn0WI18QYmlhBu_ {
    font-size: 28px;
    line-height: 31px;
  }
}

.hacAy6IGNRdizcIES5eue {
  margin-top: 30px;
  font-size: 18px;
}

@media screen and (max-width: 799px) {
  .hacAy6IGNRdizcIES5eue {
    font-size: 16px;
    line-height: 28px;
  }
}

.ERQsPGXhIqbhupAQxUQWO .hs-form {
  padding-top: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: space-between;
  align-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 481px) {
  .ERQsPGXhIqbhupAQxUQWO .hs-form {
    padding-top: 25px;
  }
}

.ERQsPGXhIqbhupAQxUQWO .hs-form fieldset {
  max-width: none;
}

@media screen and (max-width: 481px) {
  .ERQsPGXhIqbhupAQxUQWO .hs-form fieldset:nth-child(3) {
    margin: 0;
  }
}

.ERQsPGXhIqbhupAQxUQWO .hs-form fieldset:nth-child(4)>div>div {
  margin-right: 0;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-1 {
  margin: 13px 0;
  width: 100%;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-1 ._3MxfxxLKsN9MI0TQE5H1-1 {
  width: 100%;
}

.ERQsPGXhIqbhupAQxUQWO .input input[type='text'],
.ERQsPGXhIqbhupAQxUQWO .input input[type='email'],
.ERQsPGXhIqbhupAQxUQWO .input input[type='tel'],
.ERQsPGXhIqbhupAQxUQWO .input textarea {
  width: 100% !important;
}

@media screen and (max-width: 481px) {
  .ERQsPGXhIqbhupAQxUQWO .input input[type='text'],
  .ERQsPGXhIqbhupAQxUQWO .input input[type='email'],
  .ERQsPGXhIqbhupAQxUQWO .input input[type='tel'],
  .ERQsPGXhIqbhupAQxUQWO .input textarea {
    padding: 13px 1px !important;
  }
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 {
  width: 100%;
  margin: 13px 0;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-form-field {
  float: left;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-form-field:nth-child(even) {
  float: right;
}

@media screen and (max-width: 481px) {
  .ERQsPGXhIqbhupAQxUQWO .form-columns-2 {
    margin: 0;
  }
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea textarea {
  width: 100%;
  color: #1c1b20;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 6px 1px;
  border-bottom: #d7d7d8 2px solid;
  transition: border-bottom ease 0.25s;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text input::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text textarea::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea input::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea textarea::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text input::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text textarea::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea input::-webkit-input-placeholder,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea textarea::-webkit-input-placeholder {
  color: #1c1b20;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text input.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text textarea.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber input.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber textarea.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea input.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea textarea.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text input.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text textarea.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber input.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber textarea.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea input.error,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea textarea.error {
  border-color: #f53175;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text .input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber .input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea .input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text .input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber .input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

@media screen and (min-width: 550px) {
  .ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-text,
  .ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-phonenumber,
  .ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-text,
  .ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-phonenumber {
    width: 43% !important;
  }
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea textarea,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea textarea {
  padding: 10px;
  border: #d7d7d8 2px solid;
  resize: none;
  height: 139px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea label,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea label {
  width: 100%;
  margin: 7px 0;
  color: #1c1b20;
  font-size: 1.8rem;
  line-height: 1.3;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea label ::after,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea label ::after {
  content: '*';
}

@-moz-document url-prefix()  {
  .ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea label,
  .ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea label {
    color: #858587;
  }
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-textarea .input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox ul,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox ul {
  list-style-type: none;
  padding: 0;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox .input label,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox .input label {
  cursor: pointer;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox label,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  line-height: 28px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox label input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox label input {
  line-height: 28px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox label span,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox label span {
  position: absolute;
  left: 40px;
  top: 0;
  transition: top 0.25s;
  line-height: 28px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox label span::after,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox label span::after {
  content: '';
  position: absolute;
  top: 25px;
  left: 0;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox label.checkbox-checked span,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox label.checkbox-checked span {
  position: absolute;
  top: -25px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox input,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox input {
  position: relative;
  margin-right: 20px;
  height: 24px;
  min-width: 24px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox input:before,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox input:before {
  content: '';
  height: 24px;
  min-width: 24px;
  position: absolute;
  border: 2px solid #d7d7d8;
  z-index: 1;
  background: #fff;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox input:after,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox input:after {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #2fd0b7;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: opacity 0.25s ease, -webkit-transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease;
  z-index: 2;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-fieldtype-booleancheckbox input:checked:after,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-fieldtype-booleancheckbox input:checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: TpE-ZI_4TZE5pqUln9Xyx 0.3s ease-out;
  animation: TpE-ZI_4TZE5pqUln9Xyx 0.3s ease-out;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy {
  margin-top: 27px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy label span::after,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy label span::after {
  content: 'Great!';
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk {
  margin-top: -13px;
  margin-bottom: 32px;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk label span::after,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk label span::after {
  content: "I'm awesome!";
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-form-required,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-form-required {
  display: none;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-error-msgs,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-error-msgs {
  list-style-type: none;
  padding: 0;
}

.ERQsPGXhIqbhupAQxUQWO .form-columns-2 .hs-error-msgs .hs-error-msg,
.ERQsPGXhIqbhupAQxUQWO .form-columns-1 .hs-error-msgs .hs-error-msg {
  color: #f53175;
}

.ERQsPGXhIqbhupAQxUQWO .hs_error_rollup {
  width: 100%;
  margin: 0 0 13px 0;
}

.ERQsPGXhIqbhupAQxUQWO .hs_error_rollup .hs-error-msgs {
  list-style-type: none;
  padding: 0;
  color: #f53175;
}

.ERQsPGXhIqbhupAQxUQWO .hs-submit {
  position: relative;
}

.ERQsPGXhIqbhupAQxUQWO .hs-submit input {
  cursor: pointer;
  border: none;
  padding: 11px 77px 11px 27px;
  z-index: 1;
  background-image: linear-gradient(-90deg, #47dba4, #46d1be);
  background-size: cover;
  color: #fff;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 1.3;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.4s ease;
}

@media screen and (max-width: 799px) {
  .ERQsPGXhIqbhupAQxUQWO .hs-submit input {
    font-size: 1.5rem;
  }
}

.ERQsPGXhIqbhupAQxUQWO .hs-submit input:hover {
  background-image: linear-gradient(90deg, #47dba4, #46d1be);
  background-size: cover;
}

.ERQsPGXhIqbhupAQxUQWO .hs-submit::after {
  content: '';
  position: absolute;
  width: 35px;
  height: 14px;
  background: url(/media/images/svg/arrow-right.svg);
  right: 30px;
  transition: right 0.25s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

.ERQsPGXhIqbhupAQxUQWO .hs-submit:hover::after {
  right: 20px;
}

.ERQsPGXhIqbhupAQxUQWO .submitted-message {
  color: #2fd0b7;
  font-size: 2.2rem;
  margin-top: 50px;
}

@-webkit-keyframes TpE-ZI_4TZE5pqUln9Xyx {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes TpE-ZI_4TZE5pqUln9Xyx {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.pf8LdEhUhCBCdoJh_95yd {
  font-size: 30px;
  line-height: 40px;
}

@media screen and (max-width: 799px) {
  .pf8LdEhUhCBCdoJh_95yd {
    font-size: 28px;
    line-height: 31px;
  }
}

._1XWrCzBa4zPHcrucmWPIGP {
  margin-top: 30px;
  font-size: 18px;
}

@media screen and (max-width: 799px) {
  ._1XWrCzBa4zPHcrucmWPIGP {
    font-size: 16px;
    line-height: 28px;
  }
}

._5jBZE2wt6FJGtkljrp8_V .hs-form {
  padding-top: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: space-between;
  align-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 481px) {
  ._5jBZE2wt6FJGtkljrp8_V .hs-form {
    padding-top: 25px;
  }
}

._5jBZE2wt6FJGtkljrp8_V .hs-form fieldset {
  max-width: none;
}

@media screen and (max-width: 481px) {
  ._5jBZE2wt6FJGtkljrp8_V .hs-form fieldset:nth-child(4) {
    margin: 0;
  }
}

._5jBZE2wt6FJGtkljrp8_V .hs-form fieldset:nth-child(5)>div>div {
  margin-right: 0;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-1 {
  margin: 13px 0;
  width: 100%;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-1 ._2amiNU1XaISM2cYKF3VVmx {
  width: 100%;
}

._5jBZE2wt6FJGtkljrp8_V .input input[type='text'],
._5jBZE2wt6FJGtkljrp8_V .input input[type='email'],
._5jBZE2wt6FJGtkljrp8_V .input input[type='tel'],
._5jBZE2wt6FJGtkljrp8_V .input textarea {
  width: 100% !important;
}

@media screen and (max-width: 481px) {
  ._5jBZE2wt6FJGtkljrp8_V .input input[type='text'],
  ._5jBZE2wt6FJGtkljrp8_V .input input[type='email'],
  ._5jBZE2wt6FJGtkljrp8_V .input input[type='tel'],
  ._5jBZE2wt6FJGtkljrp8_V .input textarea {
    padding: 13px 1px !important;
  }
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 {
  width: 100%;
  margin: 13px 0;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-form-field {
  float: left;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-form-field:nth-child(even) {
  float: right;
}

@media screen and (max-width: 481px) {
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-2 {
    margin: 0;
  }
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea textarea {
  width: 100%;
  color: #1c1b20;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 6px 1px;
  border-bottom: #d7d7d8 2px solid;
  transition: border-bottom ease 0.25s;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text input::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text textarea::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea input::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea textarea::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text input::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text textarea::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea input::-webkit-input-placeholder,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea textarea::-webkit-input-placeholder {
  color: #1c1b20;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text input.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text textarea.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber input.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber textarea.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea input.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea textarea.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text input.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text textarea.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber input.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber textarea.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea input.error,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea textarea.error {
  border-color: #f53175;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text .input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber .input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea .input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text .input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber .input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

@media screen and (min-width: 550px) {
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-text,
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-phonenumber,
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-text,
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-phonenumber {
    width: 43% !important;
  }
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea textarea,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea textarea {
  padding: 10px;
  border: #d7d7d8 2px solid;
  resize: none;
  height: 139px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea label,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea label {
  width: 100%;
  margin: 7px 0;
  color: #1c1b20;
  font-size: 1.8rem;
  line-height: 1.3;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea label ::after,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea label ::after {
  content: '*';
}

@-moz-document url-prefix()  {
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea label,
  ._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea label {
    color: #858587;
  }
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-textarea .input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox ul,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox ul {
  list-style-type: none;
  padding: 0;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox .input label,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox .input label {
  cursor: pointer;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox label,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  line-height: 28px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox label input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox label input {
  line-height: 28px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox label span,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox label span {
  position: absolute;
  left: 40px;
  top: 0;
  transition: top 0.25s;
  line-height: 28px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox label span::after,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox label span::after {
  content: '';
  position: absolute;
  top: 25px;
  left: 0;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox label.checkbox-checked span,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox label.checkbox-checked span {
  position: absolute;
  top: -25px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox input,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox input {
  position: relative;
  margin-right: 20px;
  height: 24px;
  min-width: 24px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox input:before,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox input:before {
  content: '';
  height: 24px;
  min-width: 24px;
  position: absolute;
  border: 2px solid #d7d7d8;
  z-index: 1;
  background: #fff;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox input:after,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox input:after {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #2fd0b7;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: opacity 0.25s ease, -webkit-transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease;
  z-index: 2;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-fieldtype-booleancheckbox input:checked:after,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-fieldtype-booleancheckbox input:checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: _2XsII2VASZCQ6Xfhdy14Ds 0.3s ease-out;
  animation: _2XsII2VASZCQ6Xfhdy14Ds 0.3s ease-out;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy {
  margin-top: 27px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy label span::after,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy label span::after {
  content: 'Great!';
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk {
  margin-top: -13px;
  margin-bottom: 32px;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk label span::after,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk label span::after {
  content: "I'm awesome!";
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-form-required,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-form-required {
  display: none;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-error-msgs,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-error-msgs {
  list-style-type: none;
  padding: 0;
}

._5jBZE2wt6FJGtkljrp8_V .form-columns-2 .hs-error-msgs .hs-error-msg,
._5jBZE2wt6FJGtkljrp8_V .form-columns-1 .hs-error-msgs .hs-error-msg {
  color: #f53175;
}

._5jBZE2wt6FJGtkljrp8_V .hs_error_rollup {
  width: 100%;
  margin: 0 0 13px 0;
}

._5jBZE2wt6FJGtkljrp8_V .hs_error_rollup .hs-error-msgs {
  list-style-type: none;
  padding: 0;
  color: #f53175;
}

._5jBZE2wt6FJGtkljrp8_V .hs-submit {
  position: relative;
}

._5jBZE2wt6FJGtkljrp8_V .hs-submit input {
  cursor: pointer;
  border: none;
  padding: 11px 77px 11px 27px;
  z-index: 1;
  background-image: linear-gradient(-90deg, #47dba4, #46d1be);
  background-size: cover;
  color: #fff;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 1.3;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.4s ease;
}

@media screen and (max-width: 799px) {
  ._5jBZE2wt6FJGtkljrp8_V .hs-submit input {
    font-size: 1.5rem;
  }
}

._5jBZE2wt6FJGtkljrp8_V .hs-submit input:hover {
  background-image: linear-gradient(90deg, #47dba4, #46d1be);
  background-size: cover;
}

._5jBZE2wt6FJGtkljrp8_V .hs-submit::after {
  content: '';
  position: absolute;
  width: 35px;
  height: 14px;
  background: url(/media/images/svg/arrow-right.svg);
  right: 30px;
  transition: right 0.25s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

._5jBZE2wt6FJGtkljrp8_V .hs-submit:hover::after {
  right: 20px;
}

._5jBZE2wt6FJGtkljrp8_V .submitted-message {
  color: #2fd0b7;
  font-size: 2.2rem;
  margin-top: 50px;
}

@-webkit-keyframes _2XsII2VASZCQ6Xfhdy14Ds {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes _2XsII2VASZCQ6Xfhdy14Ds {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.RoJUJctlBrD_2K3TTh8UA {
  overflow: hidden;
}

.RnSLwYDe7wYFpdJSanlVk {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  border: 0;
  padding: 60px 0;
  -webkit-flex-direction: row;
  flex-direction: row;
}

@media screen and (max-width: 1199px) {
  .RnSLwYDe7wYFpdJSanlVk {
    padding: 0;
  }
}

@media screen and (max-width: 799px) {
  .RnSLwYDe7wYFpdJSanlVk {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

._2nF_IxQKMx_ESn-wt-jkZr {
  width: 486px;
  padding-top: 65px;
}

@media screen and (max-width: 799px) {
  ._2nF_IxQKMx_ESn-wt-jkZr {
    width: 100%;
    padding-top: 10px;
    max-width: initial;
    position: relative;
  }
}

._2nF_IxQKMx_ESn-wt-jkZr:before {
  content: '';
  position: absolute;
  top: 145px;
  left: 0;
  width: 41vw;
  height: 940px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 1199px) {
  ._2nF_IxQKMx_ESn-wt-jkZr:before {
    top: 85px;
  }
}

@media screen and (max-width: 799px) {
  ._2nF_IxQKMx_ESn-wt-jkZr:before {
    top: -100px;
    left: -100px;
    right: -100px;
    bottom: 100px;
    width: 150%;
    height: 130%;
  }
}

@media screen and (min-width: 2200px) {
  ._2nF_IxQKMx_ESn-wt-jkZr:before {
    width: 43.5vw;
  }
}

._1e0ywZM2G5jlPyJPyDFOe4 {
  margin-bottom: 30px;
}

._2-YZXCLYIg6muRpoAKkoUd {
  margin-bottom: 20px;
}

._27HMcMuaOq020-r9bXcs-F {
  margin-bottom: 60px;
}

@media screen and (max-width: 799px) {
  ._27HMcMuaOq020-r9bXcs-F p {
    font-size: 16px;
  }
}

.EJO_BJJ0EGB4xLIdlIjpt {
  background-image: none !important;
}

.FJYhFp5nH4XdwhBCfMbio {
  width: 750px;
  padding-left: 40px;
  padding-top: 45px;
}

@media screen and (max-width: 1499px) {
  .FJYhFp5nH4XdwhBCfMbio {
    padding-left: 120px;
  }
}

@media screen and (max-width: 799px) {
  .FJYhFp5nH4XdwhBCfMbio {
    width: 100%;
    padding-top: 150px;
    padding-left: 0;
  }
}

@media screen and (max-width: 449px) {
  .FJYhFp5nH4XdwhBCfMbio {
    padding-top: 125px;
  }
}

._1Dfen86CR1zt6r0oRAaYlw {
  max-width: 450px;
  position: relative;
  margin-bottom: 50px;
  display: -webkit-flex;
  display: flex;
}

._3TTfbORsKYUHTzgweyGjtg {
  width: 50%;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  color: #141315;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  cursor: pointer;
  opacity: 0.6;
  font-size: 20px;
  white-space: nowrap;
}

._1qxJPlp3bFON35LbhyUYCE._3TTfbORsKYUHTzgweyGjtg {
  opacity: 1;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._14W2aJ5TJWabOxZsKVObNU {
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

._14W2aJ5TJWabOxZsKVObNU:before {
  content: '';
  width: 28px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2fd0b7;
}

._1qxJPlp3bFON35LbhyUYCE:nth-child(1) ~ ._14W2aJ5TJWabOxZsKVObNU,
._3TTfbORsKYUHTzgweyGjtg:nth-child(1):hover ~ ._14W2aJ5TJWabOxZsKVObNU {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

._1qxJPlp3bFON35LbhyUYCE:nth-child(2) ~ ._14W2aJ5TJWabOxZsKVObNU,
._3TTfbORsKYUHTzgweyGjtg:nth-child(2):hover ~ ._14W2aJ5TJWabOxZsKVObNU {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
._1247BE2BxMu31jWNgsODLx {
  position: relative;
  padding-bottom: 50px;
  margin: 130px 0 350px;
}

@media screen and (max-width: 999px) {
  ._1247BE2BxMu31jWNgsODLx {
    margin: 40px 0;
    background: transparent linear-gradient(67deg, #47dba4 0%, #46d1be 100%) 0% 0% no-repeat padding-box;
  }
}

.ZVhr5U5QPiCxsml96wljb:before {
  content: '';
  width: 70vw;
  height: 570px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: transparent linear-gradient(67deg, #47dba4 0%, #46d1be 100%) 0% 0% no-repeat padding-box;
}

@media screen and (max-width: 999px) {
  .ZVhr5U5QPiCxsml96wljb:before {
    display: none;
  }
}

.HUq2LHH4qfOcRBpx7iHdr {
  max-width: 450px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  padding-top: 100px;
}

@media screen and (max-width: 999px) {
  .HUq2LHH4qfOcRBpx7iHdr {
    max-width: initial;
    padding-top: 50px;
  }
}

.WZ7LkbP3IuX5C3-2_CpNR,
._6bH1677xFqKHKMHyjcPfm,
._39_a9tzDxACfAs3JYY7avA,
._4_0rPYvr0HLYcB-FJCbVH {
  position: relative;
  z-index: 3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color ease-out 0.25s 0.1s;
}

@media screen and (max-width: 799px) {
  .WZ7LkbP3IuX5C3-2_CpNR {
    font-size: 28px;
  }
}

._39_a9tzDxACfAs3JYY7avA {
  padding-top: 30px;
  padding-bottom: 40px;
}

@media screen and (max-width: 799px) {
  ._39_a9tzDxACfAs3JYY7avA {
    padding-bottom: 20px;
  }

  ._39_a9tzDxACfAs3JYY7avA p {
    font-size: 16px;
  }
}

._6bH1677xFqKHKMHyjcPfm {
  padding-bottom: 2px;
}

@media screen and (max-width: 799px) {
  ._6bH1677xFqKHKMHyjcPfm {
    padding-bottom: 20px;
  }
}

._4_0rPYvr0HLYcB-FJCbVH {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  line-height: 21px;
}

._4_0rPYvr0HLYcB-FJCbVH svg {
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

._4_0rPYvr0HLYcB-FJCbVH:hover svg {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

._2PDQOXFcPR78DdlsIz3ARe {
  max-width: 896px;
  position: absolute;
  top: 250px;
  right: 0;
}

@media screen and (max-width: 1499px) {
  ._2PDQOXFcPR78DdlsIz3ARe {
    max-width: 700px;
    top: 375px;
  }
}

@media screen and (max-width: 1199px) {
  ._2PDQOXFcPR78DdlsIz3ARe {
    max-width: 550px;
  }
}

@media screen and (max-width: 999px) {
  ._2PDQOXFcPR78DdlsIz3ARe {
    position: static;
    margin-top: 50px;
  }
}

._169sxzJ4dK8zIDGbr8Um-o {
  display: block;
}

@media screen and (max-width: 999px) {
  ._169sxzJ4dK8zIDGbr8Um-o {
    display: none;
  }
}

._3Q1ov4MQT52XDdjrvhr8-8 {
  display: none;
}

@media screen and (max-width: 999px) {
  ._3Q1ov4MQT52XDdjrvhr8-8 {
    display: block;
  }
}
._2hl9C21FJYm3x33hQ97VHQ {
  background-color: #F9F9F9;
  height: 740px;
  cursor: pointer;
}

._2hl9C21FJYm3x33hQ97VHQ:hover ._2goCVf2z4K35hpMH2-KUS5 figure {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

._2hl9C21FJYm3x33hQ97VHQ:hover ._2_A7T34OLLuSCY1XZ8EnCB {
  color: unset;
}

._2hl9C21FJYm3x33hQ97VHQ:hover ._2WA6HxLulasYTJ0C132_s9 svg {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

@media screen and (max-width: 799px) {
  ._2hl9C21FJYm3x33hQ97VHQ {
    height: 590px;
  }
}

._2goCVf2z4K35hpMH2-KUS5 {
  width: 100%;
  height: 340px;
  overflow: hidden;
}

._2goCVf2z4K35hpMH2-KUS5 figure {
  transition: -webkit-transform ease 0.25s;
  transition: transform ease 0.25s;
  transition: transform ease 0.25s, -webkit-transform ease 0.25s;
}

@media screen and (max-width: 799px) {
  ._2goCVf2z4K35hpMH2-KUS5 {
    height: 260px;
  }
}

._2_A7T34OLLuSCY1XZ8EnCB {
  padding: 40px;
  height: 400px;
  position: relative;
  color: #141315;
}

._2_A7T34OLLuSCY1XZ8EnCB>div>span {
  font-family: "proxima-nova",sans-serif;
  font-weight: 700;
  color: #adadad;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

._2_A7T34OLLuSCY1XZ8EnCB>div>p {
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-size: 25px;
  line-height: 33px;
}

@media screen and (max-width: 1199px) {
  ._2_A7T34OLLuSCY1XZ8EnCB>div>p {
    font-size: 21px;
    line-height: 28px;
  }
}

@media screen and (max-width: 799px) {
  ._2_A7T34OLLuSCY1XZ8EnCB>div>p {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 399px) {
  ._2_A7T34OLLuSCY1XZ8EnCB>div>p {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1499px) {
  ._2_A7T34OLLuSCY1XZ8EnCB {
    padding: 30px;
  }
}

@media screen and (max-width: 799px) {
  ._2_A7T34OLLuSCY1XZ8EnCB {
    height: 330px;
  }
}

@media screen and (max-width: 1199px) {
  .UHefEA1O809w698aLSzjb {
    font-size: 28px;
  }
}

._19m3tKwXP3fMAQdIT4G0R5,
._2OQ5j4ovJWSST_R4XVwsLa {
  margin-top: 10px;
}

._2WA6HxLulasYTJ0C132_s9 {
  position: absolute;
  left: 40px;
  bottom: 40px;
}

@media screen and (max-width: 1499px) {
  ._2WA6HxLulasYTJ0C132_s9 {
    left: 30px;
    bottom: 30px;
  }
}
._1f6rAzH7BRVQGkeL6TSY1w {
  position: relative;
  padding-top: 80px;
  padding-bottom: 120px;
  overflow: hidden;
}

@media screen and (max-width: 999px) {
  ._1f6rAzH7BRVQGkeL6TSY1w {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

._3TorgfH5D-XkT1XoHyoOaF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 60px;
}

._3TorgfH5D-XkT1XoHyoOaF>div {
  -webkit-flex-basis: 33.33%;
  flex-basis: 33.33%;
}

._3TorgfH5D-XkT1XoHyoOaF>div:not(:last-of-type) {
  margin-right: 60px;
}

@media screen and (max-width: 1499px) {
  ._3TorgfH5D-XkT1XoHyoOaF>div:not(:last-of-type) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 999px) {
  ._3TorgfH5D-XkT1XoHyoOaF>div {
    -webkit-flex-basis: calc(50% - 20px);
    flex-basis: calc(50% - 20px);
    margin-bottom: 40px;
  }

  ._3TorgfH5D-XkT1XoHyoOaF>div:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 799px) {
  ._3TorgfH5D-XkT1XoHyoOaF>div {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    margin-bottom: 30px;
  }

  ._3TorgfH5D-XkT1XoHyoOaF>div:not(:last-of-type) {
    margin-right: 0;
  }
}

@media screen and (max-width: 999px) {
  ._3TorgfH5D-XkT1XoHyoOaF {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
._3FTE3lbg-oLakz7bNZzzne {
  position: relative;
  padding: 100px 0 450px;
  overflow: hidden;
}

@media screen and (max-width: 1499px) {
  ._3FTE3lbg-oLakz7bNZzzne {
    padding: 50px 0 450px;
  }
}

@media screen and (max-width: 799px) {
  ._3FTE3lbg-oLakz7bNZzzne {
    padding: 10px 0 450px;
  }
}

._1Zs-kCNO_UPJoIoCJGp9WV {
  background-color: #f5f5f5;
  width: 75vw;
  height: 360px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 90px 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

._1Zs-kCNO_UPJoIoCJGp9WV blockquote {
  font-size: 32px;
  line-height: 42px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-style: italic;
  width: 50vw;
}

@media screen and (max-width: 1599px) {
  ._1Zs-kCNO_UPJoIoCJGp9WV blockquote {
    font-size: 26px;
    line-height: 32px;
    width: 100%;
  }
}

@media screen and (max-width: 799px) {
  ._1Zs-kCNO_UPJoIoCJGp9WV blockquote {
    font-size: 18px;
    line-height: 31px;
  }
}

._1Zs-kCNO_UPJoIoCJGp9WV cite {
  font-size: 20px;
  line-height: 28px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  margin-top: 35px;
  font-style: unset;
}

@media screen and (max-width: 799px) {
  ._1Zs-kCNO_UPJoIoCJGp9WV cite {
    font-size: 18px;
    line-height: 31px;
  }
}

._1Zs-kCNO_UPJoIoCJGp9WV span {
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  color: #2fd0b7;
  letter-spacing: 0.15em;
}

@media screen and (max-width: 999px) {
  ._1Zs-kCNO_UPJoIoCJGp9WV {
    width: 100%;
    padding-left: 9%;
    padding-right: 9%;
    height: auto;
  }
}
._2jCK8AgD5i9M1puOEEAGot {
  padding-top: 100px;
  padding-bottom: 300px;
  overflow: hidden;
}

@media screen and (max-width: 1199px) {
  ._2jCK8AgD5i9M1puOEEAGot {
    background-color: #f5f5f5;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1199px) {
  ._2jCK8AgD5i9M1puOEEAGot {
    padding-left: 9%;
    padding-right: 9%;
  }
}

._1_siNo8x-SsiE1Rb-NpUjF {
  max-height: 650px;
  margin-top: 70px;
  display: -webkit-flex;
  display: flex;
  background-color: #f5f5f5;
}

@media screen and (max-width: 1199px) {
  ._1_siNo8x-SsiE1Rb-NpUjF {
    -webkit-flex-direction: column;
    flex-direction: column;
    max-height: initial;
  }
}

.t-AfdvQbYcZ-RvGQBau_b {
  width: 100%;
  height: 650px;
  max-width: 450px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  position: relative;
}

.t-AfdvQbYcZ-RvGQBau_b>figure {
  background-image: none !important;
}

@media screen and (max-width: 1199px) {
  .t-AfdvQbYcZ-RvGQBau_b {
    margin: 0 auto;
  }
}

@media screen and (max-width: 599px) {
  .t-AfdvQbYcZ-RvGQBau_b {
    height: 385px;
    width: 269px;
  }
}

._2lgrjDh-lwgJQYuYZEAzYt {
  padding: 145px 45px;
}

@media screen and (max-width: 1499px) {
  ._2lgrjDh-lwgJQYuYZEAzYt {
    padding: 60px 45px;
  }
}

@media screen and (max-width: 1199px) {
  ._2lgrjDh-lwgJQYuYZEAzYt {
    padding: 60px 0;
  }
}

._2JcZnzyrsBC7OaaVimBCmP {
  margin-top: 20px;
}

@media screen and (max-width: 1199px) {
  ._2JcZnzyrsBC7OaaVimBCmP {
    font-size: 28px;
    margin-top: 16px;
  }
}

.hiyd8TzLDEsqQrS1c-Yk0 {
  margin-top: 50px;
  font-size: 20px;
  line-height: 32px;
}

@media screen and (max-width: 799px) {
  .hiyd8TzLDEsqQrS1c-Yk0 {
    margin-top: 25px;
    font-size: 18px;
  }
}

._3Sjowk0G10ZoiUAFx4157q {
  margin-top: 50px;
}

@media screen and (max-width: 799px) {
  ._3Sjowk0G10ZoiUAFx4157q {
    margin-top: 40px;
  }
}

.H5DgybKntMoFy4JzHrCrY {
  display: block;
}

@media screen and (max-width: 1199px) {
  .H5DgybKntMoFy4JzHrCrY {
    display: none;
  }
}

.AuhPAt_q19ejTZiSGQoPo {
  display: none;
}

@media screen and (max-width: 1199px) {
  .AuhPAt_q19ejTZiSGQoPo {
    display: block;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 800px) {
  .IQ_fYNmdF4-8UxiX1W7IW {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
}

@media screen and (min-width: 400px) {
  ._3Tnyliz9smhFcg3bMgyEXs {
    width: calc(100% - 60px);
  }
}

@media screen and (min-width: 550px) {
  ._3Tnyliz9smhFcg3bMgyEXs {
    width: calc(100% - 120px);
    max-width: 450px;
  }
}

@media screen and (min-width: 800px) {
  ._3Tnyliz9smhFcg3bMgyEXs {
    width: auto;
    max-width: none;
    min-width: 40%;
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
  }
}

._3Tnyliz9smhFcg3bMgyEXs::before {
  content: '';
  padding-bottom: 116.667%;
  display: block;
}

.fR4g2-jSX5dmPlvWU-7uC {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

@media screen and (min-width: 800px) {
  .fR4g2-jSX5dmPlvWU-7uC {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
  }
}

.er4gjcBROltJNIsEnYQJI {
  width: 100%;
  display: grid;
  align-self: flex-end;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: 'unit';
  overflow: hidden;
}

._1ycBoTKCHMRc2Y_B2G3DQT {
  width: 100%;
  padding-top: 40px;
  padding-right: 17px;
  padding-bottom: 30px;
  padding-left: 17px;
  z-index: 0;
  grid-area: unit;
  align-self: flex-start;
  pointer-events: none;
  background-color: #fff;
  opacity: 0;
  transition: all 400ms;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

@media screen and (min-width: 800px) {
  ._1ycBoTKCHMRc2Y_B2G3DQT {
    padding-top: 60px;
    padding-right: 60px;
    padding-bottom: 60px;
    padding-left: 60px;
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
}

@media screen and (min-width: 1000px) {
  ._1ycBoTKCHMRc2Y_B2G3DQT {
    padding-top: 90px;
    padding-right: 120px;
    padding-bottom: 90px;
    padding-left: 120px;
  }
}

._1ycBoTKCHMRc2Y_B2G3DQT.uhELiRKJkbIM0hNe1JX7c {
  z-index: 2;
  opacity: 1;
  pointer-events: initial;
  -webkit-transform: none;
  transform: none;
}

.device-IE11 ._1ycBoTKCHMRc2Y_B2G3DQT {
  display: none;
}

.device-IE11 ._1ycBoTKCHMRc2Y_B2G3DQT.uhELiRKJkbIM0hNe1JX7c {
  display: block;
}

._31X6_KVLeEJC7XKZk4Myq1 {
  position: absolute;
  top: 50px;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  z-index: 3;
}

@media screen and (min-width: 800px) {
  ._31X6_KVLeEJC7XKZk4Myq1 {
    top: 0;
    left: 12px;
    -webkit-transform: none;
    transform: none;
  }
}

._2gGs8_VU-xTeGbDLxHelus {
  margin-top: 30px;
}
._2B92CGEQDUWh021FyIl-do {
  position: relative;
  margin-top: 120px;
}

@media screen and (min-width: 1000px) {
  ._2B92CGEQDUWh021FyIl-do {
    margin-top: 240px;
  }
}

._2B92CGEQDUWh021FyIl-do ._2T8u_RyDn7GE7yNduIIyyH {
  padding: 30px;
}

.ytLyuuY9FdeQHfi8h9Nq-,
._3nFLDgVyOMTTik8xhNAWWW {
  position: relative;
  z-index: 2;
}

._3nFLDgVyOMTTik8xhNAWWW {
  margin-top: 60px;
}

@media screen and (min-width: 1000px) {
  ._3nFLDgVyOMTTik8xhNAWWW {
    margin-top: 120px;
  }
}

._2B92CGEQDUWh021FyIl-do:before {
  content: '';
  width: 70%;
  height: calc(100% + 265px);
  position: absolute;
  top: -130px;
  right: 0;
  background-color: #fafafa;
}
._1HJzFcW4-xXAtyJui1Gm_3 {
  position: relative;
}

._3uwDPhnX8eNTWtCKqS2RON {
  width: 100%;
  cursor: pointer;
}

.zK1xuVcHzwmUQ2eTgaNwx {
  width: 15%;
  max-width: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  fill: #2fd0b7;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Y0Mem_8gcXkH7_vIdmLno {
  opacity: 0;
}
._11iACiXl_s8g2R-WQlN9ey {
  position: relative;
  margin-top: 60px;
}

@media screen and (min-width: 1000px) {
  ._11iACiXl_s8g2R-WQlN9ey {
    margin-top: 60px;
  }
}

._11iACiXl_s8g2R-WQlN9ey ._10kHilsht8Dv0q5RUVHweg {
  margin-top: 30px;
  margin-bottom: 50px;
  color: rgba(20,19,21,0.6);
  font-size: inherit;
}

.ECoU_4J870o2_EF-yLhtf,
._28u5J2CZbMjWYGCvLfiP45 {
  position: relative;
  z-index: 2;
}

._28u5J2CZbMjWYGCvLfiP45 {
  margin-top: 60px;
}

@media screen and (min-width: 1000px) {
  ._28u5J2CZbMjWYGCvLfiP45 {
    margin-top: 120px;
  }
}
.DxwvW_9b11ZuW4e_TtmTQ {
  margin-top: 360px;
  margin-bottom: 180px;
  overflow-x: hidden;
}

@media screen and (max-width: 999px) {
  .DxwvW_9b11ZuW4e_TtmTQ {
    margin-top: 240px;
    margin-bottom: 60px;
    background-color: #f5f5f5;
  }
}

._3g4STKkUf1VYYy-x0a49RF {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  ._3g4STKkUf1VYYy-x0a49RF {
    padding-top: 150px;
    padding-right: 60px;
    padding-bottom: 150px;
    padding-left: 60px;
  }
}

._3g4STKkUf1VYYy-x0a49RF:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
}

@media screen and (min-width: 1000px) {
  ._3g4STKkUf1VYYy-x0a49RF:before {
    width: 100vw;
    left: 10%;
  }
}

@media screen and (min-width: 1500px) {
  ._3g4STKkUf1VYYy-x0a49RF:before {
    left: 20%;
  }
}

._2-iWxxGBGTg2YniMfhB5ca {
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 1000px) {
  ._2-iWxxGBGTg2YniMfhB5ca {
    width: 75%;
    margin-left: auto;
  }
}

@media screen and (min-width: 1500px) {
  ._2-iWxxGBGTg2YniMfhB5ca {
    width: 60%;
  }
}
.MD_gfuc79TgwpKd4uq1OK {
  position: relative;
  z-index: 1;
}

._1TWyME7GA4n2dUnP5-CMZS {
  overflow: hidden;
}

.IDasYXM-NvTLttgh80ItG {
  width: 100%;
  height: auto;
  min-height: 100vw;
  display: block;
  object-fit: cover;
  object-position: center;
  -webkit-transform-origin: top;
  transform-origin: top;
}

@media screen and (min-width: 700px) {
  .IDasYXM-NvTLttgh80ItG {
    min-height: 40vw;
  }
}

@media screen and (min-width: 800px) {
  .IDasYXM-NvTLttgh80ItG {
    min-height: 0;
    max-height: 70vh;
  }
}

.device-IE11 .Y1_3SleEfovEGFWPHrM_Z,
.device-Edge .Y1_3SleEfovEGFWPHrM_Z {
  position: relative;
  height: 100vw;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .device-IE11 .Y1_3SleEfovEGFWPHrM_Z,
  .device-Edge .Y1_3SleEfovEGFWPHrM_Z {
    height: 70vh;
  }
}

.device-IE11 .IDasYXM-NvTLttgh80ItG,
.device-Edge .IDasYXM-NvTLttgh80ItG {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: none;
  max-width: initial;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-height: 0;
}

@media screen and (min-width: 800px) {
  .device-IE11 .IDasYXM-NvTLttgh80ItG,
  .device-Edge .IDasYXM-NvTLttgh80ItG {
    max-height: none;
  }
}

._16EN3f2l7a6_sV1QJnssfw {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  background-color: #1c1b20;
  z-index: 2;
}

.Y1_3SleEfovEGFWPHrM_Z {
  background-color: #141315;
}
._2VNuKv8U8rNqXEdVUFTytZ {
  position: relative;
  z-index: 2;
}

._134L2u8TKSrXgZ7Tx72uq- {
  margin-top: -60px;
}

@media screen and (min-width: 1000px) {
  ._134L2u8TKSrXgZ7Tx72uq- {
    margin-top: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

._3EjtioLgWZE0SnGUwsbphC {
  padding: 60px 30px;
  background-color: #1c1b20;
  background-image: linear-gradient(#141315, #141315);
  background-size: 33% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}

@media screen and (min-width: 800px) {
  ._3EjtioLgWZE0SnGUwsbphC {
    padding: 0;
    background-color: transparent;
  }
}

._1gu1HZlxuLha1f679A8h6D {
  display: block;
}

@media screen and (min-width: 800px) {
  ._1gu1HZlxuLha1f679A8h6D {
    display: -webkit-flex;
    display: flex;
  }
}

@media screen and (min-width: 800px) {
  ._2UFM_WnKqdrnSsQtwe_2sD,
  .gF6WwZ8xsjPRQr0XYx6O1 {
    padding-top: 42px;
    padding-right: 42px;
    padding-bottom: 42px;
    padding-left: 42px;
  }
}

._2UFM_WnKqdrnSsQtwe_2sD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-grow: 2;
  flex-grow: 2;
}

@media screen and (min-width: 800px) {
  ._2UFM_WnKqdrnSsQtwe_2sD {
    -webkit-justify-content: center;
    justify-content: center;
    background-color: #141315;
  }
}

.gF6WwZ8xsjPRQr0XYx6O1 {
  margin-top: 42px;
}

@media screen and (min-width: 800px) {
  .gF6WwZ8xsjPRQr0XYx6O1 {
    margin-top: 0;
    padding-right: 60px;
    background-color: #1c1b20;
  }
}
@media screen and (max-width: 799px) {
  ._hvxW0VJ-Ah5RldhU98qW {
    padding-bottom: 10px;
  }
}

._hvxW0VJ-Ah5RldhU98qW ._1-eJx3s5FLnkUG29FyHQ4j {
  margin-top: 60px;
  margin-bottom: 60px;
}

.oKQFUty62Hy9HcbzTeeXh {
  margin-top: 30px;
  padding-bottom: 20px;
}

@media screen and (min-width: 800px) {
  .oKQFUty62Hy9HcbzTeeXh {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 799px) {
  ._1HXpHx_LHmT5FFoGycXqst {
    margin-bottom: 35px;
  }
}
.-D8lgGvs52nRwFHoQeP_q {
  margin-top: 15vh;
  margin-bottom: 15vh;
  background-color: #f5f5f5;
}

._37nEuWLl8uSrxeFbzTOMeA {
  background-color: #fff;
}

._2ntAgFFM8DaCku2woDv9j9 {
  text-align: center;
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 60px;
  padding-left: 25px;
}

._21Phk069nVJ9SM2A9f8zgf {
  padding-top: 1rem;
  padding-bottom: 3rem;
}
.RohoTP0TvQ7AYlfQc8lv5 {
  margin-top: 120px;
}

@media screen and (max-width: 799px) {
  .RohoTP0TvQ7AYlfQc8lv5 {
    display: none;
  }
}

._3bp0qEPe4XGmTDMKfXDd_J {
  display: -webkit-flex;
  display: flex;
  background-color: #f5f5f5;
}

._3GkwyJFBtlg-OofcGVLa7T {
  width: 35%;
  position: relative;
  max-height: 225px;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  ._3GkwyJFBtlg-OofcGVLa7T {
    width: 25%;
  }
}

._3GkwyJFBtlg-OofcGVLa7T:after {
  content: '';
  width: 100%;
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: linear-gradient(to top, rgba(20,19,21,0.7), rgba(20,19,21,0));
  background-size: cover;
}

._1mRpIfu0d398QkrxDlLGDJ {
  position: relative;
  padding-top: 110px;
}

.fDjBpVkiILbYXylTu8WU- {
  width: 100%;
  position: absolute;
  top: 65%;
  z-index: 3;
  text-align: center;
}

._2OUcdRgVe_kvpV0gXvSj8s {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._1lBm0WgkAnuSAmEdO4fAbQ {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9rem;
}

._2vQrYwCoxjrQXw80TCUvAA {
  width: 65%;
  padding: 10px 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 1200px) {
  ._2vQrYwCoxjrQXw80TCUvAA {
    width: 75%;
  }
}

._171blTDjdG6vY2VnoMxE2u {
  margin-bottom: 15px;
}
._30n_UDGG05r2b5I7oOYfcl {
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._30n_UDGG05r2b5I7oOYfcl {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 800px) {
  ._3rxFygZhE6tvVLNNdPtTBn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

._3awx6e2HazaqnjDBkH5BBX {
  width: 100%;
  position: relative;
  padding-top: 40px;
  padding-right: 35px;
  padding-bottom: 40px;
  padding-left: 35px;
  margin-top: 40px;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  ._3awx6e2HazaqnjDBkH5BBX {
    width: 32%;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 40px;
    margin-top: 40px;
  }
}

._2Q5oEw9lZu-nU4y4w9JJZ5 {
  margin-bottom: 5px;
  color: #141315;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._2DKQMezLGl75u5eUS2iZSe {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  ._2DKQMezLGl75u5eUS2iZSe {
    padding-top: 120px;
    padding-bottom: 0;
  }
}

._2DKQMezLGl75u5eUS2iZSe:before {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 4%;
  left: 0;
  background-color: #f5f5f5;
}

@media screen and (min-width: 1200px) {
  ._2DKQMezLGl75u5eUS2iZSe:before {
    bottom: 100px;
    left: 7%;
  }
}

@media screen and (max-width: 1199px) {
  ._2DKQMezLGl75u5eUS2iZSe:before {
    bottom: 0;
  }
}

@media screen and (min-width: 800px) {
  ._2SA_P6awZ-tp-YftcSsAEB {
    display: -webkit-flex;
    display: flex;
  }
}

.ouXPQwafYOww-zEIqvIT1 {
  padding-bottom: 30px;
}

@media screen and (min-width: 800px) {
  .ouXPQwafYOww-zEIqvIT1 {
    width: 38%;
    padding-bottom: 0;
  }
}

._3LngwzaR7Ok_C1Q1dnvYBL {
  padding-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._3LngwzaR7Ok_C1Q1dnvYBL {
    padding-bottom: 90px;
    text-align: right;
  }
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._1PWa3xID1b5219ITSLGJKs {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 800px) {
  ._1PWa3xID1b5219ITSLGJKs {
    width: 40%;
    margin-left: 8%;
  }
}
._3_pNTVaNeHKnyItET1YdI5 {
  top: 50px;
  z-index: 15;
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
}

@media screen and (min-width: 1000px) {
  ._3_pNTVaNeHKnyItET1YdI5 {
    top: 55px;
  }
}

._4NgrzgjPxceiQXbst7T90 {
  height: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (min-width: 800px) {
  ._4NgrzgjPxceiQXbst7T90 {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}

._4NgrzgjPxceiQXbst7T90 ._2oXXBIBYZg0GtUNmI9966- {
  margin-right: auto;
}

._1UaST23T2NVgkgHZaoKtdS {
  display: none;
}

@media screen and (min-width: 800px) {
  ._1UaST23T2NVgkgHZaoKtdS {
    display: initial;
    margin-right: 30px;
    margin-left: 30px;
  }
}

._19JWHKRo4WNYnnuc2-XCeb {
  overflow: hidden;
}

._3z3EUmdpM1bjRBygqY-VvW {
  position: relative;
  padding-top: 30px;
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._3z3EUmdpM1bjRBygqY-VvW {
    padding-top: 60px;
  }
}

@media screen and (min-width: 800px) {
  ._3z3EUmdpM1bjRBygqY-VvW ._2oXXBIBYZg0GtUNmI9966- {
    text-align: center;
    font-size: 6rem;
  }
}

._3z3EUmdpM1bjRBygqY-VvW .SqGYwc0FGai39PyTce85l {
  padding-top: 8px;
}

@media screen and (min-width: 800px) {
  ._3z3EUmdpM1bjRBygqY-VvW .SqGYwc0FGai39PyTce85l {
    text-align: center;
  }
}

._3YJxGzfIw6PjjJbztw4JFb {
  margin-top: 30px;
}

@media screen and (min-width: 800px) {
  ._3YJxGzfIw6PjjJbztw4JFb {
    width: 125% !important;
    margin-top: 60px;
  }
}

@media screen and (min-width: 1200px) {
  ._3YJxGzfIw6PjjJbztw4JFb {
    width: calc(100% + ((100vw - 100%) * 0.5)) !important;
  }
}

._2z1-AGaXlN9xpm0LmPjMCM {
  margin-top: 5px;
}

@media screen and (max-width: 799px) {
  ._2z1-AGaXlN9xpm0LmPjMCM {
    font-size: 0.8em;
  }
}

._1mu3OSwrxcoaIti2xUnEaF {
  padding-top: 25px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media screen and (min-width: 800px) {
  ._1mu3OSwrxcoaIti2xUnEaF {
    padding-top: 120px;
    min-height: 550px;
  }
}

@media screen and (min-width: 800px) {
  ._2_7Nqklh9gMrJBYx020rVx {
    width: 50%;
  }
}

.z2qWW3KmArWUub5FejB_K {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  padding-left: 40px;
  color: #141315;
  font-size: 2.2rem;
  line-height: 1.1em;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  font-style: italic;
}

@media screen and (min-width: 800px) {
  .z2qWW3KmArWUub5FejB_K {
    margin-bottom: 60px;
    font-size: 3.2rem;
  }
}

.z2qWW3KmArWUub5FejB_K:before {
  content: '';
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  background-color: #2fd0b7;
}

._2PUCgwyTBHqJG-YgMDUBqF {
  color: #141315;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._229SW_IWVQ1brwFyjRYjN7 {
  display: none;
}

@media screen and (min-width: 800px) {
  ._229SW_IWVQ1brwFyjRYjN7 {
    width: 32%;
    position: absolute;
    right: 0;
    top: -100px;
    display: unset;
  }
}
.JLl1O0NM0WUvReeGe4RQe {
  padding-top: 20px;
  padding-bottom: 50px;
}

@media screen and (min-width: 800px) {
  .JLl1O0NM0WUvReeGe4RQe {
    padding-top: 120px;
    padding-bottom: 75px;
  }
}

@media screen and (min-width: 800px) {
  ._1ggyTBvM0qahdK5knv2CIL {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

._2IoRNAavtqttrVnuwFTQ0c {
  width: 100%;
  position: relative;
  padding-top: 60px;
  padding-right: 35px;
  padding-bottom: 40px;
  padding-left: 35px;
  margin-top: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  ._2IoRNAavtqttrVnuwFTQ0c {
    width: 32%;
    padding-top: 70px;
    padding-bottom: 60px;
    margin-top: 80px;
  }
}

._3Hxrc8Qa_WlLS_3xQIuDNf {
  position: absolute;
  top: -15px;
  left: 60px;
  overflow: unset;
}

._3Hxrc8Qa_WlLS_3xQIuDNf:before {
  content: '';
  width: 70px;
  height: 70px;
  position: absolute;
  top: -20px;
  left: -21px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

._1ph5xKPetq6n02lB3O6Fu2 {
  margin-bottom: 5px;
  color: #141315;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._3lROnfj37E4GoWzviAkcFz {
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  ._3lROnfj37E4GoWzviAkcFz {
    margin-bottom: 40px;
  }
}

._3-1COr_ciymVuoxoM36AYs {
  margin-bottom: -60px;
}
._12nkvwhMkJ7b_P2fQophBD {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

@media screen and (max-width: 799px) {
  ._12nkvwhMkJ7b_P2fQophBD:not(:last-child) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 800px) {
  ._12nkvwhMkJ7b_P2fQophBD {
    -webkit-flex-basis: calc(33% - 10px);
    flex-basis: calc(33% - 10px);
  }
}

@media screen and (min-width: 1200px) {
  ._12nkvwhMkJ7b_P2fQophBD {
    -webkit-flex-basis: calc(33% - 40px);
    flex-basis: calc(33% - 40px);
  }
}

._2BcFWXRJa1xJzTbrp_FlNM {
  position: relative;
  overflow: hidden;
  height: 85vw;
}

@media screen and (min-width: 800px) {
  ._2BcFWXRJa1xJzTbrp_FlNM {
    height: 270px;
  }
}

@media screen and (min-width: 1200px) {
  ._2BcFWXRJa1xJzTbrp_FlNM {
    height: 300px;
  }
}

._13GT-jbIs1L7AZagXEUbqm {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._3kO4OHo13WGPM26TnYZW2l {
  min-height: 42vw;
  padding-top: 25px;
  padding-right: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  background-color: #f5f5f5;
}

@media screen and (min-width: 700px) {
  ._3kO4OHo13WGPM26TnYZW2l {
    min-height: 260px;
  }
}

._2-V-ve4exLE94XbMD1PgGB {
  width: 100%;
  -webkit-flex-grow: 2;
  flex-grow: 2;
}

.gSiK_MhKBD51THhkqRUMR {
  margin-bottom: 10px;
}

.aYAOwftkF-m3wrGfBAtgx {
  font-size: 26px;
  padding-top: 4px;
}
._2CKvzevWbl-PJ_FyuUYOmu {
  position: relative;
  padding-top: 60px;
  margin-top: 60px;
  margin-bottom: -60px;
}

@media screen and (min-width: 800px) {
  ._2CKvzevWbl-PJ_FyuUYOmu {
    padding-top: 60px;
    margin-top: 150px;
    margin-bottom: 60px;
  }
}

._2CKvzevWbl-PJ_FyuUYOmu:before {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 800px) {
  ._2CKvzevWbl-PJ_FyuUYOmu:before {
    width: 77%;
    bottom: 400px;
    background-color: #f5f5f5;
  }
}

@media screen and (min-width: 1500px) {
  ._2CKvzevWbl-PJ_FyuUYOmu:before {
    width: 70%;
    bottom: 400px;
  }
}

._1nlAnhLJpggWuPEDJ8_UGj {
  max-width: 700px;
}

@media screen and (min-width: 800px) {
  ._1nlAnhLJpggWuPEDJ8_UGj {
    width: 80%;
  }
}

.s-UFhUPdS830b1D4tghH {
  margin-bottom: 30px;
}

._1xRq1uZtliU9G-7JUqDUA4 {
  margin-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._1xRq1uZtliU9G-7JUqDUA4 {
    margin-bottom: 60px;
  }
}

._3xab167CGhdjEEZNvtmz3x {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.et5pVliRHHPkbjkdj69GN {
  padding: 2rem 0;
}

.et5pVliRHHPkbjkdj69GN>span {
  margin-left: 0;
}

.et5pVliRHHPkbjkdj69GN>svg {
  width: 26px;
}

@media screen and (min-width: 1500px) {
  .et5pVliRHHPkbjkdj69GN {
    position: absolute;
    left: calc((100vw - 1384px) / 2 - 50px);
  }
}

@media screen and (min-width: 1700px) {
  .et5pVliRHHPkbjkdj69GN {
    position: absolute;
    left: calc((100vw - 1384px) / 2 - 150px);
  }
}

.foP3uV7mNJSSI-i0Vgfrs {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1500px) {
  .foP3uV7mNJSSI-i0Vgfrs {
    padding-left: 100px;
  }
}

.foP3uV7mNJSSI-i0Vgfrs small {
  text-transform: uppercase;
  color: #adadad;
  font-size: 14px;
}

.foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB {
  overflow: hidden;
  width: 100%;
}

.foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB._2FeCPxdDuQBKw00An7Ted7 {
  height: 250px;
}

.foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB._3dumMw_eSzjkGSN_UrnUgO {
  display: none;
}

@media screen and (min-width: 550px) {
  .foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB._2FeCPxdDuQBKw00An7Ted7 {
    height: 500px;
  }

  .foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB._3dumMw_eSzjkGSN_UrnUgO {
    width: 50%;
    height: 100%;
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB._3dumMw_eSzjkGSN_UrnUgO {
    height: 100%;
    width: 55%;
  }
}

@media screen and (min-width: 1500px) {
  .foP3uV7mNJSSI-i0Vgfrs ._1_KbMVd91Wmc-bfNAqjxSB._2FeCPxdDuQBKw00An7Ted7 {
    width: calc(100vw - (100vw - 1384px) / 2 - 100px);
  }
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding: 4rem 3rem;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G small {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G .Puo0pK0xemY1ZwCqwltVF {
  display: block;
  font-size: 25px;
  color: #2fd0b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .3s ease;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G .Puo0pK0xemY1ZwCqwltVF:hover {
  color: #16a58e;
  transition: color .3s ease;
}

@media screen and (max-width: 699px) {
  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G .Puo0pK0xemY1ZwCqwltVF {
    font-size: 22px;
  }
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._1SBdJ_RSDlESCZ5bolhZL0 {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._1SBdJ_RSDlESCZ5bolhZL0:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

@media screen and (min-width: 550px) {
  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB {
    height: 300px;
  }

  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G {
    padding: 0;
    width: 50%;
    -webkit-align-items: center;
    align-items: center;
  }

  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G h5 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1000px) {
  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB {
    width: 700px;
    position: relative;
    top: -60px;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    right: 60px;
  }

  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB ._16Rb0LVkd0z80f1yAG-55G {
    width: 45%;
  }
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1o8_O44xZ0v1kcAEKj6Sma {
  padding: 5rem 0 0;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1o8_O44xZ0v1kcAEKj6Sma ._1jPUdcsUgL27A4VCvnrG9C {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
}

.foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1o8_O44xZ0v1kcAEKj6Sma ._1jPUdcsUgL27A4VCvnrG9C small:first-of-type {
  margin-right: 60px;
}

@media screen and (min-width: 550px) {
  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1o8_O44xZ0v1kcAEKj6Sma {
    padding: 2rem 0;
  }
}

@media screen and (min-width: 1500px) {
  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r {
    -webkit-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1n66_LQfxYKuPHlaotEGMB {
    right: unset;
  }

  .foP3uV7mNJSSI-i0Vgfrs .vBdjlb0kRUr5ota_9fN9r ._1o8_O44xZ0v1kcAEKj6Sma {
    -webkit-align-self: flex-end;
    align-self: flex-end;
    padding-bottom: 60px;
  }
}

.foP3uV7mNJSSI-i0Vgfrs ._2hbMsi-I9KPd_YluRW4vhp {
  padding-top: 60px;
}

.foP3uV7mNJSSI-i0Vgfrs ._2hbMsi-I9KPd_YluRW4vhp ._3P_PqCFRsHcmjv-FPfJNDU {
  padding-bottom: 1rem;
}

.foP3uV7mNJSSI-i0Vgfrs ._2hbMsi-I9KPd_YluRW4vhp p {
  color: #1c1b20;
}

@media screen and (min-width: 550px) {
  .foP3uV7mNJSSI-i0Vgfrs ._2hbMsi-I9KPd_YluRW4vhp ._3P_PqCFRsHcmjv-FPfJNDU {
    padding-bottom: 4rem;
  }
}

@media screen and (min-width: 1500px) {
  .foP3uV7mNJSSI-i0Vgfrs ._2hbMsi-I9KPd_YluRW4vhp {
    padding-top: 120px;
    width: 60%;
  }
}
.GBjsh671DgeZ5pjKGWKsV {
  background-color: #f5f5f5;
  padding-top: 20px;
}

@media screen and (min-width: 700px) {
  .GBjsh671DgeZ5pjKGWKsV {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1500px) {
  .GBjsh671DgeZ5pjKGWKsV {
    background-color: #fff;
    padding-top: 0;
  }
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding-bottom: 60px;
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._3j7PwXXJ9SJkEQ4-tBQgho {
  height: 400px;
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._3j7PwXXJ9SJkEQ4-tBQgho ._2HhrN2ESp7EOHitr5uaHo1 {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  position: relative;
  width: 64px;
  height: 64px;
  display: block;
  bottom: calc(50% + 64px);
  left: calc(50% - 32px);
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._3j7PwXXJ9SJkEQ4-tBQgho ._2HhrN2ESp7EOHitr5uaHo1:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._3j7PwXXJ9SJkEQ4-tBQgho ._2WaHbtRSjnw05hltM8WKIb {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._2QxTmKPrQLTdgm2Z4dtCUx {
  background-color: #f5f5f5;
  padding: 2rem 0;
}

.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._2QxTmKPrQLTdgm2Z4dtCUx ._1virs-1mwYuBPf2Au98ZNJ,
.GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._2QxTmKPrQLTdgm2Z4dtCUx ._1avQzcyQX0VmcP6sMs9cbs {
  padding: 2rem 0;
}

@media screen and (min-width: 1500px) {
  .GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._2QxTmKPrQLTdgm2Z4dtCUx {
    padding: 4rem;
  }
}

@media screen and (min-width: 1500px) {
  .GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX {
    -webkit-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
    position: relative;
    padding-bottom: 180px;
    margin-bottom: 180px;
  }

  .GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._3j7PwXXJ9SJkEQ4-tBQgho {
    width: 70%;
    height: 600px;
  }

  .GBjsh671DgeZ5pjKGWKsV ._3kGaSk-uqJMIKA4Q3i2TWX ._2QxTmKPrQLTdgm2Z4dtCUx {
    padding: 10rem;
    padding-left: calc((100vw - 1400px) / 2);
    width: calc(50% + (100vw - 1400px) / 2);
    position: absolute;
    bottom: 0;
    left: calc((100vw - 1400px) / -2);
  }
}
._3ya9A7p9YGo5tCzKNeH9L0 {
  padding: 60px 0 60px 0;
}

@media screen and (min-width: 1000px) {
  ._3ya9A7p9YGo5tCzKNeH9L0 {
    padding-top: 120px;
  }
}

.svKPnu-WGs57nc0sI8aGC {
  padding-bottom: 180px;
}

@media screen and (max-width: 399px) {
  .svKPnu-WGs57nc0sI8aGC {
    padding-bottom: 120px;
  }
}

._2aQi74HcU8oJvrMOQX5slt {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

._2aQi74HcU8oJvrMOQX5slt .swiper-wrapper {
  display: -webkit-flex;
  display: flex;
}

._2aQi74HcU8oJvrMOQX5slt .kgoeXNZ14UP0oLDhcB5lH {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 60px;
}

._2aQi74HcU8oJvrMOQX5slt .kgoeXNZ14UP0oLDhcB5lH>svg {
  cursor: pointer;
}

._2aQi74HcU8oJvrMOQX5slt .kgoeXNZ14UP0oLDhcB5lH .button__next,
._2aQi74HcU8oJvrMOQX5slt .kgoeXNZ14UP0oLDhcB5lH .button__prev {
  stroke: #2fd0b7;
  stroke-width: 6px;
  width: 60px;
  transition: opacity 0.3s ease;
}

._2aQi74HcU8oJvrMOQX5slt .kgoeXNZ14UP0oLDhcB5lH .button__prev {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

._2aQi74HcU8oJvrMOQX5slt .kgoeXNZ14UP0oLDhcB5lH .swiper-button-disabled {
  opacity: 0;
}

._2aQi74HcU8oJvrMOQX5slt ._3do-zbKA7CtN_VAgXz74qQ {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-align-self: center;
  align-self: center;
}

._2aQi74HcU8oJvrMOQX5slt ._3do-zbKA7CtN_VAgXz74qQ>a>div:last-of-type {
  min-height: 280px;
}

._2aQi74HcU8oJvrMOQX5slt ._3do-zbKA7CtN_VAgXz74qQ:last-child {
  margin-right: 0;
}
@media screen and (max-width: 699px) {
  ._2m4V0Ym19alkW78N6giH7L {
    background-color: #f5f5f5;
  }
}

._2m4V0Ym19alkW78N6giH7L._3RufmT_0-plK6BZvH3TvKD {
  background-color: #f5f5f5;
}

._2U4vRaOeVExIZPXzS3eqwA {
  position: relative;
  padding-bottom: 60px;
}

._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a {
  background-color: #f5f5f5;
  padding: 4rem 0;
}

._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a ._1w_Dc1nThO_T_Cm9XDeTQf {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  margin: 1rem 0 4rem 0;
}

._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a p {
  color: #1c1b20;
  margin-bottom: 4rem;
}

@media screen and (min-width: 700px) {
  ._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a {
    padding: 4rem;
  }
}

@media screen and (min-width: 1000px) {
  ._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a p {
    width: calc(200% / 3);
  }
}

@media screen and (min-width: 1500px) {
  ._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a {
    padding: 90px 90px 120px calc((100vw - 1400px) / 2);
    width: 100%;
    position: absolute;
    left: calc((100vw - 1400px) / -2);
  }
}

@media screen and (min-width: 1900px) {
  ._2U4vRaOeVExIZPXzS3eqwA ._1d9tflDpOHxvOQDhd7eF7a {
    width: 120%;
  }
}

._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a {
  margin-bottom: 6rem;
}

@media screen and (min-width: 1000px) {
  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS {
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a {
    -webkit-flex-basis: calc(50% - 3rem);
    flex-basis: calc(50% - 3rem);
  }

  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a:nth-child(odd) {
    margin-right: 6rem;
  }
}

@media screen and (min-width: 1500px) {
  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS {
    padding-top: 330px;
  }

  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a {
    -webkit-flex-basis: calc((100% / 3) - 4rem);
    flex-basis: calc((100% / 3) - 4rem);
  }

  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a:nth-child(odd) {
    margin-right: 0;
  }

  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a:first-child {
    margin-left: calc(100% / 3 + 2rem);
    margin-right: 6rem;
  }

  ._2U4vRaOeVExIZPXzS3eqwA ._1H8XSCwtRb9hGkqOgaxPvS>a:nth-child(3n+4) {
    margin: 0 6rem 6rem 6rem;
  }
}
._1affweDx3BNKvb_qGFkkNm {
  background-color: #f5f5f5;
  padding-top: 4rem;
}

._1affweDx3BNKvb_qGFkkNm.VbUeoaMxTrbB35Da-aU_A {
  background-color: #fff;
}

@media screen and (min-width: 1500px) {
  ._1affweDx3BNKvb_qGFkkNm {
    padding-top: 180px;
  }
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH {
  padding-bottom: 60px;
}

@media screen and (min-width: 1500px) {
  ._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH {
    padding-bottom: 90px;
  }
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH ._23hpxpl0Pdr9SvTNEYeq-0 {
  padding: 3rem 0 60px 0;
}

@media screen and (min-width: 1000px) {
  ._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH ._23hpxpl0Pdr9SvTNEYeq-0 {
    width: 50%;
  }
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx .swiper-wrapper {
  display: -webkit-flex;
  display: flex;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx ._10IwQRc2qoRWE201X8Opu- {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 60px;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx ._10IwQRc2qoRWE201X8Opu->svg {
  cursor: pointer;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx ._10IwQRc2qoRWE201X8Opu- .button__next,
._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx ._10IwQRc2qoRWE201X8Opu- .button__prev {
  stroke: #2fd0b7;
  stroke-width: 6px;
  width: 60px;
  transition: opacity 0.3s ease;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx ._10IwQRc2qoRWE201X8Opu- .button__prev {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx ._10IwQRc2qoRWE201X8Opu- .swiper-button-disabled {
  opacity: 0;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx .XRiDM_vTqXw9FRI725uUv {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-align-self: center;
  align-self: center;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx .XRiDM_vTqXw9FRI725uUv:last-child {
  margin-right: 0;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx .XRiDM_vTqXw9FRI725uUv._3l_TidHwaat4qLX39BqN3F {
  height: 100%;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx .XRiDM_vTqXw9FRI725uUv._8S9mfHS069ZXg-URlzxdl {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
  align-content: center;
}

._1affweDx3BNKvb_qGFkkNm ._1smyesK5SDW6lfrm9HgzfH .x0dsd1gkywH6cPZRg8wx .XRiDM_vTqXw9FRI725uUv._8S9mfHS069ZXg-URlzxdl>div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
  align-content: center;
}
._1WXhTaYrw6tqrxwJ-eeRia {
  position: relative;
}

._1WXhTaYrw6tqrxwJ-eeRia ._2KBeMeqPAc9t7MBNfx9GYo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding: 4rem;
  z-index: 1;
  background-color: rgba(20,19,21,0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: hidden;
}

._1WXhTaYrw6tqrxwJ-eeRia ._2KBeMeqPAc9t7MBNfx9GYo:hover {
  opacity: 1;
}

._1WXhTaYrw6tqrxwJ-eeRia ._2KBeMeqPAc9t7MBNfx9GYo h5,
._1WXhTaYrw6tqrxwJ-eeRia ._2KBeMeqPAc9t7MBNfx9GYo p {
  font-size: 2rem;
}

._1WXhTaYrw6tqrxwJ-eeRia ._2KBeMeqPAc9t7MBNfx9GYo p {
  color: #fff;
  padding: 1rem 0;
}

@media screen and (max-width: 999px) {
  ._1WXhTaYrw6tqrxwJ-eeRia ._2KBeMeqPAc9t7MBNfx9GYo {
    display: none;
  }
}
.o3NlrAnpOy1nSfjLnwczs {
  padding-top: 60px;
  margin-bottom: -120px;
}

._1xJu755JsEMOGCjQo84cZD {
  position: relative;
  padding-bottom: 180px;
}

@media screen and (min-width: 800px) {
  ._1xJu755JsEMOGCjQo84cZD {
    padding-bottom: 300px;
  }
}

._1xJu755JsEMOGCjQo84cZD:before {
  content: '';
  width: 100vw;
  height: 100%;
  position: absolute;
  right: -20px;
  top: -60px;
  z-index: 1;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  ._1xJu755JsEMOGCjQo84cZD:before {
    height: 100%;
    top: -150px;
    right: 10%;
  }
}

._8SeCcTtNGyJCO5H3JI5A {
  position: relative;
  margin-top: 60px;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (min-width: 800px) {
  ._8SeCcTtNGyJCO5H3JI5A {
    margin-top: 90px;
  }
}

._3pUKBv2x0UsctKJlOeHbyS {
  width: 100%;
  margin-top: 20px;
}

@media screen and (min-width: 550px) {
  ._3pUKBv2x0UsctKJlOeHbyS {
    width: calc(33.33% - 20px);
  }
}

@media screen and (min-width: 1000px) {
  ._3pUKBv2x0UsctKJlOeHbyS {
    width: calc(33.33% - 40px);
  }
}

._182ns-xWUGf44_B6Gg9TMb {
  position: relative;
  margin-top: 60px;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  ._182ns-xWUGf44_B6Gg9TMb {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1000px) {
  ._182ns-xWUGf44_B6Gg9TMb {
    margin-top: 180px;
    display: -webkit-flex;
    display: flex;
  }
}

@media screen and (min-width: 1000px) {
  .BL2sHnbgGQFnGkJZm8CL6 {
    width: 20%;
    margin-top: -0.3em;
  }
}

._1TyKTJOd5fz8S-GKFweqdc {
  margin-top: 30px;
  padding-left: 0;
  list-style-type: none;
}

@media screen and (min-width: 550px) {
  ._1TyKTJOd5fz8S-GKFweqdc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (min-width: 800px) {
  ._1TyKTJOd5fz8S-GKFweqdc {
    width: 80%;
  }
}

@media screen and (min-width: 1000px) {
  ._1TyKTJOd5fz8S-GKFweqdc {
    width: 50%;
    margin-top: 0;
  }
}

._2uY9wwp4WwLf9VnP7YmTWL {
  display: block;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px;
  padding-right: 30px;
  list-style-type: none;
  font-size: 1.6rem;
  color: #141315;
  border-bottom: 1px solid rgba(20,19,21,0.1);
  transition: color ease 0.25s, border ease 0.25s;
}

@media screen and (min-width: 550px) {
  ._2uY9wwp4WwLf9VnP7YmTWL {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 800px) {
  ._2uY9wwp4WwLf9VnP7YmTWL {
    width: calc(50% - 30px);
    margin-right: 30px;
  }
}

._2uY9wwp4WwLf9VnP7YmTWL ._1GfmY5rsy6-jzPNUxcME9L {
  width: 35px;
  position: absolute;
  top: 6px;
  right: 10px;
  fill: transparent;
  stroke: rgba(20,19,21,0.2);
  stroke-width: 4px;
  transition: stroke ease 0.25s;
}

._2uY9wwp4WwLf9VnP7YmTWL:hover {
  color: #2fd0b7;
  border-bottom-color: rgba(47,208,183,0.5);
}

._2uY9wwp4WwLf9VnP7YmTWL:hover ._1GfmY5rsy6-jzPNUxcME9L {
  stroke: #2fd0b7;
}
._201I4gDXuepRAc9tIXX9HH {
  padding-top: 15px;
  text-align: center;
}

._1r0WTsG3O2NmCW15fChO5t {
  padding-top: 30px;
  padding-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._1r0WTsG3O2NmCW15fChO5t {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

._37bTF9-LvzS3l0_e-OKAAa {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  display: inline-block;
}

@media screen and (min-width: 800px) {
  ._37bTF9-LvzS3l0_e-OKAAa {
    width: 29%;
    margin-right: 2%;
    margin-left: 2%;
  }
}

._1Wi9W2F-zd840_c_ajup66 {
  margin: 0 auto;
  margin-bottom: 15px;
}

._21qgyQum5W9GdRxqGFAa_0 {
  text-align: center;
}

._3lIE3qoORh02bKnuYe3p1P {
  text-align: center;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}
.bq3Ag4Xs9j1BQUWFDmNNp {
  margin-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  .bq3Ag4Xs9j1BQUWFDmNNp {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1000px) {
  ._1Hv37mbs0ArAgTp_nVH4ug {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}

@media screen and (min-width: 1000px) {
  ._1Hv37mbs0ArAgTp_nVH4ug>* {
    width: 60%;
  }
}

._2_l_4FkkgGuVixFypoxtkF {
  position: relative;
  z-index: 3;
  padding-left: 0;
}

@media screen and (min-width: 1000px) {
  ._2_l_4FkkgGuVixFypoxtkF {
    margin-bottom: -60px;
  }
}

._1ZGyN-FgHCKt19rq0s4WfD {
  position: relative;
}

@media screen and (min-width: 1000px) {
  ._1ZGyN-FgHCKt19rq0s4WfD {
    width: 40%;
  }
}

._22KNKWFzTtCfbUAyV3T8hL {
  width: 100%;
  min-height: 40vw;
}

@media screen and (min-width: 1000px) {
  ._22KNKWFzTtCfbUAyV3T8hL {
    width: 200%;
    min-height: 500px;
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
  }
}
._1C5iYM5brUuXppg_sxc63w {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (min-width: 800px) {
  ._1C5iYM5brUuXppg_sxc63w {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

._3fbjkFtEHEC6ye5QgD3_sK,
._2KmQU6cNqofQN-DA268BrK {
  max-width: 650px;
}

._2KmQU6cNqofQN-DA268BrK {
  margin-top: 10px;
}
.hNlYx4aDRLI9BrQ5stx_b {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  .hNlYx4aDRLI9BrQ5stx_b {
    width: 100%;
    margin-top: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}

._1eYCNJFhrs_XGTmQwZ7VhN {
  position: absolute;
  top: 70px;
  left: 40px;
}

._2GPGAJoMIsHdIzXuULiD9_ {
  width: 100%;
}

._2GPGAJoMIsHdIzXuULiD9_:not(:only-child) {
  margin-bottom: 60px;
}

.FmWgc9se7-Bi4GQ2Jf2pS:not(:first-child) {
  margin-top: 15px;
}

@media screen and (min-width: 800px) {
  ._16Aq5D2Q4J9Ah42RAgaq8I {
    width: 55%;
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1200px) {
  ._16Aq5D2Q4J9Ah42RAgaq8I {
    width: 60%;
  }
}

._2Gfz6NyTIoyHGu-SRmrxPc {
  width: 40%;
  margin-left: auto;
}

@media screen and (max-width: 799px) {
  ._2Gfz6NyTIoyHGu-SRmrxPc {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  ._2Gfz6NyTIoyHGu-SRmrxPc {
    margin-left: auto;
    width: 30%;
  }
}

.rH2YSzxEl_Fw18LQ8Rmc5:not(:first-child) {
  margin-top: 60px;
}

@media screen and (min-width: 800px) {
  .rH2YSzxEl_Fw18LQ8Rmc5:not(:first-child) {
    margin-top: 90px;
  }
}

.rH2YSzxEl_Fw18LQ8Rmc5:not(:last-child) {
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(28,27,32,0.1);
}

@media screen and (min-width: 800px) {
  .rH2YSzxEl_Fw18LQ8Rmc5:not(:last-child) {
    padding-bottom: 90px;
  }
}

._1YL7Zl41GM83BRV7ut-3VK:not(:first-child) {
  margin-top: 30px;
}
._3fMQ_cpeubLWVhUY9o-2Hp {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

._1i2lz_g-kRETHFUif1H5XD {
  min-width: 100%;
  height: 200px;
}

@media screen and (min-width: 800px) {
  ._1i2lz_g-kRETHFUif1H5XD {
    min-width: 130%;
    height: 320px;
  }
}
._2jbsOWpTdqRPYwVhVRlbRA {
  padding-top: 30px;
}

@media screen and (min-width: 1000px) {
  ._2jbsOWpTdqRPYwVhVRlbRA {
    padding-top: 90px;
  }
}

._2Da-hJJlPiDntlTrwscirE {
  padding-top: 30px;
  padding-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  ._2Da-hJJlPiDntlTrwscirE {
    padding-top: 90px;
    padding-bottom: 150px;
  }
}

._3UevkDwoemMxyW180XGQOW {
  max-width: 1250px;
}

._1jNpKnrWBsphWzPyDe85Ft {
  padding-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  ._1jNpKnrWBsphWzPyDe85Ft {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.hyIQH8xCz8OY8ksUWT71A {
  margin-top: 30px;
  padding-left: 0;
  list-style-type: none;
}

@media screen and (min-width: 550px) {
  .hyIQH8xCz8OY8ksUWT71A {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .hyIQH8xCz8OY8ksUWT71A {
    -webkit-justify-content: unset;
    justify-content: unset;
  }
}
._2JVxB0DmLOdwZFQaBk9iOF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,0.04);
}

._2JVxB0DmLOdwZFQaBk9iOF::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  background: #16a58e;
  content: ' ';
  transition: width 0.2s ease-in-out;
}

._2JVxB0DmLOdwZFQaBk9iOF:hover::after {
  width: 100%;
}

._2JVxB0DmLOdwZFQaBk9iOF:hover ._3Xm7AyPouoDXzqdngloB8l,
._2JVxB0DmLOdwZFQaBk9iOF:hover .Q9JA93Y_V4EpqvfzDrq6N {
  color: #16a58e;
}

.K-8MMCNQX3CGIeNCP-638 {
  margin-right: 40px;
  margin-top: 35px;
  margin-bottom: 30px;
}

@media screen and (max-width: 549px) {
  .K-8MMCNQX3CGIeNCP-638 {
    margin-right: 20px;
  }
}

._2-8CMZ1Bych7X8L6CX03a5 {
  display: block;
  width: 90px;
  height: 90px;
  overflow: hidden;
}

@media screen and (max-width: 549px) {
  ._2-8CMZ1Bych7X8L6CX03a5 {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 399px) {
  ._2-8CMZ1Bych7X8L6CX03a5 {
    display: none;
  }
}

._3fzd6sflIRKCAohhcVSAmV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  margin: 1vw 0vw;
  margin-right: 1vw;
  width: 34vw;
  position: relative;
}

@media screen and (max-width: 999px) {
  ._3fzd6sflIRKCAohhcVSAmV {
    width: 100%;
    margin-right: 0;
  }
}

.Q9JA93Y_V4EpqvfzDrq6N {
  width: 105%;
  font-size: 26px;
  line-height: 1.38;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 17px;
  padding-right: 25px;
  color: #141315;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.2s ease-in-out;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .Q9JA93Y_V4EpqvfzDrq6N {
    font-size: 22px;
    width: 100%;
  }
}

@media screen and (max-width: 999px) {
  .Q9JA93Y_V4EpqvfzDrq6N {
    padding-right: 0;
  }
}

@media screen and (max-width: 699px) {
  .Q9JA93Y_V4EpqvfzDrq6N {
    width: 100%;
    font-size: 22px;
  }
}

._1a-olqF4Z2qF8qM3Ueds3Q {
  min-height: 122px;
}

@media screen and (max-width: 999px) {
  ._1a-olqF4Z2qF8qM3Ueds3Q {
    min-height: auto;
  }
}

._3Xm7AyPouoDXzqdngloB8l {
  font-size: 14px;
  color: #2fd0b7;
  max-height: 80px;
  overflow: hidden;
  margin-bottom: 14px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  letter-spacing: 1.4px;
  text-align: left;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  ._3Xm7AyPouoDXzqdngloB8l {
    font-size: 12px;
  }
}
._28nMbVasLP_ZfcQpH-GHtM {
  background: #f5f5f5;
  margin-top: 30px;
}

._2n2SOpX8gM6CiwAMVF7ZjM {
  width: 100%;
  padding: 50px 0;
}

@media screen and (max-width: 999px) {
  ._2n2SOpX8gM6CiwAMVF7ZjM {
    padding: 20px 0;
  }
}

.I6cFR9MaUg1ZbCwki5_lK {
  width: 66%;
}

@media screen and (max-width: 999px) {
  .I6cFR9MaUg1ZbCwki5_lK {
    width: 100%;
    margin-bottom: 30px;
  }
}

._2Ue7h-F0b8P2fLotPy1Xqu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

._3DU3jBjqVagK7sLhSOEcx7 {
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.98px;
  margin: 0 0 20px 0;
  transition: color 0.2s ease-in-out;
}

._3DU3jBjqVagK7sLhSOEcx7:hover {
  color: #16a58e;
}

._3DU3jBjqVagK7sLhSOEcx7:hover ._2s65dPPOC1MAPj8lyJ1RIn {
  stroke: #16a58e;
}

.AZZ7vpHol5LfZqJ0u2zhx {
  font-size: 16px;
  display: inline-block;
}

@media screen and (max-width: 999px) {
  .AZZ7vpHol5LfZqJ0u2zhx {
    display: none;
  }
}

._1yaCsq-gOJCm4_VJ1kMRux {
  font-size: 20px;
  margin-top: 40px;
  display: none;
}

@media screen and (max-width: 999px) {
  ._1yaCsq-gOJCm4_VJ1kMRux {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

._2s65dPPOC1MAPj8lyJ1RIn {
  width: 28px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  fill: transparent;
  stroke-width: 6px;
  margin-left: 10px;
  pointer-events: none;
  stroke: #2fd0b7;
  transition: stroke 0.2s ease-in-out;
}

._2COj7zj5CH_xLFY7FMQD-R {
  background: #fff;
  width: 100%;
  padding: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: row;
  flex-direction: row;
}

@media screen and (max-width: 999px) {
  ._2COj7zj5CH_xLFY7FMQD-R {
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }
}

._2wDX7e63APs9jcyKROoelX {
  padding: 50px;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  ._2wDX7e63APs9jcyKROoelX {
    padding: 16.66667px;
  }
}

@media screen and (max-width: 549px) {
  ._2wDX7e63APs9jcyKROoelX {
    padding: 13.63636px;
  }
}

._3sSq8o5ZPkOMFJ-641gM9Q {
  width: 30vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media screen and (max-width: 999px) {
  ._3sSq8o5ZPkOMFJ-641gM9Q {
    display: none;
  }
}

.Lb_99k-7rlAmwFRFgoImT {
  padding-left: 60px;
  width: 35.5vw;
}

@media screen and (max-width: 999px) {
  .Lb_99k-7rlAmwFRFgoImT {
    width: 100%;
    padding-left: 0;
  }
}

.TRHx2UeLFh8l3SJlaU8Mf {
  padding-left: 0;
}

.E5VRvvYvTVnZqgLFJQjjz {
  width: 100%;
}
._7PSjdeQayT9jIc70vikob {
  margin: 0 auto 50px auto;
}

@media screen and (max-width: 699px) {
  ._7PSjdeQayT9jIc70vikob {
    margin-bottom: 30px;
  }
}

.tpUpy13uo5QDk5AHtl02v {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

._3ONwJ5KRUEjZ0mJ9TUvnux {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  position: relative;
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  padding: 10px 15px;
  margin: 0 10px 20px 0;
  background: #efefef;
  color: #626262;
}

@media screen and (max-width: 399px) {
  ._3ONwJ5KRUEjZ0mJ9TUvnux {
    font-size: 18px;
  }
}

._3ONwJ5KRUEjZ0mJ9TUvnux:hover {
  background: transparent linear-gradient(67deg, #47dba4 0%, #46d1be 100%) 0% 0% no-repeat padding-box;
  color: #fff !important;
}

.loRg3UryOxHipCjLCI1Lw {
  background: transparent linear-gradient(67deg, #47dba4 0%, #46d1be 100%) 0% 0% no-repeat padding-box;
  color: #fff !important;
}

._2w6Scq34n23Hqm0NLd2zGU {
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 10px;
  font-size: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

._1-da-PXFd8hYKPoIfzvOOe {
  display: block;
}

@media screen and (max-width: 699px) {
  ._1-da-PXFd8hYKPoIfzvOOe {
    display: none;
  }
}

.bpiRNBmwgglXOF-80XeKm {
  display: none;
}

@media screen and (max-width: 699px) {
  .bpiRNBmwgglXOF-80XeKm {
    display: block;
  }
}

.rh1whP0MJcLzEj-XJ5Q4f {
  width: 15px;
  height: 15px;
  margin: -2px 10px 0 5px;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  fill: #2fd0b7;
  stroke: #2fd0b7;
}
._2lbPi8zhV6k-XO9uICJN5z {
  padding: 100px 0;
  overflow: hidden;
}

._3nmCKMrF3rQgSPd5Lt8aF1 {
  max-height: 650px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  background: #f5f5f5;
  max-width: 1400px;
  margin: 20px auto;
}

@media screen and (max-width: 1199px) {
  ._3nmCKMrF3rQgSPd5Lt8aF1 {
    -webkit-flex-direction: column;
    flex-direction: column;
    max-height: initial;
  }
}

._38MQ9znN6qp6VwBn4ZlOuq {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

._1EvyD6s0_53e7aTHQDhO5G {
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.98px;
  margin: 0 0 20px 0;
  transition: color 0.2s ease-in-out;
}

._1EvyD6s0_53e7aTHQDhO5G:hover {
  color: #16a58e;
}

._1EvyD6s0_53e7aTHQDhO5G:hover ._2bvDXyZYglEPavqOJ_7kuZ {
  stroke: #16a58e;
}

._1ZrvwAqAbfmpXWqtOxASGW {
  font-size: 16px;
  display: inline-block;
}

@media screen and (max-width: 999px) {
  ._1ZrvwAqAbfmpXWqtOxASGW {
    display: none;
  }
}

._12oLOH7RX6hpE3PE-BiHZl {
  color: #2fd0b7;
  font-size: 20px;
  margin-top: 40px;
  display: none;
}

@media screen and (max-width: 999px) {
  ._12oLOH7RX6hpE3PE-BiHZl {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

._2bvDXyZYglEPavqOJ_7kuZ {
  width: 28px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  fill: transparent;
  stroke-width: 6px;
  margin-left: 10px;
  pointer-events: none;
  stroke: #2fd0b7;
  transition: stroke 0.2s ease-in-out;
}

._2MZ0NkJS-OPi7ZEK3AHjqy {
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #adadad;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.4px;
  text-align: left;
  z-index: 2;
  transition: all .25s ease-in-out;
}

._19no7SsbwCgfxrUfLsz15K {
  width: 100%;
  height: 650px;
  max-width: 450px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  position: relative;
}

@media screen and (max-width: 1199px) {
  ._19no7SsbwCgfxrUfLsz15K {
    max-width: 100%;
    margin: 0 25px;
  }
}

._19no7SsbwCgfxrUfLsz15K>figure {
  background-image: none !important;
}

@media screen and (max-width: 1199px) {
  ._19no7SsbwCgfxrUfLsz15K {
    margin: 0 auto;
  }
}

@media screen and (max-width: 599px) {
  ._19no7SsbwCgfxrUfLsz15K {
    height: 385px;
    width: 269px;
  }
}

.dGYS3B9eORZJJos6NBO3f {
  padding: 40px;
  background: #f5f5f5;
}

.h4VmQyEfl3_VGA-Z8-Edj {
  margin-top: 20px;
}

@media screen and (max-width: 1199px) {
  .h4VmQyEfl3_VGA-Z8-Edj {
    font-size: 28px;
    margin-top: 16px;
  }
}

._15JlIbAE_8c3rsfPn2R_ZX {
  margin-top: 50px;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
}

@media screen and (max-width: 799px) {
  ._15JlIbAE_8c3rsfPn2R_ZX {
    margin-top: 25px;
    font-size: 18px;
  }
}

.uHygQA3PSW6eiv8OYCBZo {
  margin-top: 50px;
  color: black;
}

@media screen and (max-width: 799px) {
  .uHygQA3PSW6eiv8OYCBZo {
    margin-top: 40px;
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
}
._2VPZsCVmdZ5xOd_k1j_kSW {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 3vw;
  padding-top: 3vw;
}

@media screen and (max-width: 1499px) {
  ._2VPZsCVmdZ5xOd_k1j_kSW {
    padding-right: 9%;
    padding-left: 9%;
  }
}

@media screen and (max-width: 699px) {
  ._2VPZsCVmdZ5xOd_k1j_kSW {
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

._25cdytrxfFRzbzeVxJBzGw {
  padding-top: 0px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  max-width: 1400px;
}

@media screen and (min-width: 1000px) {
  ._25cdytrxfFRzbzeVxJBzGw {
    width: 90%;
    margin-top: 0px;
  }
}

._34WFRARBklVFLme40fe2Do {
  font-size: 18px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-stretch: normal;
  line-height: 3;
  z-index: 17;
  font-style: normal;
  text-align: left;
  color: #2fd0b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 699px) {
  ._34WFRARBklVFLme40fe2Do {
    margin: 6vw 0;
    font-size: 1.5rem;
  }
}

._34WFRARBklVFLme40fe2Do::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #2fd0b7;
  content: ' ';
  transition: width 0.2s ease-in-out;
}

._34WFRARBklVFLme40fe2Do:hover::after {
  width: 100%;
}
.gf9WFJEBXRFa2SYa0SqY1 {
  margin-top: 60px;
}

@media screen and (max-width: 999px) {
  .gf9WFJEBXRFa2SYa0SqY1 {
    margin-top: 10px;
  }
}

._39GXLXs6AIHFca9y-qulhk {
  display: block;
}

@media screen and (max-width: 999px) {
  ._39GXLXs6AIHFca9y-qulhk {
    display: none;
  }
}

._2BwM06I9TGYr-HseigjkRk {
  display: none;
}

@media screen and (max-width: 999px) {
  ._2BwM06I9TGYr-HseigjkRk {
    display: block;
    margin-bottom: 30px;
  }
}
._1hMRGT-FuWXK23SZpRTK3t {
  background: #fff;
  padding: 26.08696px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 160px;
  margin-bottom: 25px;
  transition: color 0.2s ease-in-out;
}

._1hMRGT-FuWXK23SZpRTK3t:hover ._1EHQNrfuOHKGGkz3t71RK-,
._1hMRGT-FuWXK23SZpRTK3t:hover .IDmjNvjC4VOATfBr0GlLY {
  color: #16a58e;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  ._1hMRGT-FuWXK23SZpRTK3t {
    padding: 21.42857px;
  }
}

.BSOqksKmtZED6AfFq7GKs {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

@media screen and (max-width: 999px) {
  .BSOqksKmtZED6AfFq7GKs {
    width: 70px;
    height: 70px;
  }
}

.zGb9BefpnpFe9DNvyXZHA {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (max-width: 999px) {
  .zGb9BefpnpFe9DNvyXZHA {
    width: 70px;
    height: 70px;
  }
}

._1u7Iao8lkiIpv2QxlX_-d8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

._1EHQNrfuOHKGGkz3t71RK- {
  width: 100%;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.38;
  text-align: left;
  color: #141315;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.2s ease-in-out;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  ._1EHQNrfuOHKGGkz3t71RK- {
    font-size: 17px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 999px) {
  ._1EHQNrfuOHKGGkz3t71RK- {
    font-size: 20px;
  }
}

@media screen and (max-width: 399px) {
  ._1EHQNrfuOHKGGkz3t71RK- {
    font-size: 17px;
    margin-bottom: 5px;
  }
}

.IDmjNvjC4VOATfBr0GlLY {
  font-style: normal;
  font-size: 14px;
  color: rgba(20,19,21,0.7);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.4;
  font-weight: 600;
  letter-spacing: 1.4px;
  text-align: left;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .IDmjNvjC4VOATfBr0GlLY {
    font-size: 12px;
  }
}

@media screen and (max-width: 549px) {
  .IDmjNvjC4VOATfBr0GlLY {
    font-size: 12px;
  }
}
.gkj84DkCbMMlRkIiHA0Hd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 31%;
  padding: 50px 0;
}

@media screen and (max-width: 999px) {
  .gkj84DkCbMMlRkIiHA0Hd {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding: 0;
  }
}
._1HEnT26WsibrFDdWeNaTH1 {
  background: #f5f5f5;
}

._2J0tPaOBlcZ2knq4NgAw25 {
  background: #f5f5f5;
  padding-bottom: 180px;
}

@media screen and (max-width: 999px) {
  ._2J0tPaOBlcZ2knq4NgAw25 {
    padding-top: 60px;
  }
}

._3W3MHzXbSEWsMWn5AMlHM2 {
  padding: 100px 0;
}

@media screen and (max-width: 999px) {
  ._3W3MHzXbSEWsMWn5AMlHM2 {
    padding: 20px 0;
  }
}

._3nN0i7YyoTqDQPMi_ui3J7 {
  color: #141315;
  font-size: 18px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.98px;
  transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 999px) {
  ._3nN0i7YyoTqDQPMi_ui3J7 {
    font-size: 16px;
  }
}

._3nN0i7YyoTqDQPMi_ui3J7:hover {
  color: #16a58e;
}

._3nN0i7YyoTqDQPMi_ui3J7:hover ._3xkEk8BmDIFyfVkpXnl920 {
  stroke: #16a58e;
}

._3xkEk8BmDIFyfVkpXnl920 {
  height: 12px;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  fill: transparent;
  stroke-width: 2px;
  margin-right: 25px;
  pointer-events: none;
  stroke: #2fd0b7;
  transition: stroke 0.2s ease-in-out;
}

@media screen and (max-width: 999px) {
  ._3xkEk8BmDIFyfVkpXnl920 {
    margin-right: 10px;
  }
}

._2B5AQP8Q5tbbP1UyxYLo88 {
  width: 100%;
  background: #f5f5f5;
  padding: 50px 0;
}

._2-KnovOvL7zPKNsD-8647x {
  max-width: 1400px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media screen and (max-width: 999px) {
  ._2-KnovOvL7zPKNsD-8647x {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}
._1WvdIEJ1QdFiMgQhkCkK4_ {
  margin-top: 30px;
}

@media screen and (max-width: 699px) {
  ._1LFq-k4wmCMKbD5G2viMJe {
    margin-bottom: 20px;
  }
}

._2iJvLwodTuzUfiY7l24_Xr,
.fKR-D8ATolhhdxnf21-5t {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  margin-top: -15px;
}

@media screen and (min-width: 1000px) {
  ._2iJvLwodTuzUfiY7l24_Xr>a:nth-of-type(2n),
  .fKR-D8ATolhhdxnf21-5t>a:nth-of-type(2n) {
    margin-left: 15px;
  }
}

._2iJvLwodTuzUfiY7l24_Xr {
  -webkit-justify-content: center;
  justify-content: center;
}

._18eRyJ9sK3yAy0XG-4cdpD {
  width: 100%;
}
._1aJ8uwiwV0feHBlgMnCYCI {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

._2Jg_Op7Gzc2cFeL7eRhrKN {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 799px) {
  ._2Jg_Op7Gzc2cFeL7eRhrKN {
    font-size: 1.5rem;
  }
}

._15r815JGcfXg68O1ufHC8Z {
  width: 25px;
  height: 10px;
  margin-left: 10px;
  margin-bottom: -15px;
  vertical-align: middle;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  fill: transparent;
  stroke: #141315;
  stroke-width: 5px;
  pointer-events: none;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

@media screen and (min-width: 800px) {
  ._15r815JGcfXg68O1ufHC8Z {
    width: 35px;
    height: 14px;
    margin-left: 15px;
    margin-bottom: -18px;
  }
}
._1CN2jirI9GDXw1FyDajSLz {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  margin-right: -20px;
  float: right;
  z-index: 250;
}

.device-IE11 ._1CN2jirI9GDXw1FyDajSLz {
  position: absolute;
  right: 0;
  top: 620px;
}

._1ExKI12mUFeaXGK50ccIJJ {
  margin-top: 30px;
  margin-bottom: 150px;
}

._3ov2gJ9Ron9Z2nHkpWOlC8 {
  width: 40px;
  height: 40px;
  display: block;
  background: #141315;
  padding: 11.11111px;
  border-radius: 60px;
}

._3ov2gJ9Ron9Z2nHkpWOlC8:not(:first-child) {
  margin-top: 14px;
}

._3ov2gJ9Ron9Z2nHkpWOlC8:hover ._2t4NQxF9KJPu5bWxMrnj15 {
  fill: #2fd0b7;
}

._31Pd8fmPafeugmkl1vvrVl {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

._2t4NQxF9KJPu5bWxMrnj15 {
  line-height: 1.2rem;
  fill: #fff;
  transition: fill 0.25s ease;
}

._20mJVEfh3_57aFgTZj-OdX {
  width: 75px;
  margin-right: 5%;
  margin-left: 5%;
  border: 0;
  border-top: 1px solid #adadad;
}

._3zth6dequdk3NT4TMYodPD {
  color: #adadad;
  text-transform: uppercase;
  font-size: 1.2rem;
}
._2kAn6DMjLJTAp3gxD85tcJ {
  width: 100%;
  min-height: 30vw;
  position: relative;
  z-index: 1;
  background-color: #f5f5f5;
}

@media screen and (max-width: 699px) {
  ._2kAn6DMjLJTAp3gxD85tcJ {
    min-height: 40vw;
  }
}

._1fMsYUa_bdFgNLKmg7ySVK {
  padding-top: 80px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 699px) {
  ._1fMsYUa_bdFgNLKmg7ySVK {
    padding-top: 0vw;
    background-color: #fff;
  }
}

.device-IE11 ._2kAn6DMjLJTAp3gxD85tcJ {
  height: 1px;
}

._3QNMEeA570BRzmAB_nBqOd {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

._3QNMEeA570BRzmAB_nBqOd:after {
  width: 100%;
  background-color: transparent;
  background-image: linear-gradient(to right, #141315 0%,rgba(20,19,21,0.738) 19%,rgba(20,19,21,0.541) 34%,rgba(20,19,21,0.382) 47%,rgba(20,19,21,0.278) 56.5%,rgba(20,19,21,0.194) 65%,rgba(20,19,21,0.126) 73%,rgba(20,19,21,0.075) 80.2%,rgba(20,19,21,0.042) 86.1%,rgba(20,19,21,0.021) 91%,rgba(20,19,21,0.008) 95.2%,rgba(20,19,21,0.002) 98.2%,rgba(20,19,21,0) 100%);
  background-size: 300% 100%;
  background-position: 0 0;
  opacity: 0.6;
}

._1NMhCPnX4c8fDJ2KLyH-Wi {
  position: absolute;
  top: 0;
  left: 0;
}

._3jEruZZU7O4DiYRIL_Yu7 {
  width: 100%;
  max-width: 1398px;
  position: relative;
  padding-top: 30px;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #fff;
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._3jEruZZU7O4DiYRIL_Yu7 {
    padding-right: 9%;
    padding-left: 9%;
  }
}

._1RicRwveM6lG7rCE2CBZK9 {
  padding: 10px 0 25px;
  max-width: 850px;
  width: 100%;
  color: #141315;
  font-size: 40px;
  line-height: 1.13;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 699px) {
  ._1RicRwveM6lG7rCE2CBZK9 {
    font-size: 30px;
    padding-top: 23px;
  }
}

@media screen and (min-width: 700px) {
  ._1RicRwveM6lG7rCE2CBZK9 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

._1aJjAFvpKxQG22M4D0toe3 {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding-top: 20px;
}

._2slLhyHsv2Gg2XZ3t-0CmQ {
  margin-bottom: 20px;
  font-size: 18px;
  max-width: 850px;
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.26px;
  text-align: left;
  color: #ccc;
  font-style: normal;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
}

@media screen and (min-width: 700px) {
  ._2slLhyHsv2Gg2XZ3t-0CmQ {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 699px) {
  ._32TalgJZgp4dLwNGd0EOa9 {
    position: absolute;
    z-index: 2;
    padding: 1rem 2rem;
    background-color: rgba(98,98,98,0.1);
    opacity: 0;
    -webkit-animation: _2YavJTu6dTANHAfzWPLrwk 1s ease-out 2s 1 forwards;
    animation: _2YavJTu6dTANHAfzWPLrwk 1s ease-out 2s 1 forwards;
  }
}

._32TalgJZgp4dLwNGd0EOa9 ._2KqunliFXCwynkeBmn2vAC {
  display: block;
}

._32TalgJZgp4dLwNGd0EOa9 ._2KqunliFXCwynkeBmn2vAC span {
  color: #626262;
}

._32TalgJZgp4dLwNGd0EOa9 ._2KqunliFXCwynkeBmn2vAC svg {
  stroke: #626262;
}

._32TalgJZgp4dLwNGd0EOa9 ._2KqunliFXCwynkeBmn2vAC:hover span {
  color: #333;
}

._32TalgJZgp4dLwNGd0EOa9 ._2KqunliFXCwynkeBmn2vAC:hover svg {
  stroke: #333;
  -webkit-transform: rotate(180deg) translateX(10px);
  transform: rotate(180deg) translateX(10px);
}

@media screen and (max-width: 699px) {
  ._32TalgJZgp4dLwNGd0EOa9 ._2KqunliFXCwynkeBmn2vAC._1vSRKgMk0MlaE_pg1-MIuk {
    top: 0;
  }
}

._1JZWnykjzqhEaFnz1jsaQw {
  font-size: 14px;
  text-align: left;
  color: #ccc;
  font-style: normal;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.EEpBiPbDhLNQVzu-liqDC {
  color: #141315;
  font-size: 40px;
  line-height: 1;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._1vSRKgMk0MlaE_pg1-MIuk {
  top: -50px;
}

@-webkit-keyframes _2YavJTu6dTANHAfzWPLrwk {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes _2YavJTu6dTANHAfzWPLrwk {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
._2BnR2IXJ6dkh4kcb2rwNRg {
  position: relative;
  z-index: 3;
  background: #f5f5f5;
}

@media screen and (max-width: 699px) {
  ._2BnR2IXJ6dkh4kcb2rwNRg {
    background: #fff;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

._3ucCbM7_236UQM7gNPf2Pc {
  color: #2fd0b7;
  font-size: 16px;
  font-weight: 400;
}

._3zh04GOP6yCQTyb-cWgrwD,
.Ezldvnfr2A5eW1I1Y0apq {
  font-size: 17px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  color: #1c1b20;
  font-style: normal;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 699px) {
  ._3zh04GOP6yCQTyb-cWgrwD,
  .Ezldvnfr2A5eW1I1Y0apq {
    font-size: 12px;
  }
}

._3cmeBOXY3tfW-xTSBfRr_g {
  position: relative;
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: #fff;
}

@media screen and (max-width: 699px) {
  ._3cmeBOXY3tfW-xTSBfRr_g {
    padding-top: 0px;
  }
}

@media screen and (min-width: 700px) {
  ._3cmeBOXY3tfW-xTSBfRr_g {
    padding-top: 1px;
  }
}

@media screen and (min-width: 1200px) {
  ._3cmeBOXY3tfW-xTSBfRr_g {
    padding-top: 1px;
  }
}

._1PxhuXaRGva259aeuYDYx {
  max-width: 850px !important;
}

._2-IzxOqtqRMUjO7679wCJ- h3 {
  font-size: 28px;
  font-family: "Playfair Display Regular",sans-serif;
  line-height: 1.43;
}

.TgoKz6BzDsAe-I4T1vrfl {
  width: 100%;
  margin: 0 auto;
  background: #fff;
}

@media screen and (min-width: 1000px) {
  .TgoKz6BzDsAe-I4T1vrfl {
    width: 92%;
  }
}

.GoXx6se7DBPb2RPcuyBVR {
  width: 100%;
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
}

.GoXx6se7DBPb2RPcuyBVR:not(:first-child) {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (min-width: 700px) {
  .GoXx6se7DBPb2RPcuyBVR {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (min-width: 1700px) {
  .GoXx6se7DBPb2RPcuyBVR:nth-child(2) {
    margin-top: 0;
  }
}

._1GyPXg5ahPxD6bGi9rwPFt {
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._1GyPXg5ahPxD6bGi9rwPFt {
    padding-right: 9%;
    padding-left: 9%;
  }
}

._3hMf2uZJ6t0ofsZ77CMifM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

._1brTrryaz1kaAk1ajyBOh3 {
  font-size: 1.4rem;
  color: rgba(20,19,21,0.6);
}

._1suORWysREFqftA_FQa53_ {
  width: 100%;
}

@media screen and (min-width: 700px) {
  ._1suORWysREFqftA_FQa53_ {
    padding-right: 20px;
    padding-left: 20px;
  }
}

._2lNUMm2E_j7IOTNpAhAhtB {
  width: 100%;
  max-width: 820px;
  margin-right: auto;
  margin-left: auto;
}

._20Sc8RPVkv55YCRUsc10wd {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 14px;
  border-bottom: 2px solid #707070;
  width: 100%;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.LmKR8S_wp2lKvp1XnQgvD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 40px;
  padding-top: 40px;
  border-top: 1px solid #707070;
}

._1E2nptgSY1uSwgpfk5o4Ax {
  width: 70px;
  height: 70px;
  margin-right: 20px;
}

@media screen and (max-width: 699px) {
  ._1E2nptgSY1uSwgpfk5o4Ax {
    width: 50px;
    height: 50px;
  }
}

._1vlBKP3rW1nXtHEYebIPOG {
  border-radius: 50%;
}

._352sCbRC1TDCzy6YGKQk44,
.Ezldvnfr2A5eW1I1Y0apq {
  display: block;
}

._352sCbRC1TDCzy6YGKQk44 {
  color: #141315;
  font-size: 21px;
  line-height: 1;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 5px;
}

._2fL1iIwu-EjXcqxp3K9kAD {
  display: block;
  border-bottom: 1px solid #141315;
  color: #141315;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._3Emit0eHaq4Gk1v3TlTRag {
  color: #2fd0b7;
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._2qcLzndYBzDMSfpfSE2LSi {
    padding-right: 9%;
    padding-left: 9%;
  }
}

._26Ts1NDR_ufs-zDsBMy81i {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  color: #141315;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 0.4px;
  color: #1c1b20;
}

@media screen and (max-width: 699px) {
  ._26Ts1NDR_ufs-zDsBMy81i {
    font-size: 16px;
  }
}

._1N3KtERADzvf9x20hgY2K4.OxcJiy2VM3wUMb5vUT1iO {
  position: relative;
  width: 100%;
  max-width: 750px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
}

@media screen and (min-width: 1200px) {
  ._1N3KtERADzvf9x20hgY2K4.OxcJiy2VM3wUMb5vUT1iO {
    top: -6rem;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1700px) {
  ._1N3KtERADzvf9x20hgY2K4.OxcJiy2VM3wUMb5vUT1iO {
    display: none;
  }
}

._1N3KtERADzvf9x20hgY2K4._2yXBs5faJuL-IGlX-9y-V5 {
  display: none;
}

@media screen and (min-width: 1700px) {
  ._1N3KtERADzvf9x20hgY2K4._2yXBs5faJuL-IGlX-9y-V5 {
    display: block;
    position: absolute;
    top: 240px;
    right: 100%;
  }
}

@media screen and (min-width: 1900px) {
  ._1N3KtERADzvf9x20hgY2K4._2yXBs5faJuL-IGlX-9y-V5 {
    right: calc(100% + 120px);
  }
}

._1N3KtERADzvf9x20hgY2K4 {
  position: relative;
  z-index: 3;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

._2iwARrgNdjsgVSKC41Rxfh {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 5vw;
  color: #141315;
  opacity: 0.2 !important;
}
._2mUx3hbpgBATZmbs_oG8bu {
  background: #f5f5f5;
  padding-bottom: 180px;
  margin-top: 0;
}

@media screen and (max-width: 799px) {
  ._2mUx3hbpgBATZmbs_oG8bu {
    padding-bottom: 140px;
  }
}

@media screen and (max-width: 799px) {
  ._2mUx3hbpgBATZmbs_oG8bu {
    margin-top: -20px;
  }
}

._1qt4tQWnrFE084Ffv6m2Oe {
  margin-top: -100px;
}
._1UXoknBUgBESljwse31vlz {
  background-color: #f5f5f5;
  width: 100%;
}

._3FmpvKxifx2eJ8ApydVGBD {
  background-color: #f5f5f5;
}
._2nsqkidO9D_G6x8LLXFK7s {
  background: #f5f5f5;
  margin-top: 0;
  width: 100%;
  width: 100%;
  position: relative;
}

._3x2xSlSA4qTPUO3QaerZNp {
  border: dotted 1px #1c1b20;
  position: relative;
  width: 74vw;
  margin-left: -5vw;
  margin-top: -3vw;
  margin-bottom: -23vw;
  height: 400px;
  opacity: 0.5;
  display: block;
}

@media screen and (max-width: 699px) {
  ._3x2xSlSA4qTPUO3QaerZNp {
    display: none;
  }
}

._38jxKHOH56n9XzTWB0G6Iu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (max-width: 699px) {
  ._38jxKHOH56n9XzTWB0G6Iu {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

._3G7Q_0YPp2CV6KIxqDOgTJ {
  width: 45%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

@media screen and (max-width: 699px) {
  ._3G7Q_0YPp2CV6KIxqDOgTJ {
    width: 65%;
  }
}

._1iaXBf6IoISxOzCXa0dm7I {
  width: 55%;
}

@media screen and (max-width: 699px) {
  ._1iaXBf6IoISxOzCXa0dm7I {
    width: 95%;
  }
}

._2LS8wATQk2pDeUcTu6Xacs {
  width: 90%;
  color: #141315;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 1.6;
  font-style: normal;
  font-family: "Playfair Display Regular",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1499px) {
  ._2LS8wATQk2pDeUcTu6Xacs {
    width: 100%;
  }
}

._2Rzj9ivaN9G6oOzvclw44k {
  cursor: pointer;
  font-size: 16px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-stretch: normal;
  line-height: 3;
  font-style: normal;
  text-align: left;
  color: #2fd0b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._1ocGN8JFTQIUfAso-xMvdW {
  width: 90%;
  font-size: 16px;
  color: rgba(20,19,21,0.7);
  line-height: 1.8;
  overflow: hidden;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
}

@media screen and (max-width: 1499px) {
  ._1ocGN8JFTQIUfAso-xMvdW {
    width: 100%;
  }
}

a {
  text-decoration: none !important;
}
._31ntE2o-CJZj_FkLQQdlAI {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 73px;
  background-color: #fff;
}

._31ntE2o-CJZj_FkLQQdlAI span {
  color: #626262;
}

._31ntE2o-CJZj_FkLQQdlAI svg {
  stroke: #626262;
}

._31ntE2o-CJZj_FkLQQdlAI:hover span {
  color: #333;
}

._31ntE2o-CJZj_FkLQQdlAI:hover svg {
  stroke: #333;
  -webkit-transform: rotate(180deg) translateX(10px);
  transform: rotate(180deg) translateX(10px);
}

@media screen and (max-width: 699px) {
  ._31ntE2o-CJZj_FkLQQdlAI {
    display: none;
  }
}

.dNBSTWUNm0eKWswQsN71W {
  background-color: #fff;
  padding-top: 50px;
}

@media screen and (min-width: 700px) {
  .dNBSTWUNm0eKWswQsN71W {
    padding-bottom: 50px;
  }
}

._15jTEPQROy0rTDVfgXbD8i {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 999px) {
  ._15jTEPQROy0rTDVfgXbD8i {
    width: 75%;
  }
}

@media screen and (max-width: 599px) {
  ._15jTEPQROy0rTDVfgXbD8i {
    width: 90%;
  }
}

.X6OkNssYP-YrWZZm7FaLZ {
  font-size: 34px;
  line-height: 40px;
  font-weight: 900;
  color: #1c1b20;
  font-family: "proxima-nova",sans-serif;
  font-weight: 100;
}

._3X6BbB8H-sOeAsQ-4KXa4q {
  font-size: 14px;
  line-height: 21px;
  color: rgba(28,27,32,0.8);
}

.FFIeuqlfHDFIYE_116r4c {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

@media screen and (max-width: 999px) {
  .FFIeuqlfHDFIYE_116r4c {
    width: 75%;
  }
}

@media screen and (max-width: 699px) {
  .FFIeuqlfHDFIYE_116r4c {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 599px) {
  .FFIeuqlfHDFIYE_116r4c {
    width: 90%;
  }
}

.FFIeuqlfHDFIYE_116r4c .hs-form {
  padding: 20px 0 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: space-between;
  align-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.FFIeuqlfHDFIYE_116r4c .hs-form fieldset {
  max-width: none;
}

.FFIeuqlfHDFIYE_116r4c .hs-input {
  margin-bottom: 10px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-1 {
  margin: 13px 0;
  width: 100%;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-1 ._3YHkMlhpwwl0oT6mAHOvyr {
  width: 100%;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 {
  width: 100%;
  margin: 13px 0;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-form-field {
  float: left;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-form-field:nth-child(even) {
  float: right;
}

@media screen and (max-width: 599px) {
  .FFIeuqlfHDFIYE_116r4c .form-columns-2,
  .FFIeuqlfHDFIYE_116r4c .form-columns-1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text input,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber input,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea input,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea textarea {
  width: 100%;
  color: #1c1b20;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 6px 1px;
  border-bottom: #d7d7d8 2px solid;
  transition: border-bottom ease 0.25s;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text input::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text textarea::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea input::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea textarea::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text input::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text textarea::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber input::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber textarea::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea input::-webkit-input-placeholder,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea textarea::-webkit-input-placeholder {
  color: #1c1b20;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text input.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text textarea.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber input.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber textarea.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea input.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea textarea.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text input.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text textarea.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber input.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber textarea.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea input.error,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea textarea.error {
  border-color: #f53175;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text .input,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber .input,
.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea .input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text .input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber .input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

@media screen and (min-width: 550px) {
  .FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-text,
  .FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-phonenumber,
  .FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-text,
  .FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-phonenumber {
    width: 43% !important;
  }
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea textarea,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea textarea {
  padding: 10px;
  border: #d7d7d8 2px solid;
  resize: none;
  height: 139px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea label,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea label {
  width: 100%;
  margin: 7px 0;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-textarea .input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-textarea .input {
  width: 100%;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox ul,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox ul {
  list-style-type: none;
  padding: 0;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox .input label,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox .input label {
  cursor: pointer;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox label,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 32px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox label span,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox label span {
  position: absolute;
  left: 40px;
  top: 0;
  transition: top 0.25s;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox label span::after,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox label span::after {
  content: '';
  position: absolute;
  top: 28px;
  left: 0;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox label.checkbox-checked span,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox label.checkbox-checked span {
  position: absolute;
  top: -28px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox input,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox input {
  position: relative;
  margin-right: 20px;
  height: 24px;
  min-width: 24px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox input:before,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox input:before {
  content: '';
  height: 24px;
  min-width: 24px;
  position: absolute;
  border: 2px solid #d7d7d8;
  z-index: 1;
  background: #fff;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox input:after,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox input:after {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #2fd0b7;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: opacity 0.25s ease, -webkit-transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease;
  z-index: 2;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-fieldtype-booleancheckbox input:checked:after,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-fieldtype-booleancheckbox input:checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: _3PFF2q4BQWGUG7gIwNg5IF 0.3s ease-out;
  animation: _3PFF2q4BQWGUG7gIwNg5IF 0.3s ease-out;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy {
  margin-top: 27px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs_i_agree_with_the_dpdk_privacy_policy label span::after,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs_i_agree_with_the_dpdk_privacy_policy label span::after {
  content: 'Great!';
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk {
  margin-top: -13px;
  margin-bottom: 32px;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs_i_d_like_to_receive_updates_from_dpdk label span::after,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs_i_d_like_to_receive_updates_from_dpdk label span::after {
  content: "I'm awesome!";
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-form-required,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-form-required {
  display: none;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-error-msgs,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-error-msgs {
  list-style-type: none;
  padding: 0;
}

.FFIeuqlfHDFIYE_116r4c .form-columns-2 .hs-error-msgs .hs-error-msg,
.FFIeuqlfHDFIYE_116r4c .form-columns-1 .hs-error-msgs .hs-error-msg {
  color: #f53175;
}

.FFIeuqlfHDFIYE_116r4c .hs_error_rollup {
  width: 100%;
  margin: 0 0 13px 0;
}

.FFIeuqlfHDFIYE_116r4c .hs_error_rollup .hs-error-msgs {
  list-style-type: none;
  padding: 0;
  color: #f53175;
}

.FFIeuqlfHDFIYE_116r4c .hs-submit {
  position: relative;
}

.FFIeuqlfHDFIYE_116r4c .hs-submit input {
  cursor: pointer;
  border: none;
  padding: 11px 77px 11px 27px;
  z-index: 1;
  background-image: linear-gradient(-90deg, #47dba4, #46d1be);
  background-size: cover;
  color: #fff;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 1.3;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.4s ease;
}

@media screen and (max-width: 799px) {
  .FFIeuqlfHDFIYE_116r4c .hs-submit input {
    font-size: 1.5rem;
  }
}

.FFIeuqlfHDFIYE_116r4c .hs-submit input:hover {
  background-image: linear-gradient(90deg, #47dba4, #46d1be);
  background-size: cover;
}

.FFIeuqlfHDFIYE_116r4c .hs-submit::after {
  content: '';
  position: absolute;
  width: 35px;
  height: 14px;
  background: url(/media/images/svg/arrow-right.svg);
  right: 30px;
  transition: right 0.25s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

.FFIeuqlfHDFIYE_116r4c .hs-submit:hover::after {
  right: 20px;
}

.FFIeuqlfHDFIYE_116r4c .submitted-message {
  color: #2fd0b7;
  font-size: 2.2rem;
  margin-top: 50px;
}

@-webkit-keyframes _3PFF2q4BQWGUG7gIwNg5IF {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes _3PFF2q4BQWGUG7gIwNg5IF {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  60% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
._2oG1sBqcfTvr50SBu0Edr- {
  padding-bottom: 10px;
}

@media screen and (min-width: 800px) {
  ._2oG1sBqcfTvr50SBu0Edr- {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 799px) {
  ._1ZCT_oxLb9paiIYRBYq8BO {
    margin-bottom: -80px;
  }
}
._1gb4LRPtUs0kmQZV3PCyaw {
  overflow: hidden;
}

._1IjHAuLjQ0XBzfjWGEabnV {
  position: relative;
}

._1IjHAuLjQ0XBzfjWGEabnV:before {
  content: '';
  position: absolute;
  top: 23%;
  right: -20px;
  bottom: 23%;
  left: -20px;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) and (max-width: 1499px) {
  ._1IjHAuLjQ0XBzfjWGEabnV:before {
    left: -9%;
    right: -9%;
  }
}

@media screen and (min-width: 1500px) {
  ._1IjHAuLjQ0XBzfjWGEabnV:before {
    left: -7%;
    right: -7%;
  }
}

._3STpEiaM5j8S66syRM2Qr8 {
  z-index: 5;
}

._3STpEiaM5j8S66syRM2Qr8>figure {
  background-image: none !important;
}

._3STpEiaM5j8S66syRM2Qr8:first-of-type {
  margin-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._3STpEiaM5j8S66syRM2Qr8:first-of-type {
    margin-bottom: 60px;
  }
}
._3epgJING2RJdXtEjOaOSpv {
  padding-top: 60px;
  position: relative;
  overflow: hidden;
}

.eArkiY2NaVwoqFgRjzDRn {
  position: absolute;
  top: -60px;
  right: -50px;
  bottom: 80px;
  left: -50px;
  z-index: -1;
}

@media screen and (min-width: 800px) {
  .eArkiY2NaVwoqFgRjzDRn {
    left: -40%;
    right: -40%;
    bottom: 160px;
  }
}

._3oEYjNUc-zKViMSKxWfXbj {
  width: calc(100% + 100px) !important;
  min-height: 320px;
  position: absolute;
  top: -60px;
  right: -50px;
  left: -50px;
  z-index: -1;
}

@media screen and (min-width: 800px) {
  ._3oEYjNUc-zKViMSKxWfXbj {
    width: 160% !important;
    min-height: 450px;
    left: -30%;
    right: -30%;
  }
}
._2p0n0ZwL6y6iDPvTWMFSRx {
  margin-top: 7px;
}
._3d9qJDIx9VrD8hdm5CqiVo {
  margin-top: 7px;
}
@media screen and (min-width: 800px) {
  ._300c3u5648dpYcNxvGb031 {
    display: -webkit-flex;
    display: flex;
  }

  ._300c3u5648dpYcNxvGb031._18wl8VqfW3r57HCIuSn8Q- {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  ._300c3u5648dpYcNxvGb031._3GdKc2qR1xRidvwy0eybnu {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
  }

  ._300c3u5648dpYcNxvGb031._3MXb_9Yv_f-UZSGliMm8ow {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
  }
}

._5HYiI4hbxooEOuNyBAjhN {
  width: 100%;
}

@media screen and (min-width: 800px) {
  ._18wl8VqfW3r57HCIuSn8Q- ._5HYiI4hbxooEOuNyBAjhN,
  ._3GdKc2qR1xRidvwy0eybnu ._5HYiI4hbxooEOuNyBAjhN {
    width: 45%;
  }
}

._2BeHXU7JysnWluRQH1lFDQ {
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  ._2BeHXU7JysnWluRQH1lFDQ {
    width: 45%;
    margin-bottom: 0;
  }

  ._3MXb_9Yv_f-UZSGliMm8ow ._2BeHXU7JysnWluRQH1lFDQ {
    padding-bottom: 40px;
  }
}

.GRQgQKoGOKaex4WEl9XXz {
  margin-bottom: 15px;
}

@media screen and (min-width: 800px) {
  .GRQgQKoGOKaex4WEl9XXz {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 800px) {
  ._3KHQRE5mDFM7O7LDfcIYhu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1200px) {
  ._3KHQRE5mDFM7O7LDfcIYhu {
    -webkit-align-items: center;
    align-items: center;
  }
}

@media screen and (min-width: 800px) {
  ._3j_45iSYtISTvrqm-EoAlN {
    width: calc(50% - 30px);
  }
}

._1Zzsx4DWhots0JKkfMQyw-:not(:first-child) {
  margin-top: 30px;
}

.ed_fJYcjBSw0OV21MidH_:not(:first-child) {
  margin-top: 15px;
}

@media screen and (min-width: 800px) {
  .ed_fJYcjBSw0OV21MidH_:not(:first-child) {
    margin-top: 35px;
  }
}

._25B8VmOyPzy2INPbG972lM {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._1iGV9NdIjdHEBlvj5Q4LJJ:not(:first-child) {
  margin-top: 15px;
}

._3vk9SQnEjwzY8acbVeJfgS {
  margin-top: 30px;
}

@media screen and (min-width: 550px) {
  ._3vk9SQnEjwzY8acbVeJfgS {
    margin-top: 60px;
  }
}

@media screen and (min-width: 800px) {
  ._3vk9SQnEjwzY8acbVeJfgS {
    width: calc(50% - 30px);
    margin-top: 0;
  }
}
._2_RohlDe4LZ_dDfb-4-Lg8 {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (min-width: 800px) {
  ._2_RohlDe4LZ_dDfb-4-Lg8 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
._38v6sdDu-Z88VzGVXE7VxN {
  margin-top: 45px;
  position: relative;
}

@media screen and (min-width: 800px) {
  ._38v6sdDu-Z88VzGVXE7VxN {
    margin-top: 90px;
  }
}

._35kBQZ1zThRCrv_Xm9vQ8x {
  padding: 40px 30px;
  background-color: #f5f5f5;
}

@media screen and (min-width: 800px) {
  ._35kBQZ1zThRCrv_Xm9vQ8x {
    padding: 80px 60px;
    margin-top: 60px;
  }
}

@media screen and (min-width: 1200px) {
  ._35kBQZ1zThRCrv_Xm9vQ8x {
    padding: 120px 140px;
  }
}

._3JR8ieFWDjqczeDLfp_ol3 {
  margin-bottom: 30px;
}

.mcPln21XZ6UG4miVcy_dU ul {
  padding-bottom: 22px;
  padding-left: 0;
  list-style: none;
  border-bottom: 2px solid rgba(173,173,173,0.2);
}

@media screen and (min-width: 800px) {
  .mcPln21XZ6UG4miVcy_dU ul {
    padding-bottom: 45px;
  }
}

.mcPln21XZ6UG4miVcy_dU li {
  padding: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: -5px;
}

.mcPln21XZ6UG4miVcy_dU li::before {
  content: '\2022';
  width: 1em;
  margin-left: -1em;
  display: inline-block;
  color: #2fd0b7;
}

._3986rnBeFZXjXZioEDn11f {
  padding-top: 35px;
}

@media screen and (min-width: 800px) {
  ._3986rnBeFZXjXZioEDn11f {
    padding-top: 55px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

._1-Bx1S1LKz7AplGM9KLkXp {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  padding-bottom: 15px;
  letter-spacing: 2px;
}

@media screen and (min-width: 800px) {
  ._1MbFfcNKH7ZLSLkFeuuhX_,
  ._3Zi7sPDpf0f6sUl_m_Z17g {
    width: 46%;
  }
}

@media screen and (min-width: 1200px) {
  ._1MbFfcNKH7ZLSLkFeuuhX_,
  ._3Zi7sPDpf0f6sUl_m_Z17g {
    width: 42%;
  }
}

._27H5ENz8KJ3DTbOG6Fi1Sq {
  display: block;
  position: relative;
  width: calc(100% - 10px);
  color: rgba(20,19,21,0.6);
  transition: color ease 0.25s, border ease 0.25s;
}

._27H5ENz8KJ3DTbOG6Fi1Sq ._3M859Spd9AEUYHNA-wtg7 {
  width: 35px;
  position: absolute;
  top: 6px;
  right: -10px;
  fill: transparent;
  stroke: rgba(20,19,21,0.2);
  stroke-width: 6px;
  transition: stroke ease 0.25s;
}

._27H5ENz8KJ3DTbOG6Fi1Sq:hover {
  color: #2fd0b7;
  border-bottom-color: rgba(47,208,183,0.5);
}

._27H5ENz8KJ3DTbOG6Fi1Sq:hover ._3M859Spd9AEUYHNA-wtg7 {
  stroke: #2fd0b7;
}

@media screen and (max-width: 799px) {
  ._3Zi7sPDpf0f6sUl_m_Z17g {
    padding-top: 20px;
  }
}

.NQCrzzE2bCJKqvcGE3q7Q {
  width: 100%;
  display: inline-block;
}

._2ZGTEOe8FHDeMK1pB721Qq {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  color: #2fd0b7;
  text-decoration: none;
}

._1C0DvuDp7xSVb6TTtQqdoY {
  color: rgba(20,19,21,0.6);
}

._3TjxkAPDuVxc0R47r9JRbw {
  position: absolute;
  bottom: -60px;
  right: 25px;
  display: -webkit-flex;
  display: flex;
  font-size: 0.8em;
}

@media screen and (min-width: 1000px) {
  ._3TjxkAPDuVxc0R47r9JRbw {
    right: 13%;
    bottom: -240px;
  }
}

._2-pBpsD-c1oyb2qnIrhylE {
  width: 23px;
  height: 23px;
  margin-right: 8px;
}

._3Zi7sPDpf0f6sUl_m_Z17g div {
  padding-bottom: 20px;
}

._3Zi7sPDpf0f6sUl_m_Z17g div .PDeVjJZvliTtF-qbedG1R,
._3Zi7sPDpf0f6sUl_m_Z17g div .NQCrzzE2bCJKqvcGE3q7Q {
  padding-bottom: 0;
  color: rgba(20,19,21,0.6);
}
._1m8P7BVQlITnFrMZocdsNy {
  height: calc(100vh - 52px);
  max-height: 700px;
  position: relative;
  background-color: #141315;
}

._1-CZ2xrrLzxeMTXOYmrRln {
  width: calc(100% - 20px);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

._1jhTDSM8Koj2ZcKf3qvMKD {
  min-width: 50px;
  min-height: 50px;
  margin: 0 auto 10px auto;
}

._1jhTDSM8Koj2ZcKf3qvMKD img {
  height: auto !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.GYHuFRKawGUQYh9HA12gJ {
  margin-top: 10px;
  letter-spacing: 3px;
}

._3zbfhYHwDyDk7M9PoRJlKH {
  background-color: #f5f5f5;
}

._3JwAdfusnakUM0YzffJTyo {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (min-width: 800px) {
  ._3JwAdfusnakUM0YzffJTyo {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

._3rmsNzqzCn89uQKBXgFsBO {
  padding-top: 45px;
  padding-bottom: 45px;
}

@media screen and (min-width: 800px) {
  ._3rmsNzqzCn89uQKBXgFsBO {
    padding-top: 120px;
    padding-bottom: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (max-width: 799px) {
  ._3NYzFgqhWRpPMSSx935YSe {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 800px) {
  ._3NYzFgqhWRpPMSSx935YSe {
    width: 40%;
  }
}

@media screen and (min-width: 800px) {
  ._1OjPwb3chjLn79Da7jDACG {
    width: 40%;
    margin-right: 5%;
  }
}

.fRvDI7vlszlxEJQ6fEMTg {
  margin-bottom: 10px;
}

@media screen and (min-width: 800px) {
  .fRvDI7vlszlxEJQ6fEMTg {
    margin-bottom: 20px;
  }
}

.-RqU2jWO3Q0rEAwP3uEdz {
  padding-bottom: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@media screen and (min-width: 800px) {
  .-RqU2jWO3Q0rEAwP3uEdz {
    padding-bottom: 90px;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

.avvwUap90jOwX7MEfBYhz {
  background-color: #f5f5f5;
  padding: 40px;
}

@media screen and (min-width: 800px) {
  .avvwUap90jOwX7MEfBYhz {
    width: 50%;
    padding: 80px 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}

@media screen and (min-width: 1500px) {
  .avvwUap90jOwX7MEfBYhz {
    padding-left: 160px;
  }
}

@media screen and (min-width: 800px) {
  ._264ysnUFoy9gBmYimROcYw {
    width: 50%;
  }
}

@media screen and (max-width: 799px) {
  ._264ysnUFoy9gBmYimROcYw ._2eSNiqPD5ud6vDuaw80CR8 {
    height: 230px !important;
  }
}

._264ysnUFoy9gBmYimROcYw .mpTaoxgwfaTuEC3IlaYkP {
  width: 100%;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.65;
}

._264ysnUFoy9gBmYimROcYw ._1GexZzf0g-6g3BXKcUkZJR {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

._264ysnUFoy9gBmYimROcYw ._1GexZzf0g-6g3BXKcUkZJR ._2xHCfN5CQvYCci56JPFaZk {
  padding-bottom: 10px;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-size: 2.6em;
}

@media screen and (min-width: 400px) {
  ._264ysnUFoy9gBmYimROcYw ._1GexZzf0g-6g3BXKcUkZJR ._2xHCfN5CQvYCci56JPFaZk {
    font-size: 3.4em;
  }
}

@media screen and (min-width: 1100px) {
  ._264ysnUFoy9gBmYimROcYw ._1GexZzf0g-6g3BXKcUkZJR ._2xHCfN5CQvYCci56JPFaZk {
    padding-bottom: 20px;
    font-size: 5em;
  }
}

._264ysnUFoy9gBmYimROcYw ._1GexZzf0g-6g3BXKcUkZJR .Flknp93Zl3-oTNwX_3yct {
  font-size: 1.4em;
}

@media screen and (min-width: 1100px) {
  ._264ysnUFoy9gBmYimROcYw ._1GexZzf0g-6g3BXKcUkZJR .Flknp93Zl3-oTNwX_3yct {
    font-size: 1.7em;
  }
}

._22oT3rSTErfNulPGVyZM50 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1920px;
  max-width: 1920px;
  height: 1080px;
  max-height: 100%;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: opacity 0.4s ease-in-out;
}
._1Tuctcx9jurwbWVNsCc7fs {
  display: none;
}

._3ZodNhKRrImnhlbE-zppkz {
  margin: 24px;
  position: absolute;
  z-index: 5;
}

._3ZodNhKRrImnhlbE-zppkz span {
  color: #2fd0b7;
}

@media (max-width: 1000px) {
  ._3ZodNhKRrImnhlbE-zppkz span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@media (min-width: 1000px) {
  ._1Tuctcx9jurwbWVNsCc7fs {
    width: 1920px;
    max-width: 1920px;
    height: 1080px;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
.l0bu4YTUVtGOMcKJAOusw {
  padding: 2rem 0;
}

.l0bu4YTUVtGOMcKJAOusw>span {
  margin-left: 0;
}

.l0bu4YTUVtGOMcKJAOusw>svg {
  width: 26px;
}

@media screen and (min-width: 1500px) {
  .l0bu4YTUVtGOMcKJAOusw {
    position: absolute;
    left: calc((100vw - 1384px) / 2 - 50px);
  }
}

@media screen and (min-width: 1700px) {
  .l0bu4YTUVtGOMcKJAOusw {
    position: absolute;
    left: calc((100vw - 1384px) / 2 - 150px);
  }
}

._2XpdViZsWMC7GMTTQkto7d {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1500px) {
  ._2XpdViZsWMC7GMTTQkto7d {
    padding-left: 100px;
  }
}

._2XpdViZsWMC7GMTTQkto7d small {
  text-transform: uppercase;
  color: #adadad;
  font-size: 14px;
}

._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg {
  overflow: hidden;
  width: 100%;
}

._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg.GWTPzC3mMoBTNGaSWimSj {
  height: 250px;
}

._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg._1jY3WlIeWT9ZbblhJoZY8r {
  display: none;
}

@media screen and (min-width: 550px) {
  ._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg.GWTPzC3mMoBTNGaSWimSj {
    height: 500px;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg._1jY3WlIeWT9ZbblhJoZY8r {
    width: 50%;
    height: 100%;
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  ._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg._1jY3WlIeWT9ZbblhJoZY8r {
    height: 100%;
    width: 55%;
  }
}

@media screen and (min-width: 1500px) {
  ._2XpdViZsWMC7GMTTQkto7d ._3iLepIrrr4nvqcdCcY12lg.GWTPzC3mMoBTNGaSWimSj {
    width: calc(100vw - (100vw - 1384px) / 2 - 100px);
  }
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding: 4rem 3rem;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 small {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 ._1MuBziEZckeS_BORaUPrCi {
  display: block;
  font-size: 25px;
  color: #2fd0b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.3s ease;
  margin-bottom: 18px;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 ._1MuBziEZckeS_BORaUPrCi:hover {
  color: #16a58e;
  transition: color 0.3s ease;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._2wLtcqlzhexVEcM4pqcN-8 {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._2wLtcqlzhexVEcM4pqcN-8:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

@media screen and (min-width: 550px) {
  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr {
    height: 300px;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 {
    width: 50%;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 h5 {
    margin-bottom: 30px;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 ._1MuBziEZckeS_BORaUPrCi {
    font-size: 20px;
  }
}

@media screen and (min-width: 1000px) {
  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr {
    padding-left: 5rem;
    width: 700px;
    position: relative;
    top: -60px;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    right: 60px;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr ._30Azb2f4HBKooibyy5aKx7 {
    width: 45%;
  }
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .kD29ug4D_XlIpgXE2jEim {
  padding: 5rem 0 0;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .kD29ug4D_XlIpgXE2jEim ._29jpTGWWrqkELUqlWxvm4E {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
}

._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .kD29ug4D_XlIpgXE2jEim ._29jpTGWWrqkELUqlWxvm4E small:first-of-type {
  margin-right: 60px;
}

@media screen and (min-width: 550px) {
  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .kD29ug4D_XlIpgXE2jEim {
    padding: 2rem 0;
  }
}

@media screen and (min-width: 1500px) {
  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 {
    -webkit-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .Ktj8KrtAlXaeqlYtUzwAr {
    right: unset;
  }

  ._2XpdViZsWMC7GMTTQkto7d ._1fzloAIR2skQlnb9xWCXN9 .kD29ug4D_XlIpgXE2jEim {
    -webkit-align-self: flex-end;
    align-self: flex-end;
    padding-bottom: 60px;
  }
}

._2XpdViZsWMC7GMTTQkto7d .PetA7KXgATItjsnzUeioV {
  padding-top: 60px;
}

._2XpdViZsWMC7GMTTQkto7d .PetA7KXgATItjsnzUeioV ._22k68PEgFsPaCzYZS_lRHO {
  padding-bottom: 1rem;
}

._2XpdViZsWMC7GMTTQkto7d .PetA7KXgATItjsnzUeioV p {
  color: #1c1b20;
}

@media screen and (min-width: 550px) {
  ._2XpdViZsWMC7GMTTQkto7d .PetA7KXgATItjsnzUeioV ._22k68PEgFsPaCzYZS_lRHO {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1500px) {
  ._2XpdViZsWMC7GMTTQkto7d .PetA7KXgATItjsnzUeioV {
    padding-top: 120px;
    width: 60%;
  }
}
._31yxibn-VWqLLSrbvQS-Ml {
  background-color: #f5f5f5;
  padding-top: 20px;
}

@media screen and (min-width: 700px) {
  ._31yxibn-VWqLLSrbvQS-Ml {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1500px) {
  ._31yxibn-VWqLLSrbvQS-Ml {
    background-color: #fff;
    padding-top: 0;
  }
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding-bottom: 60px;
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._1KZpNOF9e3YBEp_x5XCB4q {
  height: 400px;
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._1KZpNOF9e3YBEp_x5XCB4q ._1lKY5W3pC3-frwagCVRi0t {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  position: relative;
  width: 64px;
  height: 64px;
  display: block;
  bottom: calc(50% + 64px);
  left: calc(50% - 32px);
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._1KZpNOF9e3YBEp_x5XCB4q ._1lKY5W3pC3-frwagCVRi0t:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._1KZpNOF9e3YBEp_x5XCB4q ._2HHdTdDXPC4Kh2pMLv4d9S {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: ease-in-out 1s;
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._1KZpNOF9e3YBEp_x5XCB4q ._2HHdTdDXPC4Kh2pMLv4d9S:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._3vn9RfklCOXCxwqVZ_vxCX {
  background-color: #f5f5f5;
  padding: 2rem 0;
}

._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._3vn9RfklCOXCxwqVZ_vxCX .Y0PyVa-7UUs_APICLqQVK,
._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._3vn9RfklCOXCxwqVZ_vxCX ._3Po4ib-st3LIqbItk2Q64Z {
  padding: 2rem 0;
}

@media screen and (min-width: 1500px) {
  ._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._3vn9RfklCOXCxwqVZ_vxCX {
    padding: 4rem;
  }
}

@media screen and (min-width: 1500px) {
  ._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq {
    position: relative;
    padding-bottom: 180px;
    margin-bottom: 180px;
  }

  ._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._1KZpNOF9e3YBEp_x5XCB4q {
    position: absolute;
    right: calc((100vw - 1400px) / -2);
    width: 734px;
    height: 644px;
    z-index: 2;
  }

  ._31yxibn-VWqLLSrbvQS-Ml ._3rirFeGOJhB3BicqjI6WGq ._3vn9RfklCOXCxwqVZ_vxCX {
    position: relative;
    padding: 60px 72px;
    width: calc(48% + (100vw - 1400px) / 2);
    top: 120px;
  }
}
._1hQ_0B4d_WeU-ON3UL5SgH ._1z5nnHdomClD22Mcqw4Dfg {
  margin-top: 60px;
  margin-bottom: 60px;
}

._1hQ_0B4d_WeU-ON3UL5SgH ._9h_80CyIlCSs7bEZAqf7u::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 100%;
  right: 0px;
  top: 0;
  background: rgba(215,215,215,0.404);
  -webkit-filter: blur(20px) saturate(50%);
  filter: blur(20px) saturate(50%);
}

._1hQ_0B4d_WeU-ON3UL5SgH ._9h_80CyIlCSs7bEZAqf7u._1RmlcP6xDZWh3ijhP_5d-G::after {
  background: none;
  -webkit-filter: none;
  filter: none;
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq {
  margin-left: 25px;
  margin-left: 40px;
  width: 370px;
  height: 370px;
  background-color: #f5f5f5;
  white-space: initial;
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne {
  padding: 30px;
  display: grid;
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne ._46q8Uf5eumd45d8VuVB-j {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 30px;
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne ._46q8Uf5eumd45d8VuVB-j ._2O3DvIB6iFxKssQ3kLLmEE {
  width: 80px !important;
  height: 80px !important;
  border-radius: 70px;
  border: 2px solid #47dba4;
}

@media screen and (min-width: 1200px) {
  ._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne ._46q8Uf5eumd45d8VuVB-j ._2O3DvIB6iFxKssQ3kLLmEE {
    width: 112px !important;
    height: 112px !important;
  }
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne ._46q8Uf5eumd45d8VuVB-j ._2VaMtUWAyqXFGfO_INY8Ma {
  padding-top: 16px;
  padding-left: 0px;
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne ._46q8Uf5eumd45d8VuVB-j ._2VaMtUWAyqXFGfO_INY8Ma ._2ToQKrYBO1ovYOfGIo-4JW {
  color: #47dba4;
}

@media screen and (min-width: 1200px) {
  ._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne ._46q8Uf5eumd45d8VuVB-j ._2VaMtUWAyqXFGfO_INY8Ma {
    padding-top: 30px;
    padding-left: 30px;
  }
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne .Yk9FHe3nvoLQn30ftsC3g {
  padding-bottom: 15px;
}

@media screen and (min-width: 1000px) {
  ._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq ._1qZvUOxsXjhu7Kzl3K16ne .Yk9FHe3nvoLQn30ftsC3g {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1000px) {
  ._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq {
    width: 420px;
    height: 420px;
  }
}

@media screen and (min-width: 1200px) {
  ._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq {
    width: 490px;
    height: 490px;
  }
}

._1hQ_0B4d_WeU-ON3UL5SgH ._2rBtK2wahKH-maPEQHf3Hq:first-child {
  margin: 0;
}

@media screen and (min-width: 1000px) {
  ._1hQ_0B4d_WeU-ON3UL5SgH {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}
.cN8SngjShVAd27AjSPhf4 {
  padding: 2rem 0;
}

.cN8SngjShVAd27AjSPhf4>span {
  margin-left: 0;
}

.cN8SngjShVAd27AjSPhf4>svg {
  width: 26px;
}

@media screen and (min-width: 1500px) {
  .cN8SngjShVAd27AjSPhf4 {
    position: absolute;
    left: calc((100vw - 1384px) / 2 - 50px);
  }
}

@media screen and (min-width: 1700px) {
  .cN8SngjShVAd27AjSPhf4 {
    position: absolute;
    left: calc((100vw - 1384px) / 2 - 150px);
  }
}

.p5WmpulicSVHF4QUBZGKE {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1500px) {
  .p5WmpulicSVHF4QUBZGKE {
    padding-left: 100px;
  }
}

.p5WmpulicSVHF4QUBZGKE small {
  text-transform: uppercase;
  color: #adadad;
  font-size: 14px;
}

.p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf {
  overflow: hidden;
  width: 100%;
}

.p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf.jO0_qKqGy_1kIqssVULHk {
  height: 250px;
}

.p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf._2sbwZHrLRvCvWvgKUDvg_F {
  display: none;
}

@media screen and (min-width: 550px) {
  .p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf.jO0_qKqGy_1kIqssVULHk {
    height: 500px;
  }

  .p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf._2sbwZHrLRvCvWvgKUDvg_F {
    width: 50%;
    height: 100%;
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf._2sbwZHrLRvCvWvgKUDvg_F {
    height: 100%;
    width: 55%;
  }
}

@media screen and (min-width: 1500px) {
  .p5WmpulicSVHF4QUBZGKE ._205uDHWgSqqtQ4S_qytIjf.jO0_qKqGy_1kIqssVULHk {
    width: calc(100vw - (100vw - 1384px) / 2 - 100px);
  }
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding: 4rem 3rem;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 small {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 .mzHgpuXoHVYdbZoQDx9zj {
  display: block;
  font-size: 25px;
  color: #2fd0b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.3s ease;
  margin-bottom: 18px;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 .mzHgpuXoHVYdbZoQDx9zj:hover {
  color: #16a58e;
  transition: color 0.3s ease;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2KKnN0NiO-H4c02VeOOarc {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2KKnN0NiO-H4c02VeOOarc:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

@media screen and (min-width: 550px) {
  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX {
    height: 300px;
  }

  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 {
    width: 50%;
  }

  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 h5 {
    margin-bottom: 30px;
  }

  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 .mzHgpuXoHVYdbZoQDx9zj {
    font-size: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX {
    padding-left: 5rem;
    width: 700px;
    position: relative;
    top: -60px;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    right: 60px;
  }

  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX ._2rmRV4p-gS54kWySw1f681 {
    width: 45%;
  }
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2mLpoqHXIMVoItw8hKBp-W {
  padding: 5rem 0 0;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2mLpoqHXIMVoItw8hKBp-W ._2NPKM3mZ1WxoM4zejHDct0 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
}

.p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2mLpoqHXIMVoItw8hKBp-W ._2NPKM3mZ1WxoM4zejHDct0 small:first-of-type {
  margin-right: 60px;
}

@media screen and (min-width: 550px) {
  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2mLpoqHXIMVoItw8hKBp-W {
    padding: 2rem 0;
  }
}

@media screen and (min-width: 1500px) {
  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W {
    -webkit-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2XgLD0jVj64d_pPcrkzPX {
    right: unset;
  }

  .p5WmpulicSVHF4QUBZGKE .yZInquf7CYMINlEgGgd1W ._2mLpoqHXIMVoItw8hKBp-W {
    -webkit-align-self: flex-end;
    align-self: flex-end;
    padding-bottom: 60px;
  }
}

.p5WmpulicSVHF4QUBZGKE ._2rvVzJqu8158tz12wAferd {
  padding-top: 60px;
}

.p5WmpulicSVHF4QUBZGKE ._2rvVzJqu8158tz12wAferd .pXU89qoJr5MkgIMAODeDX {
  padding-bottom: 1rem;
}

.p5WmpulicSVHF4QUBZGKE ._2rvVzJqu8158tz12wAferd p {
  color: #1c1b20;
}

@media screen and (min-width: 550px) {
  .p5WmpulicSVHF4QUBZGKE ._2rvVzJqu8158tz12wAferd .pXU89qoJr5MkgIMAODeDX {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1500px) {
  .p5WmpulicSVHF4QUBZGKE ._2rvVzJqu8158tz12wAferd {
    padding-top: 120px;
    width: 60%;
  }
}
._1sRXCmXx1lzRVz69d4asl2 {
  background-color: #f5f5f5;
  padding-top: 20px;
}

@media screen and (min-width: 700px) {
  ._1sRXCmXx1lzRVz69d4asl2 {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1500px) {
  ._1sRXCmXx1lzRVz69d4asl2 {
    background-color: #fff;
    padding-top: 0;
  }
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding-bottom: 60px;
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD .xX5nFQJuIQaaUahMfq5wj {
  height: 400px;
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD .xX5nFQJuIQaaUahMfq5wj .LIu7-wIpjhYITL1erxFyC {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  position: relative;
  width: 64px;
  height: 64px;
  display: block;
  bottom: calc(50% + 64px);
  left: calc(50% - 32px);
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD .xX5nFQJuIQaaUahMfq5wj .LIu7-wIpjhYITL1erxFyC:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD .xX5nFQJuIQaaUahMfq5wj ._2pa2FCg1FvKIY68E2k0jpX {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: ease-in-out 1s;
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD .xX5nFQJuIQaaUahMfq5wj ._2pa2FCg1FvKIY68E2k0jpX:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD ._3eLzGqnbW5ksm0hfN3BIjV {
  background-color: #f5f5f5;
  padding: 2rem 0;
}

._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD ._3eLzGqnbW5ksm0hfN3BIjV ._1Ju_UDcx5P9nApKk-ov68X,
._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD ._3eLzGqnbW5ksm0hfN3BIjV ._2HkGSEd4aTGX_xCZuqDgP8 {
  padding: 2rem 0;
}

@media screen and (min-width: 1500px) {
  ._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD ._3eLzGqnbW5ksm0hfN3BIjV {
    padding: 4rem;
  }
}

@media screen and (min-width: 1500px) {
  ._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD {
    position: relative;
    padding-bottom: 180px;
    margin-bottom: 180px;
  }

  ._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD .xX5nFQJuIQaaUahMfq5wj {
    position: absolute;
    right: calc((100vw - 1400px) / -2);
    width: 734px;
    height: 644px;
    z-index: 2;
  }

  ._1sRXCmXx1lzRVz69d4asl2 .JzWyIvOThYdHd7_JeqHBD ._3eLzGqnbW5ksm0hfN3BIjV {
    position: relative;
    padding: 60px 72px;
    width: calc(48% + (100vw - 1400px) / 2);
    top: 120px;
  }
}
._3JwB8dKz09vJ7lNzA8eZ1E {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.device-IE11 ._3JwB8dKz09vJ7lNzA8eZ1E {
  overflow: visible;
}

.UJtRQrAZSuCrrJaRcG2DV {
  display: inline-block;
}

._3YGRG-E1Wq_ydqF-dfc1E3,
.lzgibv8mjGrFhIk8E9xTo,
._3usObTq6UfXzdPteTIW_FT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

._3YGRG-E1Wq_ydqF-dfc1E3 {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.lzgibv8mjGrFhIk8E9xTo {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

._3usObTq6UfXzdPteTIW_FT {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

._1JmG3IuEiPnC5bw6urWrPF {
  position: relative;
  padding-top: 0.5em;
  padding-right: 1.3em;
  padding-bottom: 0.5em;
  padding-left: 1.3em;
  z-index: 1;
  background-image: linear-gradient(-90deg, #47dba4, #46d1be);
  background-size: cover;
}

._1JmG3IuEiPnC5bw6urWrPF._1HhBpLXxyN58qViGdxPRdX {
  padding-left: 1.3em;
  padding-right: 1.3rem;
}

@media screen and (min-width: 800px) {
  ._1JmG3IuEiPnC5bw6urWrPF {
    padding-top: 0.6em;
    padding-right: 1.5em;
    padding-bottom: 0.6em;
    padding-left: 1.5em;
  }
}

._1JmG3IuEiPnC5bw6urWrPF:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(90deg, #47dba4, #46d1be);
  background-size: cover;
  opacity: 0;
  transition: opacity 0.4s ease;
}

._1jyKKyj4-5js8lWv7zAt_C {
  border-radius: 35px;
}

._1jyKKyj4-5js8lWv7zAt_C._1JmG3IuEiPnC5bw6urWrPF:after {
  border-radius: 35px;
}

._3G3xbo_drS1vpGXBL82Xol {
  display: inline-block;
  vertical-align: middle;
  color: #2fd0b7;
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 799px) {
  ._3G3xbo_drS1vpGXBL82Xol {
    font-size: 1.5rem;
  }
}

.nBw9diIA-beyK6Mf321OA ._3G3xbo_drS1vpGXBL82Xol {
  transition: color 0.15s linear;
}

._1JmG3IuEiPnC5bw6urWrPF ._3G3xbo_drS1vpGXBL82Xol {
  color: #fff;
}

._3yjQV0Go1f1MdOSjuVcQMe {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

._3d0uN3bbtLgJbBB_pOtvBY {
  width: 25px;
  height: 10px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  fill: transparent;
  stroke: #2fd0b7;
  stroke-width: 5px;
  pointer-events: none;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

@media screen and (min-width: 800px) {
  ._3d0uN3bbtLgJbBB_pOtvBY {
    width: 35px;
    height: 14px;
    margin-left: 15px;
  }
}

._1HhBpLXxyN58qViGdxPRdX ._3d0uN3bbtLgJbBB_pOtvBY {
  width: 32px;
  height: 15px;
  margin-left: 0;
  vertical-align: unset;
  -webkit-transform: rotate(90deg) translateX(-7px);
  transform: rotate(90deg) translateX(-7px);
}

.nBw9diIA-beyK6Mf321OA ._3d0uN3bbtLgJbBB_pOtvBY {
  transition: stroke 0.15s linear, -webkit-transform 0.25s;
  transition: transform 0.25s, stroke 0.15s linear;
  transition: transform 0.25s, stroke 0.15s linear, -webkit-transform 0.25s;
}

._1JmG3IuEiPnC5bw6urWrPF ._3d0uN3bbtLgJbBB_pOtvBY {
  stroke: #fff;
}

.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E:hover:not(._1HhBpLXxyN58qViGdxPRdX) ._3d0uN3bbtLgJbBB_pOtvBY,
.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E._36lU9Or-N5iz6f0tZLeOrm:not(._1HhBpLXxyN58qViGdxPRdX) ._3d0uN3bbtLgJbBB_pOtvBY {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E:hover:not(._1HhBpLXxyN58qViGdxPRdX)._1JmG3IuEiPnC5bw6urWrPF:after,
.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E._36lU9Or-N5iz6f0tZLeOrm:not(._1HhBpLXxyN58qViGdxPRdX)._1JmG3IuEiPnC5bw6urWrPF:after {
  opacity: 1;
}

.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E:hover:not(._1HhBpLXxyN58qViGdxPRdX).nBw9diIA-beyK6Mf321OA ._3d0uN3bbtLgJbBB_pOtvBY,
.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E._36lU9Or-N5iz6f0tZLeOrm:not(._1HhBpLXxyN58qViGdxPRdX).nBw9diIA-beyK6Mf321OA ._3d0uN3bbtLgJbBB_pOtvBY {
  stroke: #fff;
}

.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E:hover:not(._1HhBpLXxyN58qViGdxPRdX).nBw9diIA-beyK6Mf321OA ._3G3xbo_drS1vpGXBL82Xol,
.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E._36lU9Or-N5iz6f0tZLeOrm:not(._1HhBpLXxyN58qViGdxPRdX).nBw9diIA-beyK6Mf321OA ._3G3xbo_drS1vpGXBL82Xol {
  color: #fff;
}

.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E:hover._1HhBpLXxyN58qViGdxPRdX ._3d0uN3bbtLgJbBB_pOtvBY,
.device-desktop ._3JwB8dKz09vJ7lNzA8eZ1E._36lU9Or-N5iz6f0tZLeOrm._1HhBpLXxyN58qViGdxPRdX ._3d0uN3bbtLgJbBB_pOtvBY {
  -webkit-transform: rotate(90deg) translateX(-3px);
  transform: rotate(90deg) translateX(-3px);
}
._1ta7B-zuUCdMyXmZEFW59O {
  position: fixed;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 1000;
}

@media screen and (min-width: 550px) {
  ._1ta7B-zuUCdMyXmZEFW59O {
    top: 75px;
    right: 30px;
    left: unset;
    bottom: unset;
  }
}

._2lYLhjIEaRU6NAHRpW98Ht {
  padding: 15px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

@media screen and (min-width: 550px) {
  ._2lYLhjIEaRU6NAHRpW98Ht {
    padding: 20px 32px;
  }
}

._1xYtsjhyd0kIQZFV6qwgfJ {
  max-width: 225px;
  line-height: 1.2em;
}

._3i0h_DIGDA4xklTPfYZG7d,
.WRJ-3cxoQVZghjLhKpL2I,
._1sJ-Y2yyuIQa2tzpdixRDR {
  font-size: 0.85em;
}

._3i0h_DIGDA4xklTPfYZG7d {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
}

._1sJ-Y2yyuIQa2tzpdixRDR {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  color: #2fd0b7;
}

._1N3GdhV-Mc8mU85hRF5Eqn {
  margin-top: 10px;
}

@media screen and (min-width: 550px) {
  ._1N3GdhV-Mc8mU85hRF5Eqn {
    margin-top: 0;
    margin-left: 30px;
  }
}
@media screen and (max-width: 999px) {
  .-l8huGEkBC8_ltC0Wtyl1 {
    width: 100%;
    height: calc(100vh - 52px);
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 60px;
    padding-right: 80px;
    padding-left: 80px;
    overflow-x: auto;
    background-color: #141315;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 549px) {
  .-l8huGEkBC8_ltC0Wtyl1 {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 399px) {
  .-l8huGEkBC8_ltC0Wtyl1 {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media screen and (min-width: 1000px) {
  .-l8huGEkBC8_ltC0Wtyl1 {
    height: 100%;
    -webkit-flex-grow: 2;
    flex-grow: 2;
  }
}

@media screen and (max-width: 999px) {
  .-TGzkxby7Lg3x27SbfnL0 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition: all 300ms;
  }
}

@media screen and (min-width: 1000px) {
  ._1mi-BnVrkwrvjUsPivaAJh {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}

._2PUShpTI6GWf4sqVIA1XRf {
  font-family: "proxima-nova",sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  cursor: pointer;
  transition: color ease 0.25s;
}

@media screen and (max-width: 999px) {
  ._2PUShpTI6GWf4sqVIA1XRf {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: block;
  }
}

@media screen and (max-width: 999px) {
  ._3-dpGtnzm_D-f--r50OV0d {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
    transition: all 300ms;
  }

  .-l8huGEkBC8_ltC0Wtyl1.-TGzkxby7Lg3x27SbfnL0 ._3-dpGtnzm_D-f--r50OV0d {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.device-desktop ._2PUShpTI6GWf4sqVIA1XRf:hover {
  color: #2fd0b7;
}

._2PUShpTI6GWf4sqVIA1XRf._2og_W6nDB309srFl76gpsS {
  color: #2fd0b7;
}

@media screen and (min-width: 1000px) {
  ._1mi-BnVrkwrvjUsPivaAJh ._2PUShpTI6GWf4sqVIA1XRf {
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1000px) {
  .AUJbGFG9hAo7wgdzyzXZ-,
  .ihZAO-hX7s59nv-ag8Ltk {
    display: none;
  }
}

@media screen and (max-width: 999px) {
  .ihZAO-hX7s59nv-ag8Ltk {
    margin-top: 30px;
    padding-top: 50px;
    border-top: 1px solid rgba(255,255,255,0.25);
  }
}

@media screen and (max-width: 999px) {
  .AUJbGFG9hAo7wgdzyzXZ- {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
._2OwhdqmnzCXa4Y5vuAXi7i {
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(0deg, #47dba4, #46d1be);
  background-size: cover;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
._27bfL48NrIEmxRuFqJzOBy {
  width: 100%;
  height: 52px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #1c1b20;
}

@media screen and (min-width: 1000px) {
  ._27bfL48NrIEmxRuFqJzOBy {
    height: 55px;
  }
}

.FY9HCQsr3lNqFfc2qhdUT {
  max-width: 1640px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
}

@media screen and (max-width: 999px) {
  .FY9HCQsr3lNqFfc2qhdUT {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1000px) {
  .FY9HCQsr3lNqFfc2qhdUT {
    height: 100%;
    padding-right: 55px;
    padding-left: 55px;
  }
}

._1NJq1x6W_JR0NTPNtNLic1 {
  position: relative;
}

@media screen and (max-width: 999px) {
  ._1NJq1x6W_JR0NTPNtNLic1 {
    width: 78px;
    height: 52px;
  }
}

@media screen and (min-width: 1000px) {
  ._1NJq1x6W_JR0NTPNtNLic1 {
    width: 55px;
  }
}

._3xoBSnTOKB15MZHZvIZCUC {
  width: 40px;
  height: 25px;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 20px);
  fill: #fff;
}

._2-wEpw04-ZJi6h8aTJXQY {
  width: 52px;
  height: 52px;
  position: relative;
  display: block;
}

@media screen and (min-width: 1000px) {
  ._2-wEpw04-ZJi6h8aTJXQY {
    display: none;
  }
}

.device-IE11 ._2-wEpw04-ZJi6h8aTJXQY {
  margin-left: auto;
}

._260GZqeekpSSAfpKZKFEOU {
  width: 24px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 12px);
  background-color: #fff;
}

._260GZqeekpSSAfpKZKFEOU:before,
._260GZqeekpSSAfpKZKFEOU:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}

._260GZqeekpSSAfpKZKFEOU:before {
  -webkit-transform: translateY(-7px) rotate(0);
  transform: translateY(-7px) rotate(0);
}

._260GZqeekpSSAfpKZKFEOU:after {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: translateY(7px) scaleX(0.6);
  transform: translateY(7px) scaleX(0.6);
}

._1G5CRk1DRdE0r_ey4ULGrM ._260GZqeekpSSAfpKZKFEOU {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

._1G5CRk1DRdE0r_ey4ULGrM ._260GZqeekpSSAfpKZKFEOU:before {
  -webkit-transform: translateY(0) rotate(-90deg);
  transform: translateY(0) rotate(-90deg);
}

._1G5CRk1DRdE0r_ey4ULGrM ._260GZqeekpSSAfpKZKFEOU:after {
  -webkit-transform: translateY(7px) scaleX(0);
  transform: translateY(7px) scaleX(0);
}
@font-face {
  font-family: 'Playfair Display Regular';
  src: url("/fonts/PlayfairDisplay-Regular.woff2") format("woff2"),url("/fonts/PlayfairDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Bold';
  src: url("/fonts/PlayfairDisplay-Bold.woff2") format("woff2"),url("/fonts/PlayfairDisplay-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Bold Italic';
  src: url("/fonts/PlayfairDisplay-BoldItalic.woff2") format("woff2"),url("/fonts/PlayfairDisplay-BoldItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Italic';
  src: url("/fonts/PlayfairDisplay-Italic.woff2") format("woff2"),url("/fonts/PlayfairDisplay-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Black';
  src: url("/fonts/PlayfairDisplay-Black.woff2") format("woff2"),url("/fonts/PlayfairDisplay-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Black Italic';
  src: url("/fonts/PlayfairDisplay-BlackItalic.woff2") format("woff2"),url("/fonts/PlayfairDisplay-BlackItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair DisplaySC Regular';
  src: url("/fonts/PlayfairDisplaySC-Regular.woff2") format("woff2"),url("/fonts/PlayfairDisplaySC-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair DisplaySC Italic';
  src: url("/fonts/PlayfairDisplaySC-Italic.woff2") format("woff2"),url("/fonts/PlayfairDisplaySC-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair DisplaySC Bold';
  src: url("/fonts/PlayfairDisplaySC-Bold.woff2") format("woff2"),url("/fonts/PlayfairDisplaySC-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair DisplaySC Bold Italic';
  src: url("/fonts/PlayfairDisplaySC-BoldItalic.woff2") format("woff2"),url("/fonts/PlayfairDisplaySC-BoldItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair DisplaySC Black';
  src: url("/fonts/PlayfairDisplaySC-Black.woff2") format("woff2"),url("/fonts/PlayfairDisplaySC-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair DisplaySC Black Italic';
  src: url("/fonts/PlayfairDisplaySC-BlackItalic.woff2") format("woff2"),url("/fonts/PlayfairDisplaySC-BlackItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  padding-left: 20px;
}

button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

img,
iframe,
video {
  height: 100%;
}

iframe,
video {
  max-width: 100%;
}

iframe {
  border: 0;
}

body .grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
}

html,
body {
  width: 100%;
  background-color: #fff;
  font-family: "proxima-nova",sans-serif;
  font-weight: 300;
}

html {
  font-size: 62.5%;
}

body {
  overflow-y: scroll;
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.main {
  padding-top: 52px;
}

@media screen and (min-width: 1000px) {
  .main {
    padding-top: 55px;
  }
}

a {
  text-decoration: none;
}

a:not([class]) {
  color: #2fd0b7;
  text-decoration: underline;
}

a:not([class]):hover {
  text-decoration: none;
}

button:not([disabled]) {
  cursor: pointer;
}

input,
textarea,
button {
  font: inherit;
  font-size: 1.8rem;
  line-height: 1.3;
}

input._2FowlFCGRDgo5788zkoWn3,
input[type='text'],
input[type='button'],
input[type='submit'],
.U9EuX6G8f_csbQK5FVoC7 {
  -webkit-appearance: none;
  border-radius: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  ._3V95ce7QaNGPLEv0E5bmBC {
    -webkit-transform: none !important;
    transform: none !important;
  }
}

:root:not(._17da7P8qiMdJMwxr4gWw8T) button,
:root:not(._17da7P8qiMdJMwxr4gWw8T) a,
:root:not(._17da7P8qiMdJMwxr4gWw8T) [role='button'],
:root:not(._17da7P8qiMdJMwxr4gWw8T) [role='tab'],
:root:not(._17da7P8qiMdJMwxr4gWw8T) input {
  outline: 0;
}

:root.keyboard-focus button:focus,
:root.keyboard-focus a:focus,
:root.keyboard-focus [role='button']:focus,
:root.keyboard-focus [role='tab']:focus {
  outline: #16a58e 5px auto;
}
